@import '../../../assets/sass/palette.scss';

.custom-tooltip {
  border-radius: 4px !important;    
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;  
  background: #1E2749 !important;
  opacity: 1 !important;

  &::after {
    background-color: #1E2749 !important;
  }
}

.app-customization-status-tooltip {
  width: 320px;
}
