@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
.toolTipPay {
    width: 350px !important;
    border-radius: 4px !important;
    background-color: $primary-tool-tip !important;
    opacity: 1 !important;
}
.connected {
    color: $select-drop-hover;
}
.payments {
    .modal-dialog {
        position: absolute !important;
        right: 25px !important;
        display: block !important;
    }
}

.mediaGreyText {
    color: $secondary-grey-shade !important;
}
.unLine {
    text-decoration: underline;
}
.keyInput {
    border: 1px $input-border-color !important;
    border-color: $input-border-color !important;
    border-radius: 5px !important;
}
.errorMsg {
    font-size: 12px;
}
.updateGreyBtn {
    background-color: $unselect-drop-hover !important;
    color: white !important;
    border-color: $unselect-drop-hover !important;
}

@include for-big-desktop-up {
    .payments {
        .modal-dialog {
            position: absolute !important;
            right: 25px !important;
            display: block !important;
        }
    }
}

.stripe-logo {
    margin-left: -10px;
}
