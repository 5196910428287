.lastDropdownElement {
    transform: translateY(-100%) !important;
}
.show-effects {
    &:hover {
        background: #edf6fb !important;
        border-radius: 10px;
    }
    &:focus {
        background: #edf6fb !important;
        border-radius: 10px;
        border: 1px solid #00a3ff !important;
    }
    .dropdown-item {
        &:hover {
            background: #edf6fb !important;
            border-radius: 10px;
        }
    }
}
