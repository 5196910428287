.live-show-comments {
  // width: 350px !important;
  // flex: 0 0 350px !important;

  @media screen and (max-width: 991px) {
    padding-left: 0 !important;
  }
}

.live-show-comments-section {
  height: 100%;

  @media screen and (max-width: 991px) {
    height: 35rem;
    margin-top: calc(var(--ZOOM-MULTIPLIER) * 1.42rem) !important;
  }
  
  .live-show-comments-list {
    // max-height: calc(100vh - calc(var(--ZOOM-MULTIPLIER) * 13.42rem));
    // overflow-y: scroll;
    // overflow-x: hidden;
    padding-left: calc(var(--ZOOM-MULTIPLIER) * 1rem);
    // &.comments-overflow {
    //   overflow-y: scroll;
    // }
    height: calc(100vh - 200px);
  }
  .live-show-comments-header {
    // padding: calc(var(--ZOOM-MULTIPLIER) * 1.71rem) calc(var(--ZOOM-MULTIPLIER) * 1.71rem) calc(var(--ZOOM-MULTIPLIER) * 1.14rem) calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
    z-index: 99;
    background: #fff;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;

    &-more {
      right: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
    }
  }

  .comment-item {
    display: flex;
    padding: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
    position: relative;
    &:hover {
      background: #f8f8fa;
    }
    .no-visible {
      visibility: hidden;
    }
    .comment-item-image-container {
      margin-right: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
      position: relative;

      .facebook-connected-status {
        width: 1rem;
        height: 1rem;
        right: 0.25rem;
      }

      .circle-container {
        position: absolute;
        top: calc(var(--ZOOM-MULTIPLIER) * (-0.5rem));
        left: calc(var(--ZOOM-MULTIPLIER) * (-0.5rem));
        background: transparent;
        z-index: 1;
      }
      img {        
        width: 44px;
        height: 44px;        
      }
      .progress-bar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: radial-gradient(closest-side, white 79%, transparent 80%), conic-gradient(hotpink 75%, pink 0);
      }
    }

    .name-content-container {
      width: calc(100% - calc(var(--ZOOM-MULTIPLIER) * 4rem));

      .name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;        
        font-size: 16px;
        line-height: 21px;
        color: #1e2749;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        max-width: 10rem;
        &:hover {
          color: #369ff7;
          text-decoration: underline;
          text-decoration-color: #369ff7;
        }

        @media screen and (max-width: 1439px) {
          font-size: 0.85rem;
          line-height: 1.1rem;          
        }
      }
      .content {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;        
        font-size: 20px;        
        line-height: 20px;
        letter-spacing: 0px;        
        color: #1e2749;
        white-space: pre-wrap;
        // line-break: anywhere;

        @media screen and (max-width: 1439px) {
          font-size: 1rem;
          line-height: 1.5rem;        
        }
      }
      .name-line {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 40px;
        flex-wrap: wrap;
        // margin-top: calc(var(--ZOOM-MULTIPLIER) * 0.3rem);

        & > * {
          padding-right: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
        }
        .tooltips-container {
          // z-index: 10;
          position: relative;
        }
        .dollar-number {
          color: #00a3ff;
          // font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
          font-size: 16px;
          line-height: 19.2px;
        }
        .dollar-icon {
          padding-right: 2px !important;
          &.svg-icon svg {
            height: 18px;
            width: 18px;
          }
          [fill]:not(.permanent):not(g) {
            fill: #00a3ff;
          }
        }
        .crown-icon {
          [fill]:not(.permanent):not(g) {
            fill: #f9eed0;
          }
          &.svg-icon svg {
            height: 16px;
            width: 20px;
          }
        }
        .first-time-icon {
          &.svg-icon svg {
            height: 18px;
            width: 16px;
          }
        }
        .cart-icon {
          position: relative;
          [fill]:not(.permanent):not(g) {
            fill: #1e2749;
          }
          &.svg-icon svg {
            height: 16px;
            width: 18px;
          }
        }
        .crow-icon {
          [fill]:not(.permanent):not(g) {
            fill: rgb(255, 199, 0);
          }
        }
        .cart-icon-container {
          position: relative;
          .cart-number {
            position: absolute;
            top: 0.35rem;
            left: 0.5rem;
            color: white;
            font-weight: 900;
            font-size: 0.5rem;
          }
        }
        .time {
          font-family: 'Poppins';
          font-weight: 300;
          color: #66728b;
          font-size: 14px;
          line-height: 21px;
          // font-size: calc(var(--ZOOM-MULTIPLIER) * 0.85rem);          
          // line-height: calc(var(--ZOOM-MULTIPLIER) * 1.1rem);
        }
      }
    }

    .reply-icon {
      cursor: pointer;
      &:hover {
        [fill]:not(.permanent):not(g) {
          fill: #00a3ff;
        }
      }
    }
  }

  .live-show-comment-input-container {
    bottom: 0;
    left: 0;
    padding: 10px;
    z-index: 99;
    background: white;
    border-radius: 0 0 10px 10px;

    .comment-input {
      margin-right: calc(var(--ZOOM-MULTIPLIER) * 5.71rem);
    }
  }

  .live-show-comment-item-more {
    right: 10px;
    z-index: 10;

    .pin-icon {
      position: relative;
      top: 2px;
      right: 10px;
      cursor: pointer;
      &.pinned {
        [fill]:not(.permanent):not(g) {
          fill: #00a3ff;
        }
      }
    }
  }
  .block-overlay {
    top: 0px;
    left: 0px;
    background-color: rgba(230, 230, 230, 0.4);
    width: 100%;
    height: 100%;
    z-index: 1;
  }  
}