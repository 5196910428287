.btn-back {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  margin-left: -12px;
  padding: 12px;
  cursor: pointer;
  width: fit-content;
  border-radius: 8px;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
    padding: 0 5px;
  }
}

.btn-back:hover {
  background-color: #e5e5e5;
}

.brand-logo {
  margin-bottom: 40px;
}

.content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

  span {
    font-size: 16px;
    font-weight: 600;
  }

  .email-sentence {
    font-size: 14px;
    font-weight: 500;
    color: #a1a5b7;
    line-height: 17px;

    .email-text {
      font-size: 14px;
      font-weight: 500;
      color: #008fdf;
    }
  }
}

.p-text {
  font-size: 14px;
  font-weight: 500;
  color: #a1a5b7;
  margin-bottom: 24px;
}

.height-container {
  height: 860px;
}

.email-section {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.spinner {
  height: 80px;
  width: 80px;
  animation: 0.65s linear infinite spin;
  margin-bottom: 60px;
}

.store-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.verify-email-container {
  padding: 96px 110px;
  border-radius: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
