@import '../../../../assets/sass/palette';
.drpBtn {
    background: $select-drop-hover-backgroud !important;
    border-radius: 18px !important;
    height: 30px !important;
    color: $select-drop-hover !important;
    padding: 6px !important;
}
.drpBtnColor {
    background: $light-gray-table !important;
    border-radius: 18px !important;
    height: 30px !important;
    color: $unselect-drop-hover !important;
    padding: 6px !important;
}
.drpBtn:active {
    background: $select-drop-hover !important;
}
.drpBtn:hover {
    background: $select-drop-hover-backgroud !important;
}
.dropdown-menu {
    background-color: $primary-color-white !important;
    box-shadow: 0px 5px 20px $drop-shadow-background !important;
    padding: 1px;
}
.lastUpdated {
    opacity: 0.5;
    padding-right: 4px;
}
.updateTime {
    top: 10px;
}
$img-backgroung: #f8f8fa;
$budget-increase: #50cd89;
$budget-decrease: #f1416c;
$budget-same: #ffc700;

.vendor-list {
    .img-div {
        background: $img-backgroung;
        border-radius: 5px;
        height: 50px !important;
        width: 50px !important;
        margin-right: 5px !important;
        border-radius: 5px !important;
    }

    .green-dot {
        background: $budget-increase;
    }

    .red-dot {
        background: $budget-decrease;
    }

    .yellow-dot {
        background: $budget-same;
    }
}
.selectionbtn {
    margin: auto !important;
}
.min-w-370px {
    min-width: 370px !important;
}

.min-w-130px {
    min-width: 130px !important;
}

.min-w-100px {
    min-width: 100px !important;
}

.min-w-150px{
    min-width: 150px !important;
}