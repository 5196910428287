.create-live-show-div {
    width: 74.85rem;

    &.extended-content {
        width: 99.28rem;
    }
}
.left-side-buttons {
    width: 70%;
    float: left;
}
.right-side-buttons {
    width: 30%;
    float: right;
}
.float-left {
    float: left;
}