.minimumTableHeight {
    min-height: 500px !important;
}
.selected {
    background: #edf6fb;

    &:hover {
        background: #edf6fb !important;
    }
}
.border-none {
    border: none !important;
}
.main-cell {
    color: #1e2749 !important;
    font-family: 'poppins-regular';
    font-weight: 600;
    font-size: 14px;    
    max-width: 200px;

    span {
        text-overflow: ellipsis;
        overflow: hidden;    
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        white-space: nowrap;
    }


    &:not(.no-decoration) {
        &:hover {
            text-decoration: underline !important;
        }
    }

    &.inventory-history-cell {
        -webkit-line-clamp: 1;
    }

    &.product-main-cell {
        @media only screen and (min-width: 1441px) {
            width: 300px !important;
            max-width: 100%;
        }        
    }
}
.no-hover-row {
    &:hover {
        background-color: transparent !important;
    }
}

.fixed_header {
    // width: 400px;
    table-layout: fixed;
    border-collapse: collapse;

    thead {                
        tr {
            display: block;
        }
    }

    tbody {
        display: block;
        // width: 100%;
        overflow: scroll;
        // overflow: auto;
        height: 500px;
    }

    th, td {
        // padding: 5px;
        // text-align: left;
        // width: 200px;
    }
}
