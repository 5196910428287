@import '../../../assets/sass/palette.scss';
.left-border-flat {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left-width: 0 !important;
}
.right-border-flat {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.disabled-input {
    background-color: $input-grey !important;
    cursor: not-allowed;
}

.radio-normal {
    height: 14px;
    width: 14px;
}

.w-80 {
    width: 80% !important;
}

.coupon-link {
    color: #1e2749 !important;
    font-family: "poppins-regular";
    font-weight: 600;
    font-size: 14px;
}