.small-video-controller-wrapper {
  align-items: center;
  margin: 0 calc(var(--ZOOM-MULTIPLIER) * 0.25rem);
  cursor: pointer;
}

.small-controller-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--ZOOM-MULTIPLIER) * 2.86rem);
  width: calc(var(--ZOOM-MULTIPLIER) * 2.86rem);
  border-radius: 50%;
  background-color: #f8f8fa;
  margin: 0 calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
  &:hover {
    background-color: #E9E9EC;
  }
  &:active {
    background-color: #C2C4CC;
  }
  img {
    height: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
    width: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
  }
}

.small-controller-wrapper-muted {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--ZOOM-MULTIPLIER) * 2.86rem);
  width: calc(var(--ZOOM-MULTIPLIER) * 2.86rem);
  border-radius: 50%;
  background-color: #00a3ff;
  margin: 0 7px;

  &:hover {
    background-color: #008FDF;
  }
  &:active {
    background-color: #0179BC;
  }

  img {
    height: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
    width: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
  }
}

.video-controller-wrapper {
  align-items: center;
  margin: 0 calc(var(--ZOOM-MULTIPLIER) * 1.42rem);
  cursor: pointer;
}
.controller-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--ZOOM-MULTIPLIER) * 3.57rem);
  width: calc(var(--ZOOM-MULTIPLIER) * 3.57rem);
  border-radius: 50%;
  background-color: #f8f8fa;
  margin: 0 7px;
  &:hover {
    background-color: #E9E9EC;
  }
  &:active {
    background-color: #C2C4CC;
  }
  img {
    height: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
    width: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
  }
}

.controller-wrapper-muted {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--ZOOM-MULTIPLIER) * 3.57rem);
  width: calc(var(--ZOOM-MULTIPLIER) * 3.57rem);
  border-radius: 50%;
  background-color: #00a3ff;
  margin: 0 calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
  &:hover {
    background-color: #008FDF;
  }
  &:active {
    background-color: #0179BC;
  }
  img {
    height: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
    width: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
  }
}

.drop-down-controller {
  background-color: #ffffff;
  position: absolute;
  z-index: 1000;
  right: 100;
  border-radius: 10px;
  width: max-content;
  padding: 0 calc(var(--ZOOM-MULTIPLIER) * 1.42rem);
  box-shadow: 0px 10px 30px 0px #13103933;
}

.drop-down-container {
  position: relative;
}

.drop-down-option {
  span {
    font-weight: 400;
    font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
    line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
    color: #4a516c;
  }
  .active-device {
    color: #00a3ff;
  }
  padding-bottom: calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
  &:first-child {
    padding: calc(var(--ZOOM-MULTIPLIER) * 1.42rem) 0 calc(var(--ZOOM-MULTIPLIER) * 2.14rem) 0;
  }
}
