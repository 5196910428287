@import '../../../assets/sass/palette.scss';
.warning-text {
    color: $primary-red;
}

.title-input {
    outline: none;
    max-width: 150px;
    width: fit-content;
}
.title-input:read-only {
    cursor: text !important;
}

.tag-input {
    border: 1px solid $input-border-color;
    border-radius: 5px;
    min-height: 50px;
    padding: 8px 12px;
    border-radius: 0 5px 5px 0 !important;
    border-left-width: 0.5px !important;
}

.tag-input-class {
    width: 80%;
    overflow: scroll;
    outline: 0;
    // max-width: 100px;
}
.variant-input-item {
    .dropdown-box {
        height: 50px !important;
        border-radius: 5px 0 0 5px !important;
        border-right-width: 0.5px !important;
    }

    .small-tags {
        color: $light-gray-text;
        background: $light-gray;
        margin-right: 10px !important;
    }

    .dropdown-menu {
        top: 53px !important;
    }
}
