.empty-data-section {
  border-radius: 10px;
  
  &.page-empty {
    min-height: calc(100vh - 300px);
  }

  &.section-empty {
    min-height: 450px;
  }

  h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;    
    color: #4B526D;
    margin-bottom: 10px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;    
    color: #A1A5B7;
    margin-bottom: 26px;

    strong {
      color: #4B526D;
    }
  }

  .btn {
    padding: 16px 24px !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;    
  }
}