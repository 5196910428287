@import '../../../../assets/sass/palette';
@import '../../../../assets/sass/core//components/mixins/svg-icon';
.primary-span{
    background-color: $primary-blue-background;
    color: $primary-color-white;
}

.secondary-span{
    background-color: $primary-border-color;
    color: $medium-grey-shade;
}

.number-span{
    border-radius: 50%;
    padding: 3px;
    margin-left: 5px;
    height: 25px;
    width: 25px;
}

.profile-div{
    background-color: $light-gray;
    border-radius: 8px;
}

.icon-style{
    @include svg-icon-color($light-gray-text);
}

.org-logo-url {
    width: 30px;
    height: 30px;
    margin: 6px;
}