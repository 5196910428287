//
// Label
//

// Set arrow direction
@mixin menu-link-arrow-rotate($angle, $angleRtl) {
    /*rtl:ignore*/
    transform: rotateZ($angle);
    transition: get($menu, accordion, arrow-transition);

    [direction='rtl'] & {
        /*rtl:ignore*/
        transform: rotateZ($angleRtl);
    }
}

// Set menu link padding x
@mixin menu-link-padding-x($value) {
    // Menu item
    .menu-item {
        .menu-link,
        .menu-content {
            padding-left: $value;
            padding-right: $value;
        }
    }
}

// Set menu link padding y
@mixin menu-link-padding-y($value) {
    // Menu item
    .menu-item {
        .menu-link,
        .menu-content {
            padding-top: $value;
            padding-bottom: $value;
        }
    }
}

// Set menu indention
@mixin menu-link-indention($value, $offset) {
    // Accordion mode
    .menu-sub:not([data-popper-placement]) {
        > .menu-item {
            > .menu-content,
            > .menu-link {
                padding-left: calc(#{1 * $value} + #{$offset});
            }

            > .menu-sub:not([data-popper-placement]) {
                > .menu-item {
                    > .menu-content,
                    > .menu-link {
                        padding-left: calc(#{2 * $value} + #{$offset});
                    }

                    > .menu-sub:not([data-popper-placement]) {
                        > .menu-item {
                            > .menu-content,
                            > .menu-link {
                                padding-left: calc(#{3 * $value} + #{$offset});
                            }

                            > .menu-sub:not([data-popper-placement]) {
                                > .menu-item {
                                    > .menu-content,
                                    > .menu-link {
                                        padding-left: calc(
                                            #{4 * $value} + #{$offset}
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .menu-fit & {
            > .menu-item {
                > .menu-content,
                > .menu-link {
                    padding-left: #{$value};
                    padding-right: 0;
                }

                > .menu-sub:not([data-popper-placement]) {
                    > .menu-item {
                        > .menu-content,
                        > .menu-link {
                            padding-left: calc(#{2 * $value});
                            padding-right: 0;
                        }

                        > .menu-sub:not([data-popper-placement]) {
                            > .menu-item {
                                > .menu-content,
                                > .menu-link {
                                    padding-left: calc(#{3 * $value});
                                    padding-right: 0;
                                }

                                > .menu-sub:not([data-popper-placement]) {
                                    > .menu-item {
                                        > .menu-content,
                                        > .menu-link {
                                            padding-left: calc(#{4 * $value});
                                            padding-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Set menu link theme
@mixin menu-link-theme(
    $title-color,
    $icon-color,
    $bullet-color,
    $arrow-color,
    $bg-color: null
) {
    @if ($bg-color != null) {
        background-color: $bg-color;
    }

    @if ($title-color != null) {
        color: $title-color;

        .menu-title {
            color: $title-color;
        }
    }

    @if ($icon-color != null) {
        .menu-icon {
            // Font Icon
            i {
                color: $icon-color;
            }
            // Svg icon
            .svg-icon {
                @include svg-icon-color($icon-color);
            }
        }
    }

    @if ($bullet-color != null) {
        .menu-bullet {
            .bullet {
                background-color: $bullet-color;
            }
        }
    }

    @if ($arrow-color != null) {
        // Arrow
        .menu-arrow:after {
            @include svg-bg-icon(arrow-start, $arrow-color);
            /*rtl:begin:remove*/
            @include svg-bg-icon(arrow-end, $arrow-color);
            /*rtl:end:remove*/
        }
    }
}

// Set menu link default state
@mixin menu-link-default-state(
    $title-color,
    $icon-color,
    $bullet-color,
    $arrow-color,
    $bg-color: null,
    $all-links: true
) {
    $sel: '';

    @if ($all-links == false) {
        $sel: '& > ';
    }

    #{$sel}.menu-link {
        @include menu-link-theme(
            $title-color,
            $icon-color,
            $bullet-color,
            $arrow-color,
            $bg-color
        );
    }
}

// Set menu link hover state
@mixin menu-link-hover-state(
    $title-color,
    $icon-color,
    $bullet-color,
    $arrow-color,
    $bg-color: null,
    $all-links: true
) {
    $sel: '';

    @if ($all-links == false) {
        $sel: '& > ';
    }

    &.hover > .menu-link:not(.disabled):not(.active),
    #{$sel}.menu-link:hover:not(.disabled):not(.active) {
        transition: $transition-link;

        @include menu-link-theme(
            $title-color,
            $icon-color,
            $bullet-color,
            $arrow-color,
            $bg-color
        );
    }
}

// Set menu link active state
@mixin menu-link-active-state(
    $title-color,
    $icon-color,
    $bullet-color,
    $arrow-color,
    $bg-color: null,
    $all-links: true
) {
    $sel: '';

    @if ($all-links == false) {
        $sel: '& > ';
    }

    #{$sel}.menu-link.active {
        transition: $transition-link;

        @include menu-link-theme(
            $title-color,
            $icon-color,
            $bullet-color,
            $arrow-color,
            $bg-color
        );
    }
}

// Set menu link show state
@mixin menu-link-show-state(
    $title-color,
    $icon-color,
    $bullet-color,
    $arrow-color,
    $bg-color: null,
    $all-links: true
) {
    &.here > .menu-link,
    &.show > .menu-link {
        transition: $transition-link;

        @include menu-link-theme(
            $title-color,
            $icon-color,
            $bullet-color,
            $arrow-color,
            $bg-color
        );
    }
}

// Set menu link here state
@mixin menu-link-here-state(
    $title-color,
    $icon-color,
    $bullet-color,
    $arrow-color,
    $bg-color: null,
    $all-links: true
) {
    &.here > .menu-link {
        transition: $transition-link;

        @include menu-link-theme(
            $title-color,
            $icon-color,
            $bullet-color,
            $arrow-color,
            $bg-color
        );
    }
}
