@import '../../../assets/sass/palette.scss';
.left-border-flat {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left-width: 0 !important;
}
.right-border-flat {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
