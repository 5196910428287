@import '../../../assets/sass/palette.scss';

.selectedFulfilmentBoxBottom {
    background-color: $selected-item;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}
.selectedFulfilmentBox {
    border: 1px $selected-item solid !important;
}
.unSelectedFulfilmentBox {
    border: 1px $input-border-color solid;
}
.selectedLeftButton {
    width: 60%;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
}
.selectedRightButton {
    width: 40%;
    border-bottom-right-radius: 0.4rem;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
    color: rgba(102, 114, 139, 1);
    background-color: #fff;

    &:hover {
        background-color: #f8f8fa;
    }
}
.fulfillSelectIcon {
    background-color: red;
    color: green;
}
.order-item-box {
    width: 282px;
    margin: 0 15px 15px 0;

    &-main {
        padding: 10px;

        &.scanned-box {
            background-color: rgba($color: #61bd4f, $alpha: 0.03);
        }

        .order-product-name {
            font-weight: 500;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            width: 100%;
            color: #1E2749;
            line-height: 21px;

            &:hover {
                text-decoration: underline !important;
            }
        }

        .order-product-variants {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #1E2749;
        }

        .order-product-info {
            &-label {
                color: rgba(161, 165, 183, 1) !important;
            }
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: rgba(75, 82, 109, 1);                
            }
        }
    }
}
.noScrollBarFulfilment::-webkit-scrollbar {
    display: none;
}
.moveBoxUp {
    // transform: translateY(-80px) !important;
    top: 30px !important;

    &.up-direction {
        top: -55px !important;
    }
}
.markScanned-btn {    
    padding: 10px !important;
    color: #00a3ff;
    border-radius: 0 0 6px 6px;
    font-size: 14px;
    line-height: 18px;

    &:hover {
        background-color: #00A3FF1A;
    }

    &:active {
        color: #fff;
        background-color: #00a3ff;
    }
}
.disabled-bottom-btn {
    cursor: not-allowed !important;
    pointer-events: none;
    background-color: #C2C4CC;

    & > * {
        color: #fff !important;
    }
}