.edit-columns {
    // max-height: 370px;

    &-header {
        padding: 20px 20px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #1E2749;
        border-bottom: 1px solid #c2c4cc4d;
    }

    &-content {
        padding: 10px;
        max-height: 310px;

        .menu-item {
            .menu-link {
                padding: 10px !important;
            }

            .svg-icon {
                display: flex;
                align-items: center;
            }

            .drag-icon {
                width: 8px;
                height: 14px;
                margin-right: 10px;

                rect {
                    fill: #c2c4cc !important;
                }
            }

            .field-name {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #4A516C;
                white-space: nowrap;
                width: 200px !important;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .form-switch.form-check-solid.form-switch-sm {
                .form-check-input {
                    height: 14px;
                    width: 28px;
                }
            }
        }
    }    
}