@import '../../../assets/sass/palette.scss';
.error-list-background {
    background-color: #fff3f6 !important;
}
.danger-text {
    color: #f1416c !important;
}
.rotatedArrowIcon {
    transform: rotate(90deg);
}
.floatingBoxes {
    position: absolute !important;
    z-index: 1;
    background-color: white;
    width: 220px;
    border-radius: 10px;
}
.floatingTopBoxes {
    position: absolute !important;
    transform: translateY(-100%);
    background-color: white;
    min-width: 220px;
    top: 0.5rem;
    z-index: 1;
    right: 0;
}
.listBox:hover {
    background-color: $light-gray;
}
.lightGreyBackground {
    background-color: $light-gray;
}
.pencilHover:hover {
    svg {
        color: #00a3ff !important;
    }
}
.pencilSelected {
    outline: 1px solid rgba(0, 163, 255, 0.8);
}
.blankBox {
    min-height: 50px !important;
    max-height: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
}
.stock-level {
    input {
        padding: 0 !important;
        border-radius: 0 !important;
    }
}
.inventory-location-cell {
    display: block;    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}
.inventory-variants-table-row {
    background-color: rgba(248, 248, 250, 0.4);
}