@import '../../../assets/sass/palette.scss';
.printPageContainerOrderPrint {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: $primary-color-white;
    flex: 1;
    display: flex;
    overflow: scroll;
    flex-direction: column;
    align-items: center;
}
.pagesOrderPrint {
    min-width: 210mm;
    max-width: 210mm;
    min-height: 297mm;
    max-height: 297mm;
    padding: 10mm;
    overflow: scroll;
}
.headingContainerOrderPrint {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.orderIdsOrderPrint {
    font-size: 40px;
}
.itemsAmountOrderPrint {
    margin-top: 10px;
    margin-bottom: 10px;
}
.nameOrderPrint {
    font-size: 20px;
}
.theTable {
    width: 100%;
    margin-top: 10px;
}

.theHeadingRow {
    border-bottom: 2px black solid;
}
.theContentRow {
    border-bottom: 1px $gray-border-color solid;
}
.borderRightOrderPrint {
    border-right: 1px $gray-border-color solid;
}
.theHeadingElements,
.theContentElements {
    padding: 8px;
}
.centerTableAlign {
    text-align: center;
}
.leftTableAlign {
    text-align: left;
}
.imageOrderPrint {
    min-height: 80px !important;
    max-height: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
    width: 80px !important;
    height: 80px !important;
    object-fit: contain;
    margin-right: 5px;
    border: 1px solid #ddd;
    border-radius: 7px;
}
.productNameImageContainer {
    display: flex;
    align-items: center;
}

@page {
    size: A4;
}

@media print {
    body * {
        visibility: hidden;
        overflow: visible !important;
    }

    #section-to-print,
    #section-to-print * {
        visibility: visible;
    }
    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
    .footerOrderPrint {
        position: fixed;
        bottom: 5px;
        left: 10px;
    }
    .headerOrderPrint {
        position: fixed;
        top: 5px;
        left: 10px;
    }
}
