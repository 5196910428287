@import '../../../assets/sass/palette.scss';
.minimumBloxkWidthBulkEditors {
    min-width: 140px !important;
    z-index: 9 !important;
}
.customer-name-cell {
    color: #1e2749 !important;
    font-weight: 600;
    font-size: 14px;

    &:hover {
        text-decoration: underline !important;
    }
}
.customer-email-name {
    svg {
        width: 24px !important;
        height: 24px !important;

        &.vip-icon {
            path {
                fill: transparent !important;                
            }
        }
    }
}
.customer-location-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
}
