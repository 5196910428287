@import '../../../assets/sass/palette.scss';
.dropdown-custom-width {
    min-width: 100%;
}
.divider-status {
    background-color: $primary-border-color;
}
.availability-status {
    height: 43px;

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;     
        color: #00a3ff;   
    }

    .svg-icon svg [fill]:not(.permanent):not(g) {
        fill: #00a3ff;
    }
}

.availability-control-item {
    &:hover {
        p {
            text-decoration: underline;
        }
    }
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;     
        color: #4A516C;   
    }

    .svg-icon svg {
        height: 10px;

        [fill]:not(.permanent):not(g) {
            fill: #4a516C;
        }
    }    
}
