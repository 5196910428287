.customer-not-found {
  h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #4b526d;
  }

  p {    
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #a1a5b7;
    margin: 0;
  }
}

.coupon-customers-table {
  height: 370px;

  table {
    thead tr th {
      position: sticky;
      top: 0;
      z-index: 4;
      background-color: #f8f8fa;
    }
  }
}

.segments-box {
  border: 1px solid #e4e6ef;
  padding: 12px;
  border-radius: 7px;
  display: block;

  .segment-item {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 4px 10px;
    border-radius: 5px;
    display: inline-block;
  }
}

.customer-dropdown-menu {
  height: 300px;  

  .customer-block-item {
    border-bottom: 1px solid #e9e9eb;
  }

  &-actions {
    left: 0;
    background-color: #fff;
    bottom: 0;
    padding: 10px;
  }
}

.customer-segments-section {
  width: 500px;
}