//
// Custom Margin and Padding
//

$spaceamounts: (8, 12, 16, 20, 24, 28, 30, 32, 36, 40, 44, 48); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right, all, x, y); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == 'all' {
      .m-#{$space} {
        margin: #{$space}px !important;
      }

      .p-#{$space} {
        padding: #{$space}px !important;
      }
    } @else if $side == 'x' {
      .m-#{$side}-#{$space} {
        margin-right: #{$space}px !important;
        margin-left: #{$space}px !important;
      }

      .p-#{$side}-#{$space} {        
        padding-right: #{$space}px !important;
        padding-left: #{$space}px !important;
      }
    } @else if $side == 'y' {
      .m-#{$side}-#{$space} {
        margin-top: #{$space}px !important;
        margin-bottom: #{$space}px !important;
      }

      .p-#{$side}-#{$space} {        
        padding-top: #{$space}px !important;
        padding-bottom: #{$space}px !important;
      }
    } @else {
      .m-#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p-#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}