@import '../../../assets/sass/palette.scss';

.titleNames {
    visibility: hidden !important;
    [data-kt-aside-minimize='on'] & {
        visibility: visible !important;
        border-radius: 4px !important;
        background-color: $primary-tool-tip !important;
    }
}

.modified-badge {
    margin-left: 10px;
    border-radius: 70px !important;
    padding: 0.5em 0.45em !important;
}

.account-menu {
    max-width: calc(100% - 32px);
    margin: 0 auto 8px;    
    padding: 13px 0px 13px 7px;
    border-radius: 6px;

    &.active {
        background-color: #181F3A;
    }

    &-avatar {
        margin-right: 17px;
    }

    .menu-title {
        color: rgba(255, 255, 255, 0.8);
    }
}

.account-menu-dropdown {
    left: 0;
    top: -160px;
    background-color: #181F3A;
    border-radius: 6px;

    .menu-link {
        padding: 15px 29px !important;
    }

    .menu-item {
        .dropdown-item {
            color: rgba(255, 255, 255, 0.8) !important;

            &:hover {
                background: rgba(0, 163, 255, 0.1);
                border-radius: 5px 0px 0px 5px;
                border-left: 5px solid #00a3ff;
                padding-left: 24px !important;
                color: #00a3ff !important;                
            }
        }        
    }
}

.notification-icon {
    .svg-icon.active-icon svg [fill]:not(.permanent):not(g) {
        fill: #00a3ff;
    }
}

.notifications-dropdown {
    width: 620px;

    .notifications-content {
        max-height: 455px;
        overflow-y: scroll;
    }

    .notification-item {
        &:hover {
            background-color: #f8f8fa;
            border-left: 4px solid #00a3ff;
            padding-left: 4px !important;
        }

        .notification-read-status {
            width: 8px;
            height: 8px;
            background-color: #00a3ff;
            border-radius: 50%;
        }

        &-title {
            p {
                margin: 0;
                color: #a5a9b6;
                font-weight: 400;                
            }

            span {
                color: #1e2749;
                font-weight: 500;
                font-family: 'poppins-medium';
            }
        }

        &-description {            
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;    
            color: #a1a5b7;        
        }

        .notification-more-action {
            right: 8px;
            width: 24px;
            height: 24px;
            background-color: #e6f6ff;

            .svg-icon.active-icon svg [fill]:not(.permanent):not(g) {
                fill: #00a3ff;
            }
        }        
    }
}

.notification-action-dropdown {
    width: 150px;
}