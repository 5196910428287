.color-picker {
  &-input {
    height: 48px;
    width: 78px;
    border: 1px solid #C2C4CC;
    padding: 8px 10px;
    border-radius: 6px;  
    
    &.active {
      border: 1px solid #00A3FF;

      svg {
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        -ms-transition: -ms-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;  
      }

      .active-arrow svg [fill]:not(.permanent):not(g) {
        fill: #00A3FF;
      }
    }

    .selected-color {
      width: 32px;
      height: 32px;
      border: 1px solid #e4e5e9;
      border-radius: 50%;
    }
  }
}


.color-picker-dropdown {
  width: 180px;
  padding: 8px !important;

  .color-picker-tab {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 14px;
    }
  }
  
  // React Colorful Library Styles
  .react-colorful {
    width: 100%;
    height: 146px;

    &__saturation {
      border-radius: 10px;

      &-pointer {
        width: 22px;
        height: 22px;
        border: 5px solid #fff;
      }
    }

    &__hue-pointer {
      width: 16px;
      height: 16px;
      border: 4px solid #fff;
    }

    &__last-control {
      height: 10px;
      margin: 14px 0;
      border-radius: 15px;
    }
  }

  .solid-section {
    .colors-group-row {
      margin-top: 6.5px;
    }
    .color-item {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #e4e5e9;
    }
  }

  .picker-section {
    input {
      padding: 10px 14px;
      width: 100%;
      border: 1px solid #E4E5E9;
      border-radius: 7px;
      color: #A1A5B7;
    }
  }
}