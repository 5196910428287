@import '../../../assets/sass/palette.scss';

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

.printBtn {
    border: 1px solid $primary-secondary !important;
    color: $primary-grey !important;
    height: 30px !important;
    width: 70px !important;
    padding: 4px !important;
    font-size: 12px;
}
.recieptBtn {
    border: 1px solid $primary-secondary !important;
    color: $primary-grey !important;
    height: 30px !important;
    width: 75px !important;
    padding: 4px !important;
    font-size: 12px;
}

.lineStyle {
    width: 400px;
    margin-left: -22px !important;
}

.dotsIcon {
    height: 19px;
    margin-top: 6px;
    position: relative;
}
.closeOrderIcon {
    height: 25px;
    margin-top: -31px !important;
}
.arrowOrderDown {
    height: 22px;
    margin-top: -17px !important;
}
.product {
    margin-left: 10px;
}
.orderedItem {
    padding-top: 23px;
    width: 330px;
}
.tax {
    width: 280px;
}
.paymentDetails {
    margin-right: 22px;
}

.customerLogo {
    border-radius: 50% !important;
    object-fit: cover;
}

.customerName {
    font-family: 'poppins-regular';
    font-size: 16px;
    padding-left: 10px;
}

.greyText {
    color: $gray-border-color;
}
.phoneIn {
    width: auto !important;
}
.inputBoxStyle {
    width: 179px !important;
}
.postBtn {
    padding: 6px 12px !important;
    color: white !important;
    font-size: 12px !important;
    line-height: 21px !important;
    border-radius: 5px !important;
}
.commentText {
    font-size: 10px !important;
    margin-left: 52px !important;
    width: max-content !important;
}

.statusText {
    padding-left: 20px;
}
.mailStatus {
    font-size: 12px;    
}
.vertical {
    border-left: 0.5px solid grey;
    margin-left: 9px;
    opacity: 0.3;
    margin-left: 17px;
}
.v1 {
    height: 95px;
}
.v2 {
    height: 20px;
}
.resendBtn {
    height: 38px;
    padding-top: 9px;
    margin-top: 12px;
}
.process {
    color: black;
    font-family: 'poppins-regular';
}

form {
    display: flex;
    flex-direction: row;
    border: 1px solid $input-grey;
    padding: 4px;
    background: $input-grey;
    border-radius: 5px;
}

.orderDate {
    font-size: 12px;
    font-family: 'poppins-regular';
}
// .container {
//     padding: 0 30px;
//     padding-right: 0px !important;
// }
.addressStyle {
    line-height: 0;
}
.editText {
    font-size: 12px !important;
}
.orderPreviewList {
    .modal-dialog {
        position: absolute !important;
        right: 25px !important;
        display: block !important;
    }
}
.dropDownItem {
    position: absolute;
    left: -116px;
    top: 24px;
}
@include for-big-desktop-up {
    .orderDate {
        font-size: 12px;
        font-family: 'poppins-regular';
    }
}
.order-status-preview-line {
    width: auto !important;
    float: left;
}
.order-status-box {
    border-radius: 18px;
    padding: 6px 12px;
    width: fit-content;

    &.pending-order-status-box {
        background: rgba(72, 83, 106, 0.1);
        color: #48536A;
    }

    &.to-process-order-status-box {
        background: rgba(122, 134, 160, 0.1);
        color: #7A86A0;
    }

    &.to-fulfil-order-status-box {
        background: rgba(0, 163, 255, 0.1);
        color: #00a3ff;
    }

    &.to-ship-order-status-box {
        background: rgba(172, 127, 255, 0.1);
        color: #ac7fff;
    }

    &.shipped-order-status-box {
        background: rgba(143, 44, 214, 0.1);
        color: #8F2CD6;
    }

    &.error-order-status-box {
        background: rgba(235, 90, 70, 0.1);
        color: rgba(235, 90, 70, 1);
    }
}
.order-status-menus {
    position: absolute;
    right: 10px;
    top: -50px;
}
.btn-order-secondary {
    padding: 8px 10px;
    background: rgba(0, 163, 255, 0.1);
    border-radius: 5px;
    color: #00a3ff;
    outline: none;
    border: none;
    margin-left: 10px;
}

.com-ordr-row {
    padding-top: 18px;
}
.com-ordr-code{color: var(--black-black, #1E2749);    
    font-size: 16px;
    font-weight: 600;
}
