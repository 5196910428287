.position-inherit {
    position: inherit !important;
}
body.modal-open {
    height: 100vh;
    overflow-y: hidden !important;
}

.cursor-pointer {
    cursor: pointer !important;
}
.sub-menu-item .menu-link button[data-csvbox-initator='true']{
    background: transparent;
    box-shadow: none;
    text-shadow: none;
    border: 0
}