.live-description {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #8B90A1;
}

.home-subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #1e2749;
}

.home-analytics-card {
  margin: 12px 0 !important;
  h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;    
    color: #1e2749;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 13px;  
    color: #a1a5b7;

    span {            
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      
      &.positive-value {
        color: #61bd4f;
      }

      &.negative-value {
        color: #f1416c;
      }
    }
  }  

  &.h-380px {
    height: 380px;
  }
}

.channel-profit-item {
  display: flex;  
  border-bottom: 1px solid #e9e9eb;
  height: 40px;

  &.no-border {
    border: none;
  }

  &.subitem {
    div {
      color: #66728b;
    }
  }

  div {
    flex-basis: 100%;    
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    color: #1e2749;
    display: flex;
    align-items: center;
  }
}

.svg-icon-select {
  svg {
    height: 22px !important;
    width: 22px !important;
    path {
      transition: stroke 0.15s ease-in-out;
    }
  }
}

.btn-primary, .btn-secondary:hover {
  .svg-icon-select {
    svg {
      path {
        stroke: white !important;
      }
    }
  }
}

.live-indicator-slide {
  padding: 0 10px;
  &.first {
    padding-left: 0px;
  }
  &.last {
    padding-right: 0px;
  }
}

.slick-slide {
  border-right: none !important;
}

.custom-arrow.slick-prev:before,
.custom-arrow.slick-next:before {
  content: none !important; /* Removes the default arrows */
}

th.live-header-action{
  width: 30px;
}

td.live-header-action {
  width: 30px;
}

.live-search-bar-container {
  padding: 1rem 0;
  align-items: center;
  .search-bar-box {
    width: 360px !important;
  }
}

.live-product-table-container {
  .expand-arrow-icon.active {
    svg {
      background-color: rgb(255 255 255 / 10%);
      filter: invert(44%) sepia(35%) saturate(3228%) hue-rotate(177deg)
      brightness(103%) contrast(110%);
    }
  }
}

.live-reprot-page-title {
  font-size: 30px !important;
  line-height: 36px;
  font-weight: 600;
  font-family: 'poppins-regular';
  color: #1e2749;
  margin-bottom: 0;
}