.box-padding {
  padding: 21px 30px 22px !important;
}

.product-count-span{
  line-height: 16px !important;
  font-size: 14px !important;
}

.light-discard-btn {
  line-height: 18px !important;
  padding: 16px 24px !important;
  font-size: 18px !important;
}