@import '../../../../assets/sass/palette';
.taxes {
    .input-group-addon {
        background-color: $primary-color-white;
        border: 1px solid #e4e6ef;
        padding-top: 12px;
        padding-right: 30px;
    }
    .input-no-border {
        border-right: none !important;
    }
}
// ADDED TO SHOW ON TOP RIGHT
.success-modal {
    .modal-dialog {
        position: absolute !important;
        right: 25px !important;
        display: block !important;
    }
}

.tax-discard-button {
    background-color: #EB5A46 !important;
}

.w-42 {
    width: 42% !important;
}

.w-84 {
    width: 85% !important;
}

.secondary-text {
    color: $secondary-grey-shade !important;
}

.tax-heading-container {
    display: flex;
    flex-direction: "row";
    align-items: center;
    justify-content: space-between;
    
}

.sandbox-container{
    display: flex;
    flex-direction: "row";
    align-items: center;
    justify-content: center;
}
.setting-container{
    display: flex;
    flex-direction: "row";
    align-items: center;
    justify-content: space-between;
}
.sandbox-text{
    margin-right: 5px;
}

.con-load-container{
    margin-left: 15px;
}