@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
.orderDetailsPage {
    .leave-body-size {
        min-width: 600px;
    }
    .thinDropdownTextOrderDetails {
        font-style: normal;
        font-weight: thin;
        color: #4a516c;
        opacity: 0.8;
    }

    @media (max-width: 576px) {
        .leave-body-size {
            min-width: auto;
        }
    }
    @media (max-width: 768px) {
        .leave-body-size {
            min-width: auto;
        }
    }

    .card {
        margin-bottom: 20px;
    }

    .pt-18px {
        padding-top: 18px !important;
    }

    .price-color {
        color: $secondary-grey-shade;
    }

    .tagBadge {
        font-size: 12px !important;
        background-color: $tag-Color !important;
        color: $tagText-Color !important;
        padding: 0px !important;
        padding-left: 5px !important;
    }

    .closeIconBadge {
        filter: $filterBlueColor !important;
    }
    .hideScrollBar::-webkit-scrollbar {
        display: none;
    }

    .tagInput {
        padding: 10px;
        border: 1px solid $primary-border-color;
        border-radius: 10px;
        margin: 3px;
    }

    .userNameStyle {
        margin-right: 17px;
    }
    .notesLogo {
        height: 40px !important;
        width: 40px !important;
        border-radius: 50% !important;
    }

    .customerName {
        font-family: 'poppins-regular';
        font-size: 12px !important;
        padding-left: 10px;
    }

    .timelineForm {
        width: 105vh !important;
    }

    @include for-big-desktop-up {
        .timelineForm {
            width: 118vh !important;
        }
    }

    .timeLineVertical {
        border-left: 0.5px solid grey;        
        opacity: 0.3;
        margin-left: 22px;
        width: 1px !important;
    }

    .combined-order-description {
        a {
            padding-right: 4px;
        }
    }

    .resendEmail {
        height: 38px;
        padding-top: 9px;
        margin-top: 12px;
        display: block !important;
    }

    .v2 {
        height: 20px;
    }

    .orderType {
        width: 163px !important;
    }

    .dropStyle {
        border: 1px solid $primary-border-color !important;
        color: #4b526d !important;
        font-family: 'THICCCBOI';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    }

    .priceInput {
        width: 70px !important;
        height: 40px;
    }

    .productName {
        padding-left: 0px;
    }

    .alignLeft {
        left: 16px !important;
        position: absolute;
    }

    .recieptCancel {
        margin-left: 251px !important;
    }

    .orderDetails {
        .modal-dialog {
            position: absolute !important;
            right: 25px !important;
            display: block !important;
        }
    }

    .bg-error-red {
        background-color: $primary-error-button-background !important;
    }

    .success-btn {
        background-color: white !important;
        border: 1px solid #34c759 !important;
    }

    .order_variant {
        color: $light-gray-text;
    }
    .lightBlueButtonBack {
        background-color: $schd-Btn-Color !important;
    }

    .purpleButton {
        background-color: $purple-Btn !important;
        color: $purple-Btn-Color !important;
    }
    .order-place-time {
        color: #1e2749 !important;
    }
    .order-variant-title {
        font-size: 1.15rem !important;
    }

    .orderSummaryTable {
        overflow-x: clip !important;
        .qty-dropdown {
            width: 100px !important;
        }
        .d-flex.align-items-center.justify-content-center {
            .position-relative {
                .dropdown-box {
                    min-width: 200px !important;
                }
            }
        }
        .dropdown-menu {
            right: 0px !important;
            max-height: 100px !important;
            overflow: auto !important;
            min-width: 200px !important;
            position: absolute !important;
            left: 0;
            div {
                width: 100% !important;
            }
        }
    }

    .roundButton {
        background: rgba(241, 65, 108, 0.1) !important;
        line-height: 13.84px !important;
    }
    .partialShippingButton {
        background: rgba(241, 65, 108, 1) !important;
        border-radius: 1rem;
    }

    .orderSummary {
        .osImage {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            object-fit: cover;
        }
        .osHeading {                                    
            color: #1e2749;
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
            font-family: 'poppins-medium';
            white-space: nowrap;
            width: 128px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .osLocation {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #a1a5b7;
        }
        .osLight {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #4b526dcc;
        }
        .unAvailItems {
            padding: 2px 6px;
            background: rgba(241, 65, 108, 0.1);
            border-radius: 5px;
            font-weight: 500;
            font-size: 12px;
            text-transform: capitalize;
            color: #f1416c;
        }
        .filfileldItems {
            padding: 2px 6px;
            border-radius: 5px;
            font-weight: 500;
            font-size: 12px;
            text-transform: capitalize;
            color: white;
        }
        .osTotal {
            font-weight: 500;
            font-size: 14px;
            color: #4b526d;
        }
        .osMulAmount {
            font-weight: 400;
            font-size: 14px;
            color: #66728b;
        }
        .osVariantName {
            font-weight: 500;
            font-size: 12px;
            color: #4b526d;            
            white-space: nowrap;
            width: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .rightToDown {
            transform: rotate(90deg);
        }
        .osQuantText {
            font-weight: 500;
            font-size: 12px;
            color: #4b526d;
        }
        .noBorderColor {
            border-color: rgba(255, 255, 255, 0);
        }
    }

    .order-timeline-icon {
        width: 24px;
        height: 24px;
    }
}

#order-address {
    #order-address-edit {
        visibility: hidden;
    }
}

#order-address:hover {
    #order-address-edit {
        visibility: visible;
    }
}