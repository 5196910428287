.sort-dropdown {
    .dropdown-header {
        padding: 20px;

        div:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #1E2749;
        }

        div:nth-child(2) {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .dropdown-content {
        padding: 10px;

        .dropdown-item {
            &.menu-link:not(.text-primary) {
                color: rgba($color: #1E2749, $alpha: 0.8) !important;
            }

            &.menu-link {
                padding: 10px;

                &.text-primary {
                    color: #00a3ff !important;
                }
            }
        }
    }
}