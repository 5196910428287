@import '../../../assets/sass/palette.scss';

.live-show-comment-post-btn {
    right: 0.25rem;
    top: 0.6rem;
    font-size: 1rem !important;
    padding: 0.5rem !important;
}

.emoji-icon {
    position: absolute;
    right: 15px !important;
    &.show-post-btn {
        right: 4rem !important;
    }
}
.emoji-picker-react {
    position: absolute !important;
    right: 32%;
}
.emoji-picker-react {
    z-index: 1
}
.emoji-picker-div {
    // width: 69%;
    // float: right;
    width: 460px;
    position: absolute;
    right: 32%;
    z-index: 10000;

    .emoji-mart {
        width: 100% !important;
        box-shadow: 0px 10px 30px 0px #13103933;
        border-radius: 15px;
        border: none;

        &-bar {
            padding: 20px;
            border: none;

            .emoji-mart-anchors {
                padding: 0;
                .emoji-mart-anchor {
                    min-width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 1px;

                    .svg-icon {
                        svg {
                            path {
                                fill: #4a516c;
                            }
                        }
                    }

                    &:hover,
                    &-selected {
                        background-color: #00a3ff;
    
                        .emoji-mart-anchor-bar {
                            display: none !important;
                        }

                        .svg-icon {
                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }

                    &-icon {
                        svg {
                            width: 20px !important;
                            height: 20px !important;
                        }
                    }
                }
            }
        }

        &-search {
            padding: 0 32px 0 30px;
            margin: 0;

            input {
                height: 40px;
                font-size: 14px;
                font-family: 'poppins-medium';
                padding: 5px 18px;

                &:focus {
                    border: 1px solid #00a3ff;
                }
            }

            &-icon {
                top: 8px;
                right: 35px;
            }
        }

        &-scroll {
            padding: 0 0 0 20px;
            margin: 20px 0;
            height: 274px;
        }

        &-category {
            .emoji-mart-emoji {
                span {
                    cursor: pointer;
                }
            }
            &-label {
                display: none;
                span {
                    font-size: 14px;
                    padding: 10px;
                    font-family: 'poppins-medium';
                    background-color: #fff !important;             
                }
            }
        }
        
        &-emoji {
            width: 46.1px;
            height: 46.1px;
        }
    }

    // .EmojiPicker_picker__19Vln {
    //     position: absolute !important;
    //     z-index: 1;
    //     background-color: white;
    //     width: 45%;
    //     box-shadow: 0px 10px 30px rgb(19 16 57 / 20%);
    //     border: 0;
    // }
    // .EmojiPicker_header__eDZPD {
    //     background-color: white;
    //     border-bottom: 0 !important;
    // }
    // h3.EmojiCategory_categoryName__zHcOq {
    //     display: none;
    // }
    // .EmojiArea_emojis__L4mMq {
    //     background-color: white;
    // }
    // .Emoji_emoji__iKc1G:hover {
    //     background-color: white;
    // }
    // .EmojiContainer_emojiContainer__4SPb5 {
    //     gap: 15px !important;
    // }
}
.emoji-mart.emoji-mart-light {
    z-index: 1;
}
.display-none {
    display: none !important;
}
.emoji-textarea {
    width: 95% !important;
    color: black;
    border: 1px solid $primary-blue-background !important;
    border-radius: 10px;
    padding: 10px;
    margin-left: 1px;
    [data-kt-aside-minimize='on'] & {
        width: 280px;
    }
    &:active {
        border: 0px !important; // 1px solid $primary-blue-background !important;
        border-radius: 10px !important;
    }
    &:focus {
        outline: 0px !important; // 1px solid $primary-blue-background !important;
        border-radius: 10px !important;
    }
}
.emoji-top-70 {
    top: 70%;
}
.emoji-icon-margin {
    margin: 5px !important;
}
.svg-hover {
    right: 10px !important;
}