@import '../../../assets/sass/palette.scss';
.left-border-flat {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left-width: 0 !important;
}
.right-border-flat {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.disabled-input {
    background-color: $input-grey !important;
    cursor: not-allowed;
}

.radio-normal {
    height: 14px;
    width: 14px;
}

.toolTipShipMode {
    width: 390px !important;
    font-size: 14px;
}

.toolTipShipModeLine {
    line-height: 20px;
}

