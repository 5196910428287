@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

.oneThirdCustomerDetails {
    width: 33%;
}
.rightBorderCustomerDetails {
    border-right: 0.5px $gray-border-color solid;
}

.customerImageCustomerDetails {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
}
.lightPrimaryButton {
    background-color: $primary-blue-light-background !important;
    color: $primary-blue-background !important;
}
.table-dark-strip {
    background: $light-gray-table;
}
.vip-icon {
    path {
        fill: transparent !important;
    }
}
