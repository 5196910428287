.white-hover-text:hover {
    color: white !important;
}
.featured-products {
    .featured-product-title {
        line-height: 35px;
        font-size: 30px;
        font-weight: 500;
    }
    .list-title {
        font-weight: 500;
        line-height: 35px;
    }
    .product-list-title {
        font-weight: 600;
        font-size: 25px !important;
        line-height: 35px;
        letter-spacing: 1px;
    }
    .list-product-name {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .list-status-title {
        font-weight: 600;
        font-size: 25px;
        line-height: 35px;
        letter-spacing: 0.5px;
    }
}
