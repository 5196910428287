//Product Details

.product {
    .expand-arrow-icon {
        @include svg-icon-color(rgba(75, 82, 109, 1));
    }

    .default-banner-box {
        width: 80px;
        background: #ffc700 !important;
        border-radius: 5px;
        font-size: 12px;
        line-height: 24px;
        color: #4a516c;
        padding: 5px 10px !important;
    }
    .default-banner-arrow {
        @include svg-icon-color(#4a516c);
        margin-right: 1px;
        transform: translateX(-1px);
    }

    .secondary-badge {
        border-radius: 8px;
        padding: 5px;
    }

    .actProductsBtnSelected {
        color: white !important;
        background: #369ff7 !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}

.active-status-box {
    width: 80px;
    background: #eefaf4 !important;
    border-radius: 18px;
    font-size: 12px;
    line-height: 24px;
    color: #50cd89;
    padding: 5px 10px !important;

    &.cursor-pointer {
        &:hover {
            color: #fff !important;
            background: #50cd89 !important;
            .active-arrow {
                @include svg-icon-color(#ffffff);
            }
        }
    }    
}

.collection-badge {
    background: #f8f8fa;
    border-radius: 8px;
    color: #66728b;

    &.disabled-tag {
        color: #A1A5B7;
    }
}

.sort-icon {
    @include svg-icon-color(rgba(0, 163, 255, 1));
}

.active-arrow {
    @include svg-icon-color(#50cd89);
}

.draft-arrow {
    @include svg-icon-color(#ffc700);
}

.archive-arrow {
    @include svg-icon-color(#ff7373);
}

.inactive-arrow {
    @include svg-icon-color(#ff7373);
}

.scheduled-arrow {
    @include svg-icon-color(#00a3ff);
}

.update-arrow {
    @include svg-icon-color(rgba(185, 100, 0, 0.5));
}

.ready-arrow {
    @include svg-icon-color(rgba(7, 138, 21, 0.5));
}

.draft-status-box {
    width: 80px;
    background: rgb(255, 199, 0, 0.1) !important;
    border-radius: 18px;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 199, 0);
    padding: 5px 10px !important;

    &.cursor-pointer {
        &:hover {
            color: white !important;
            background: rgb(255, 199, 0) !important;
            .draft-arrow {
                @include svg-icon-color(#ffffff);
            }
        }
    }
}

.archive-status-box {
    width: 80px;
    background: rgb(255, 115, 115, 0.1) !important;
    border-radius: 18px;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 115, 115);
    padding: 5px 10px !important;

    &.cursor-pointer {
        &:hover {
            color: white !important;
            background: rgb(255, 115, 115) !important;
            .archive-arrow {
                @include svg-icon-color(#ffffff);
            }
        }
    }    
}

.expired-status-box {
    width: 80px;
    background: rgb(255, 115, 115, 0.1) !important;
    border-radius: 18px;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 115, 115);
    padding: 5px 10px !important;

    &.cursor-pointer {
        &:hover {
            color: white !important;
            background: rgb(255, 115, 115) !important;
            .archive-arrow {
                @include svg-icon-color(#ffffff);
            }
        }
    }
}

.inactive-status-box {
    width: 80px;
    background: rgb(255, 115, 115, 0.1) !important;
    border-radius: 18px;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 115, 115);
    padding: 5px 10px !important;

    &.cursor-pointer {
        &:hover {
            color: white !important;
            background: rgb(255, 115, 115) !important;
            .inactive-arrow {
                @include svg-icon-color(#ffffff);
            }
        }
    }
}

.scheduled-status-box {
    width: 100px;
    border-radius: 18px;
    font-size: 12px;
    line-height: 24px;
    padding: 5px 10px !important;
    background: rgba(0, 163, 255, 0.1) !important;
    color: #00a3ff !important;

    &.cursor-pointer {
        &:hover {
            color: white !important;
            background: #00a3ff !important;
            .scheduled-arrow {
                @include svg-icon-color(#ffffff);
            }
        }
    }    
}
