.select-downarrow-icon {
    top: 28px;
    right: 3px;
}

.bulk-editor-content {
    height: 600px;

    &.expanded-bulk-editor {
        height: calc(100vh - 130px);
    }
}

.bulk-editor-footer {
    height: 80px;
}

.dimension-select {
    width: 60px;
}

.dimension-select-dropdown {
    min-width: 100px !important;
}

// Styles data-sheet
.dsg-add-row {
    display: none;
}

.bulk-editor-table {
    .dsg-container {
        height: 100% !important;
    }    
}

.dsg-container {

    .dsg-cell-header-container {
        color: #1e2749;        
        font-family: 'poppins-medium';
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;        
    }

    .dsg-row-header {
        background: #f8f8fa;
    
        .dsg-cell {
            background: #f8f8fa;
        }
    }
    
    .dsg-active-cell, .dsg-selection-rect, .dsg-expand-rows-indicator {
        border-color: #00a3ff !important;
    }

    .dsg-selection-rect {
        display: none;
    }

    .product-name-input {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.dsg-context-menu {
    .dsg-context-menu-item:nth-child(4), 
    .dsg-context-menu-item:nth-child(5) {
        display: none !important;
    } 
}
