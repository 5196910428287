@import '../../../assets/sass/palette.scss';

.livestream-search-bar {
    width: 250px;
    transition-duration: 250ms;
    transition-timing-function: ease-out;
    &:focus-visible {
        width: 400px;
    }
    &:not(:placeholder-shown) {
        width: 400px;
    }
}

.DatesSelectorWeekContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
}
.vertical-divider {
    background-color: $input-border-color;
    width: 5px;
    height: 100%;
    display: block;
}

.squareCalBlock {
    width: 40px;
    height: 40px;
}
.squareCalBlockChild {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.absoluteLeft {
    left: 0;
}
.dataCalendarBlock {
    min-height: 4rem;
}
.fc {
    background: white;
}
.calendar-view {
    // height: 700px !important;
    min-height: calc(100vh - 160px);
    .overflow-scroll {
        overflow: scroll !important;
    }

    .event-card {
        padding: 12px !important;
    }

    .cursor-pointer.bg-light.rounded.d-flex.align-items-center.justify-content-center.px-5.py-1 {
        padding-top: 14px !important;
        padding-bottom: 14px !important;
    }
    .fc-header-toolbar.fc-toolbar {
        display: none;
    }
    .fc-theme-standard th {
        border: 0px !important;
    }
    .fc-theme-standard .fc-scrollgrid {
        border: 0px !important;
    }
    .fc-col-header-cell.fc-day {
        height: 64px;
        vertical-align: inherit;
    }
    .fc-day-today {
        background: #00a3ff;
        border-radius: 8px;
        a {
            color: white;
        }
    }
    .fc .fc-col-header-cell-cushion {
        text-transform: uppercase;
    }
    .fc-timegrid-slot.fc-timegrid-slot-lane {
        height: 51px !important;
    }
    .fc .fc-timegrid-slot-label,
    .fc-timegrid-axis {
        border: 0px;
    }
    // .fc-scrollgrid.fc-scrollgrid-liquid {
    //     thead {
    //         display: none;
    //     }
    // }
    .fc-day-past {
        background-color: #f9f9f9 !important;
    }
    .fc-timegrid-now-indicator-line {
        border-color: #00A3FF !important;
    }
    .fc-day-future {
        .fc-timegrid-col-frame {
            cursor: pointer !important;
            z-index: 1;
        }
    }
    // .fc-col-header {
    //     display: none;
    // }
    .fc-scrollgrid-section.fc-scrollgrid-section-header {
        display: none;
    }
    .fc-day-today {
        background-color: white !important;
    }
    .fc-daygrid-body.fc-daygrid-body-unbalanced.fc-daygrid-body-natural,
    .fc-timegrid-divider.fc-cell-shaded {
        display: none;
    }
    .fc-event-title {
        font-size: 15px;
    }
    .overlappingImage {
        border: 2px solid white;
        margin-right: -8px;
        width: 28px;
        min-width: 28px;
        height: 28px;
        min-height: 28px;
        object-fit: cover;
    }
    .eventContainer{
        display: flex;
        align-items: center;
    }
    .overviewText {
        font-size: 10px;
    }
    .overviewTimeText {
        font-size: 8px;
        margin-top: 1px;
    }
    .singleLine {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .titleTextCal {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        word-break: break-word !important;
        margin: 0;
        color: #13192e;
    }
    .descTextCal {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
    .evenContentDB {
        display: block;        
        z-index: 1000000;
    }
    .evenContentDN {
        display: none;
    }
    .extendedWidth {
        width: 110%;
        transform: translateX(-4%) translateY(-4%);
    }
    .moveButtons {
        background-color: #f8f8fa;
        border: 1px #f8f8fa solid;
        &:hover {
            background-color: #e5f6ff;
        }
        &:active {
            background-color: #e5f6ff;
            border: 1px #00a3ff solid;
        }
    }
    .moveText {
        color: #8b90a1;

        &:hover {
            color: #00a3ff;
        }
        &:active {
            color: #00a3ff;
        }
    }
    .roundedRight {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }
    .roundedLeft {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
    }
    .headerMonthStyle {
        font-size: 25px;
        font-weight: 500;
        line-height: 37.5px;
    }
    .fc-event {
        border-radius: 10px;
    }
    .date-number-text {
        font-size: 16px;
        line-height: 22px;
    }
}

.fixedBlock {
    position: absolute;
    border-radius: 10px;
    z-index: 1111;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .overlappingImage {
        border: 2px solid white;
        margin-right: -8px;
        width: 28px;
        min-width: 28px;
        height: 28px;
        min-height: 28px;
        object-fit: cover;
    }

    .descTextCal {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .titleTextCal {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        word-break: break-word !important;
        margin: 0;
        color: #13192e;
    }

    &.live {
        &:hover {
            box-shadow: 0px 5px 30px 0px rgba(246, 78, 96, 0.10) !important;
        }

        &:active {
            box-shadow: 0px 15px 30px 0px rgba(246, 78, 96, 0.20) !important;
        }
    }

    &.past {
        &:hover {
            box-shadow: 0px 5px 30px 0px rgba(19, 16, 57, 0.10) !important;
        }

        &:active {
            box-shadow: 0px 10px 30px 0px rgba(19, 16, 57, 0.15) !important;
        }
    }

    &.upcoming {
        &:hover {
            box-shadow: 0px 5px 30px 0px rgba(0, 163, 255, 0.10) !important;
        }

        &:active {
            box-shadow: 0px 15px 30px 0px rgba(0, 163, 255, 0.20) !important;
        }
    }
}

.liveshow-image-list {
    margin-bottom: 12px;
}

.liveshow-calendar-section {
    height: calc(100vh - 335px);

    @media screen and (min-width: 1921px) {
        height: calc(100vh - 350px);
    }
}