.background-panel {
  border-radius: 10px;
  width: 100%;
  // max-width: 61.71rem;
  // height: 100%;
  background-color: #1e2749;

  @media screen and (max-width: 1279px) {
    height: calc(var(--ZOOM-MULTIPLIER) * 31.6rem) !important;
  }

  .analytics-board {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: radial-gradient(51.25% 89.01% at -13.54% 134.78%, rgba(2, 49, 232, 0.65) 0%, rgba(2, 49, 232, 0) 100%);

    .pattern-image {
      padding: calc(var(--ZOOM-MULTIPLIER) * 3.57rem);
      color: #ffffff;
      width: 100%;
      height: 100%;
      background-image: url('../../../assets/icons/BoardPattern.svg');
      background-repeat: no-repeat;
      background-position-x: right;
      // min-height: calc(100vh - calc(var(--ZOOM-MULTIPLIER) * 37.96rem));

      .total-sales {
        margin-top: 1rem;

        .sales-title {
          font-weight: 300;
          font-size: calc(var(--ZOOM-MULTIPLIER) * 1.14rem);
          line-height: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
        }

        .sales-value {
          font-weight: 700;
          font-size: calc(var(--ZOOM-MULTIPLIER) * 3.43rem);
          line-height: calc(var(--ZOOM-MULTIPLIER) * 3.43rem);
          letter-spacing: calc(var(--ZOOM-MULTIPLIER) * 0.43rem);
        }
      }

      .target-goal {
        margin-bottom: calc(var(--ZOOM-MULTIPLIER) * 5rem);

        .bar-class {
          background-color: #ffffff1a;
          height: calc(var(--ZOOM-MULTIPLIER) * 0.6rem);
          border-radius: 50px;
        }

        .title-wrapper {          
          min-width: calc(var(--ZOOM-MULTIPLIER) * 19.78rem);

          .goal-title {
            font-weight: 300;
            font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
            line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
          }

          .goal-value {
            font-weight: 600;
            font-size: calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
            line-height: calc(var(--ZOOM-MULTIPLIER) * 3.21rem);
          }
        }

        .like-score {
          .heart-fill-icon {
            bottom: 0;
            left: calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
            z-index: 99;
          }

          .heart-animation {
            bottom: calc(var(--ZOOM-MULTIPLIER) * 1.43rem);
            right: 0.4rem;
          }

          .score-count {
            font-size: calc(var(--ZOOM-MULTIPLIER) * 1.29rem);
            line-height: calc(var(--ZOOM-MULTIPLIER) * 2rem);
            font-weight: 700;
          }
        }
      }

      .order-title {
        font-weight: 300;
        font-size: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
        line-height: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
      }

      .order-value {
        margin-top: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
        font-weight: 700;
        font-size: calc(var(--ZOOM-MULTIPLIER) * 1.57rem);
        line-height: calc(var(--ZOOM-MULTIPLIER) * 1.14rem);
      }
    }
  }
}
