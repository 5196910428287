@import '../../../assets/sass/palette.scss';
.purchase-order-card {
    border-radius: 7px;
    background: $primary-color-white;
    cursor: pointer;
}
.title {
    color: $gray-border-color;
}
.card-image-bottom {
    border-radius: 50%;
    height: 23px;
    width: 23px;
    object-fit: cover;
}
.card-image-top {
    width: 20px;
    height: 20px;
    border-radius: 5px;
}
.breaker {
    height: 1px;
    display: block;
    background: #f4f4f5;
}
.text {
    font-family: 'poppins-Regular';
    padding-right: 0;
}
.text-medium {
    font-family: 'poppins-medium';
    padding-right: 0;
}
.text-bold {
    font-family: 'poppins-SemiBold';
    padding-right: 0;
}
