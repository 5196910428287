.sales-channels {

  &-container {
    max-width: 778px;
    margin: 0 auto;
  }

  .facebook-logo {
    width: 90px;
    background-color: #23599b;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 12px;
  }

  button {
    max-width: 155px;
  }

  .facebook-connected-account {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    color: #34C759;
  }
}