@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

.expandArrow {
    width: 11px;
    margin-right: 10px;
}
.nodeText {
    margin-left: 5px;
}

.customNodeRoot {
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;

    .expandIconWrapper {
        margin-left: 25px;
    }

    .collection-drag-icon {
        left: 5px;
    }

    &.disabled-item {
        pointer-events: none;
    }
}

.customNodeRoot:hover {
    background: rgba(0, 163, 255, 0.08);
    border-radius: 7px;
}
.collectionTable {
    // margin-bottom: 30px !important;
    font-size: 14px;
}
.collectionTable:hover {
    background: none;
}
.labels {
    margin-right: 5px;
}
.collectText {
    text-overflow: ellipsis;
    width: 100px;
    overflow: hidden;
}
.editButtons {
    position: relative;
    top: -2px;
    padding-left: 5px;
    padding-right: 5px;
}

.pencilIcon {
    padding-left: 20px;
}

.pencilIcon:hover {
    filter: $filterBlueColor;
}

.plusIcon:hover {
    filter: $filterBlueColor;
}

.trashIcon:hover {
    filter: $filterRedColor;
}

.editArea {
    position: fixed;
    top: 79%;
    width: 340px;
    background: white;
    height: 105px !important;
    right: 36px;
}

.editInput {
    width: 100%;
    background: white;
    border: 1px solid $input-border-color;
    box-sizing: border-box;
    border-radius: 7px;
    height: 40px;
    padding-left: 4px;
    padding-right: 4px;
}

.editBtnGroup {
    margin-left: 150px;
    margin-top: 3%;
}

.collectionImg {
    background: $primary-white-background;
    border-radius: 5px;
    height: 50px !important;
    width: 50px !important;
    margin-right: 5px !important;
    border-radius: 5px !important;
    margin-left: 10px;
}

.collectionName {
    min-width: 151px;

    span {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;     
    }
}

.productCount {
    min-width: 149px;
}

.statusBtn {
    border-radius: 18px !important;
    height: 30px !important;
    color: $primary-color-white !important;
}

.statusDrpBtn {
    background: $act-Btn-Color !important;
    color: $select-drop-hover !important;
    opacity: 0.6 !important;
}

.inactDrpBtnColor {
    background: rgba(255, 115, 115, 0.1) !important;
    color: #ff7373 !important;
}

.schdDrpBtnColor {
    background: rgba(0, 163, 255, 0.1) !important;
    color: #00a3ff !important;
}

.collectionType {
    min-width: 152px;
    text-transform: capitalize;
}

.status {
    min-width: 148px;
}

.expandIcon {
    height: 31px !important;
    width: 20px !important;
}
.collectionImg {
    background: $primary-white-background;
    border-radius: 5px;
    height: 50px !important;
    width: 50px !important;
    margin-right: 5px !important;
    border-radius: 5px !important;
}

.collection-row {
    width: 100%;

    .main-cell {
        width: 60%;
    }
    .name-cell {
        margin-left: 28px;
    }

    .productCount,
    .collectionType,
    .status {
        width: 15%;
    }
}

@include for-big-desktop-up {
    .pencilIcon {
        padding-left: 50px;
    }

    .editArea {
        top: 73.5%;
        width: 431px;
        right: 24px;
        height: 108px !important;
    }

    .editInput {
        width: 100%;
        margin-top: 10px;
    }
    .editNestedInput {
        margin-top: 10px;
        margin-left: 40px;
        width: 90%;
    }

    .editBtnGroup {
        margin-left: 225px;
        margin-top: 2%;
    }
    // .collectionName {
    //     min-width: 150px;
    // }

    // .productCount {
    //     min-width: 240px;
    // }

    // .collectionType {
    //     min-width: 350px;
    // }

    // .status {
    //     min-width: 150px;
    // }
}

.collectionThumbImg {
    height: 50px !important;
    width: 50px !important;
    min-width: 50px !important;
    object-fit: cover;
    border-radius: 10px;
}
.tooltip-inner {
    background-color: $primary-tool-tip !important ;
    color: white !important;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: $primary-tool-tip !important ;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: $primary-tool-tip !important ;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $primary-tool-tip !important ;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $primary-tool-tip !important ;
}
