.couponEmptyHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #4b526d;
    text-align: center;
}
.couponEmptyDesc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #a1a5b7;
    text-align: center;
}
