.leave-body-size {
    min-width: 600px;
}

@media (max-width: 576px) {
    .leave-body-size {
        min-width: auto;
    }
}
@media (max-width: 768px) {
    .leave-body-size {
        min-width: auto;
    }
}

.placeholder-content {
    min-height: 70vh;
}

.response-menu {
    position: fixed;
    top: 207px;
    right: -54px;
    transform: rotate(-90deg);
    padding: 8px 12px !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    border-radius: 5px 5px 0px 0px !important;
    background: #00a3ff;
}
