@import '../../../../assets/sass/palette';
@import '../../../../assets/sass/core//components/mixins/svg-icon';

.card-style {
  border-radius: 30px;
  box-shadow: 0px 5px 30px rgba(19, 16, 57, 0.1);
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.title-style {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title-style > p {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
}

.sub-title-style {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
}

.create-store-input-container {
  gap: 4px;
}

.fs-18px {
  font-size: 18px;
}

.fs-24px {
  font-size: 24px;
}

.fs-16px {
  font-size: 16px;
}
.fs-14px {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.vh-50 {
  height: 50vh !important;
}

.signup {
  overflow: hidden;
  height: 100%;
  .input-group-addon {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: min-content;
    padding-right: 15px;
  }
  .no-border {
    border: none !important;
  }
  .password-section {
    border: 1px solid #e4e5e9;
    border-radius: 7px;
  }
  .social-btn1:hover {
    background-color: #f8f8fa;
    outline: 1px solid #c2c4cc;
  }
}

.mb-40px {
  margin-bottom: 40px;
}

.pb-50px {
  padding-bottom: 50px;
}

.mb-30px {
  margin-bottom: 30px;
}

.padding-style {
  display: flex;
  align-items: center;
  padding-left: 200px;
}

.social-btn {
  background-color: $social-button-color !important;
  color: $primary-color-white !important;
  border: 0px !important;
  border-radius: 8px !important;
  flex-direction: row;
}

.spinner-border {
  width: 30px !important;
  height: 30px !important;  
  background-image: url('../../../../assets/icons/loader.svg');
  background-repeat: no-repeat;
  background-size: contain;
  border: none !important;

  &.auth-flow {
    width: 18px !important;
    height: 18px !important;
    margin-left: 10px;
  }
}

.social-btn1 {
  height: 50px;
  width: 156px;
  border-radius: 6px;
  outline: 1px solid #c2c4cc;
}

.social-btn-margin {
  margin: 0px 10px;
}

.mr-10px {
  margin-right: 10px;
}

.social-icon {
  width: 22px;
  height: 22px;
}

.apple-btn {
  background-color: #000000 !important;
  color: $primary-color-white !important;
  border: 0px !important;
  border-radius: 8px !important;
}

.w-33 {
  width: 33.33%;
}

.loggedin-message {
  color: $secondary-grey;
}

.secondary-grey-shade {
  color: $secondary-grey-shade;
}

.gradient {
  // background: linear-gradient(216.79deg, #3b4a85 13.14%, #242c49 95.9%);
  background-image: url('../../../../assets/images/SignupBackground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-white {
  background: $primary-color-white;
}

.display-end {
  display: flex;
  align-items: flex-end;
}

.signup {
  .form-control {
    background: transparent !important;
  }
}

.grey-border {
  border: 1px solid #e4e5e9 !important;
}

.grey-border:hover {
  border: 1px solid #00a3ff !important;
}
.grey-border:focus {
  border: 1px solid #00a3ff !important;
}

.image-container {
  width: 100%;
  height: auto;
  // width: 931.68px;
  // height: 944.96px;
  // object-position: bottom;
  padding-right: 56px;
}

.content-wrapper {
  padding-top: 100px !important ;
  padding-bottom: 100px !important ;
}

.content-container {
  padding: 40px 110px 56px 110px;
  border-radius: 10px;
  // gap: 24px;
}

.button-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  // gap: 32px;
}
.button {
  width: 500px;
  height: 50px;
}
.button-padding {
  padding: 14px 24px !important;
  font-size: 18px !important ;
  font-weight: 500 !important;
  margin-top: 20px;
}

.p-4rem {
  padding: 0.5rem 4rem;
}

.social-auth {
  gap: 16px;
}

.visibility-hidden {
  visibility: hidden;
}

.border-container {
  border: 0.1px solid #ebebeb;
  width: 45%;
  height: 1px;
}

.orText {
  color: #a6b4bb;
  font-weight: 500;
}

.or-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // height: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.or-container > p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.p-6rem {
  padding: 6rem;
}

.align-center {
  display: flex;
  align-items: center;
}

.p-8rem {
  padding: 8rem;
}

.align-end {
  text-align: end;
}

.image-dimension {
  width: 66px !important;
  height: 70px !important;
}

.form-container {
  width: 49%;
  z-index: 200 !important;
}

.image-wrapper {
  width: 51%;
  z-index: 100 !important;
}

.btn-content-wrapper {
  gap: 8px;
  align-items: center;
  span {
    font-size: 18px !important;
  }
}

.field-wrapper {
  gap: 4px;
}
.field-container {
  gap: 24px;
}
.margin-style {
  margin-top: 0 !important;
  margin-bottom: 2rem !important;
}
.container-height {
  min-height: 830px;
}
.container-width {
  min-width: 720px;
}
.padding-top-50px {
  padding-top: 50px !important;
}
.mt-60px {
  margin-top: 60px;
  margin-bottom: 0;
}

.mt-50px {
  margin-top: 50px;
}
.mt-40px {
  margin-top: 40px;
}
.mt-30px {
  margin-top: 30px;
}
.mt-24px {
  margin-top: 24px;
}
.logo-dimensions {
  height: 70px;
  width: 67px;
}
.mt-8px {
  margin-top: 8px;
}
.l-height-17px {
  line-height: 17px;
}
.mt-140px {
  margin-top: 140px;
}

.mt-4px {
  margin-top: 4px;
}
.mt-120px {
  margin-top: 120px;
}
.mt-30px {
  margin-top: 30px;
}

.mt-20px {
  margin-top: 20px;
}

.loader-Center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-container-card {
  padding: 40px 90px 56px 90px;
  border-radius: 10px;
  // grid-gap: 24px;
  // gap: 24px;
}
.button-card {
  width: 100% !important;
}
.hp-50px {
  height: 50px !important;
}

.inner-gap {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.input-gap {
  padding-bottom: 24px;
}

.already-have-an-account {
  margin-top: 30px;
}

.auth-header {
  padding: 24px 0;
}

.forgot-pass-gap {
  padding-top: 5px;
}

.reset-pwd {
  padding: 160px 110px !important;
}

.reset-pwd .auth-header {
  margin-top: 50px !important;
}

@media screen and (max-width: 2560px) {
  .image-dimension {
    width: 130px !important;
    height: 140px !important;
  }

  .title-style > p {
    font-size: 50px;
    font-weight: 600;
    line-height: 65px;
    margin: 0;
  }

  .content-container {
    padding: 80px 110px 80px 110px;
    // gap: 30px;
  }

  .large-image-container {
    width: 100%;
    height: auto;
    padding-right: 56px;
    filter: drop-shadow(-43px 19px 47px #00000070);
  }

  .social-auth {
    width: 100%;
    justify-content: space-between;
  }

  .social-btn1 {
    width: 210px;
  }
  .button {
    width: 100%;
  }
}

@media screen and (max-width: 1920px) {
  .title-style > p {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    margin: 0;
  }

  .image-dimension {
    width: 66px !important;
    height: 70px !important;
  }

  .social-btn1 {
    width: 156px;
  }
  .container-height {
    min-height: auto;
  }
  .content-container {
    padding: 40px 110px 40px 110px;
  }
  .image-container {
    width: 95%;
  }
}

@media screen and (max-width: 1700px) {
  .form-container {
    width: 45%;
  }

  .image-wrapper {
    width: 55%;
    margin-bottom: 40px !important;
  }

  .image-container {
    transform: scaleY(1.1);
  }

  .container-width {
    min-width: 700px;
  }
  .hp-50px {
    height: 40px !important;
  }

  .content-container {
    padding: 70px 110px 70px 110px;
    // gap: 15px;
  }

  .padding-style {
    padding-left: 75px;
  }
  .button {
    width: 100%;
  }
  .social-btn1 {
    width: 143px;
  }
}

@media screen and (max-width: 1440px) {
  .form-container {
    width: 45%;
  }

  .image-wrapper {
    width: 55%;
    margin-bottom: 37px !important;
  }

  .image-container {
    transform: scaleY(1.1);
  }

  .container-width {
    min-width: 600px;
  }

  .hp-50px {
    height: 40px !important;
  }

  .container-height {
    min-height: auto;
  }

  .content-container {
    padding: 25px 70px 25px 70px;
    // gap: 15px;
  }

  .padding-style {
    padding-left: 75px;
  }
  .button {
    width: 100%;
  }
  .social-btn1 {
    width: 143px;
  }
}

@media screen and (max-width: 1440px) and (max-height: 750px) {
  .form-container {
    width: 45%;
  }

  .image-wrapper {
    width: 50%;
    margin-bottom: 35px !important;
  }

  .image-container {
    transform: scaleY(1.1);
  }

  .container-width {
    min-width: 600px;
  }

  .hp-50px {
    height: 40px !important;
  }

  .container-height {
    min-height: auto;
  }

  .content-container {
    padding: 25px 70px 25px 70px;
    // gap: 15px;
  }

  .padding-style {
    padding-left: 75px;
  }
  .button {
    width: 100%;
  }
  .social-btn1 {
    width: 143px;
  }
}

@media screen and (max-width: 1400px) {
  .form-container {
    width: 45%;
  }

  .image-wrapper {
    width: 46%;
    margin-bottom: 28px !important;
  }
  .image-dimension {
    width: 50px !important;
  }
  .title-style {
    gap: 0;
    grid-gap: 0;
  }

  .hp-50px {
    height: 40px !important;
  }

  .inner-gap {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .image-container {
    transform: scaleY(1.1);
  }

  .container-width {
    min-width: 600px;
  }

  .container-height {
    min-height: auto;
  }

  .content-container {
    padding: 20px 70px 20px 70px;
    // gap: 10px;
  }

  .padding-style {
    padding-left: 65px;
  }
  .button {
    width: 100%;
  }
  .social-btn1 {
    width: 143px;
    height: 40px !important;
  }
  .button {
    height: 40px !important;
  }
  .button-container {
    gap: 10px !important;
  }
  .button-padding {
    margin-top: 30px !important;
    padding: 10px 24px !important;
  }
  .image-container {
    padding-right: 0px !important;
    padding-left: 56px !important;
  }
}

@media screen and (max-width: 1300px) {
  .form-container {
    width: 45%;
  }
  .padding-style {
    padding-left: 45px;
  }

  .image-wrapper {
    width: 54%;
    margin-bottom: 33px !important;
  }
  .image-dimension {
    width: 50px !important;
  }
  .title-style {
    gap: 0;
    grid-gap: 0;
  }
  .inner-gap {
    gap: 0px !important ;
  }
  .hp-50px {
    height: 40px !important;
  }

  .image-container {
    transform: scaleY(1.1);
  }

  .container-width {
    min-width: 600px;
  }

  .container-height {
    min-height: auto;
  }

  .content-container {
    padding: 25px 70px 25px 70px;
    // gap: 15px;
  }

  .button {
    width: 100%;
  }
  .social-btn1 {
    width: 143px;
  }
  .image-container {
    padding-right: 0px !important;
    padding-left: 45px !important;
  }
}

@media screen and (max-width: 1024px) {
  .image-wrapper {
    display: none;
  }

  .form-container {
    width: 100%;
  }
  .social-btn1 {
    width: 156px;
  }
  .button {
    width: 500px;
  }
  .padding-style {
    padding: 0 200px;
    justify-content: center;
  }

  .content-container {
    padding: 40px 60px 40px 60px;
    // gap: 10px;
  }
}

@media screen and (max-width: 900px) {
  .social-btn1 {
    width: 150px;
  }

  .button {
    width: 100%;
  }
}

.text-color-exp {
  color: #4a516c;
}
