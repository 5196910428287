@import '../../../assets/sass/palette.scss';
.helper-text-one {
    color: $dnd-text-blue;
}
.helper-text-bottom {
    color: $gray-border-color;
    font-size: 14px;
    line-height: 21px;
}
.ql-font-roboto {
    font-family: 'poppins-semibold';
}
.ql-toolbar {
    border-color: $input-border-color !important;
    border-top-left-radius: 0.475rem !important;
    border-top-right-radius: 0.475rem !important;
}
.ql-container {
    border-bottom-left-radius: 0.475rem !important;
    border-bottom-right-radius: 0.475rem !important;
    font-family: 'poppins-medium' !important;
    border-color: $input-border-color !important;
}

.ql-editor.ql-blank::before {
    color: $gray-border-color !important;
    font-family: 'poppins-medium' !important;
    font-style: normal !important;
}
.ql-editor {
    min-height: 15vh !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
    font-size: 14px;
    line-height: 21px;
    font-family: 'poppins-regular';
    font-weight: 400;
}
.section-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1e2749;    
}
.section-subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #1e2749;
}
.input-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;     
    text-transform: capitalize; 
    font-family: 'poppins-medium';
}