.outerSelectionCircle {
    min-height: 15px;
    max-height: 15px;
    min-width: 15px;
    max-width: 15px;
    border-radius: 50%;
}
.innerSelectionCircle {
    min-height: 8px;
    max-height: 8px;
    min-width: 8px;
    max-width: 8px;
    border-radius: 50%;
}
