@import '../../../assets/sass/palette.scss';

.sortIcon:hover {
    color: $primary-color-white !important;
}

.w-340px {
    width: 340px !important;
}

.rules-engine-product-table {
    .product-name-cell {
        .main-cell {
            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;   
                color: #1e2749 !important;
                font-family: 'poppins-regular';
                font-weight: 600;
                font-size: 14px;
                width: 200px;
                
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
}