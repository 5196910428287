.screenHeader {
    .profileLogo {
        height: 44px;
        width: 44px;
    }
    .orderTypeSH {
        background-color: #e4e6ef80;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .selectedOrderTypeSH {
        background-color: white;
    }
    .order-text {
        color: #4A516C;
    }
}
