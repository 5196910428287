.inventory-history-modal {
  .expand-arrow-icon.active {
    svg {
      background-color: rgb(255 255 255 / 10%);
      filter: invert(44%) sepia(35%) saturate(3228%) hue-rotate(177deg)
      brightness(103%) contrast(110%);
    }
  }
  .close-icon {
    svg {
      rect {
        fill: #4A516C !important;
      }
    }
  }

  .modal-content {
    display: block !important;
  }

  .modal-body {
    padding: 16px 30px 30px !important;

    .table-responsive {
      overflow-y: scroll;
      height: 500px;

      th {
        position: sticky;
        top: 0;
        background: #f8f8fa;
        z-index: 1;
      }
      
      .table-product-image {
        border: 1px solid #ddd;
        min-width: 50px;
      }

      .h-300 {
        min-height: 300px;
      }
    }

    .log-row {
      .default-font {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;        
      }

      .adjustment-date {
        color: #00a3ff;
        width: 200px;
      }

      .product-varinat-info {        
        width: 250px;        

        h6 {
          font-weight: 500 !important;
          color: #1E2749;
        }
      }

      .adjustment-status {
        margin-left: 8px;
        padding: 8px 12px;
        border-radius: 18px;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;

        &.received {
          background: rgba(52, 199, 89, 0.1);
          color: #34C759;
        }

        &.returned {
          background: rgba(243, 65, 108, 0.1);
          color: #F3416C;
        }

        &.recounted {
          background: rgba(0, 163, 255, 0.1);
          color: #00A3FF;
        }

        &.damaged {
          background: rgba(235, 90, 70, 0.1);
          color: #EB5A46;
        }

        &.lost {
          background: rgba(252, 173, 9, 0.1);
          color: #FCAD09;
        }

        &.given {
          background: rgba(7, 153, 146, 0.1);
          color: #079992;
        }

        &.theft {
          background: rgba(75, 147, 229, 0.1);
          color: #4B93E5;
        }
      }
    }

    .filter-menu {
      max-height: 470px;
      top: 40px;

      &-header {
        padding: 20px 20px 16px;
        border-bottom: 1px solid rgba(194, 196, 204, 0.3);
        
        h6 {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: rgba(30, 39, 73, 0.8);        
        }

        .clear-btn {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #00A3FF;
        }
      }

      &-item-header {
        .rotate-icon {
          transform: rotate(180deg);
        }
      }

      &-item-content {
        max-height: 122px;
      }

      &-footer {
        position: relative;
        width: 100%;
        background-color: #fff;
      }
    }
  }
}