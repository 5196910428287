//
// Scroll mixins
//

@mixin scrollbar-color($color, $hover-color: null) {
  // Firefox
  scrollbar-color: $color transparent;
  // scrollbar-color: $color #fff;
  // scrollbar-color: black #fff;

  // Webkit
  &::-webkit-scrollbar-thumb {
    // background-color: $color;
    // background-color: #000000;
    background-color: #eff2f5;
	  border: 4px solid #fff;
    // border-left: none !important;
    border-radius: 20px 20px 30px 30px;    
  }

  ::-webkit-scrollbar-track
  {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    // background-color: #F5F5F5;
    // background-color: #fff;
  }

  ::-webkit-scrollbar
  {
    width: 10px;
    // background-color: #fff;
  }

  // @if ($hover-color != null) {
  //   // Hover state
  //   &:hover {
  //     // Firefox
  //     scrollbar-color: $hover-color transparent;

  //     // Webkit
  //     &::-webkit-scrollbar-thumb {
  //       background-color: $hover-color;
  //     }
  //   }
  // }
}
