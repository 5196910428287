html {
  font-family: 'poppins'; // 1
  text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-poppins-regular;

  // @media screen and (min-width: 1921px) and (max-width: 2150px) {
  //   font-size: 18px !important;
  // }

  // @media screen and (min-width: 2151px) and (max-width: 2560px) {
  //   font-size: 20px !important;
  // }

  // @media screen and (min-width: 2561px) and (max-width: 3050px) {
  //   font-size: 25px !important;
  // }

  // @media screen and (min-width: 3051px) and (max-width: 4575px) {
  //   font-size: 33px !important;
  // }

  // @media screen and (min-width: 4576px) and (max-width: 6100px) {
  //   font-size: 50px !important;
  // }

  // @media screen and (min-width: 6101px) {
  //   font-size: 60px !important;
  // }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
}

// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}
