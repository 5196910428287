.columnContainer {
    width: 100%;
    min-height: 100%;
}

.columnSubContainer {
    width: 100%;
    min-height: 100%;
}
.smooth-dnd-container {
    height: 100% !important;
}
