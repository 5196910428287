.liveshow-settings {
  min-width: 400px;
  background-color: #ffffff;
  padding: 40px 24px;
  min-height: 600px;

  .heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 1%;
    margin-bottom: 44px;
  }
}

.liveshow-details {
  height: 132px;
  background: #fef3f9;
  background-image: url('../../../../assets/icons/PencilSimpleLine.svg');
  background-repeat: no-repeat;
  background-position: right;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 24px;

  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    color: #e8178a;
  }

  &:hover {
    background-color: #fef3f981;
  }
}

.liveshow-goals {
  height: 132px;
  background: #f2fbff;
  border-radius: 10px;
  background-image: url('../../../../assets/icons/RocketLaunch.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 24px;

  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    color: #00a3ff;
  }

  &:hover {
    background-color: #f2fbff93;
  }
}

.nextlive-container {
  margin-top: 40px;

  .heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 1%;
    margin-bottom: 20px;
  }
}

.liveshow-streamer-video-container {  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .liveshow-streamer-video {    
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 40px;
    min-height: 665px;
  
    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    }
  }
}

.video-stream-container {
  flex-direction: column;
  margin-top: 15px;
  align-items: center;
  position: relative;

  video {
    height: 500px;
    max-width: 850px;
  }
  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
  }
  .video-controller {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .preview-video-container {
    width: 100%;
    background-color: #000;
    border-radius: 10px;

    @media screen and (min-width: 1660px) {
      width: 850px;
    }
  }
  .seller-video-container {
    width: 100%;
    background-color: #000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1660px) {
      width: 850px;
    }
  }
}

.nextliveshow-container {
  display: flex;
  align-items: center;
  height: 122px;
  background: #f8f8fa;
  border-radius: 10px;
  padding: 12px;
  margin-top: 20px;

  .image-container-next-show {
    width: 60px;
    margin-right: 20px;
  }

  .desc-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .date-time-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4a516c;
  }
}

.time-remaining {
  padding: 15px 0;
  margin-left: 5px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #8b90a1;
  }
}

.no-video-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  max-width: 850px;
  font-size: 28px;
  font-weight: 500;
  margin: 0 auto;
  word-wrap: break-word;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 10px;

  span {
    padding: 5px;
  }
}

.no-video-float-placeholder {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 850px;
  height: 500px;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
  margin: 0 auto;
  word-wrap: break-word;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 10px;

  span {
    padding: 5px;
  }
}

.no-video-float-feed-placeholder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
}

.countdown-timer-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 48.21rem !important;
  height: 17.85rem;
  border-radius: 15px !important;
  z-index: 20;

  .discardbutton {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 15px;
    padding-right: 15px;

    &.confirm-close-btn {
      position: absolute;
      top: 0px;
    }
  }

  .countdown-header {
    p {
      font-size: 1.42rem;
      font-weight: 500;
      margin: 0;
    }
    margin-top: -15px;
    margin-bottom: 20px;
  }

  .countdown-timer {
    p {
      font-size: 80px;
      line-height: 1;
      color: #00a3ff;
      font-weight: 600;
      margin: 0;
    }
    margin-bottom: 30px;
  }

  .countdown-detail {
    p {
      color: #8b90a1;
      line-height: 1.5rem;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.broadcaster-view {
  flex-direction: column;
}

.background-next-product-image {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-color: #00000066;
}

.preview-video-canvas {
  // width: 300px;
  // margin-bottom: 40px;
  height: 500px;  
}

.controller {
  align-items: center;
  justify-content: center;  
  padding-top: 40px;
}
