.stop-stream-button-wrapper {
  align-items: center;
  margin: 0 calc(var(--ZOOM-MULTIPLIER) * 0.25rem);
  cursor: pointer;
}

.stop-stream-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--ZOOM-MULTIPLIER) * 2.86rem);
  width: calc(var(--ZOOM-MULTIPLIER) * 2.86rem);
  border-radius: 50%;
  background-color: red;
  margin: 0 calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
  color: white;
  font-weight: 600;
  font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
  z-index: 999;
}
