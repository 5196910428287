.app-customizer-page {
  &-container {
    max-width: 1058px;
    margin: 0 auto;
  }

  .app-theme-status-box {
    padding: 9px 12px;
    background-color: #fff;
    border-radius: 8px;
    font-size: 14px;

    .app-status {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      border-radius: 50%;

      &.published {
        background-color: #50cd89;
      }

      &.draft {
        background-color: #f1416c;
      }

      &.in-review {
        background-color: #ffc700;
      }
    }
  }

  &-content {
    padding-top: 40px;

    .intro-screens {
      padding: 40px;
      min-height: 560px;

      h3 {        
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;   
        margin-bottom: 8px;     
      }

      .section-description {                
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #66728b;
      }

      &-section {
        margin-top: 40px;

        h5 {          
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;  
          margin-bottom: 16px;        
        }

        .logo-img {
          width: 120px;
          height: 120px;
          object-fit: contain;
        }

        .logo-btn {
          position: relative;
          border: none;

          &:active {
            border: 1px solid #00A3FF !important;
            border-radius: 6px !important;

            img {
              border-radius: 6px !important;
              width: 118px;
              height: 118px;
            }
          }

          .logo-remove-icon {
            width: 20px;
            height: 20px;
            position: absolute;
            top: -9px;
            right: -9px;
            background-color: white;        
            border-radius: 50%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 5px 30px rgb(19 16 57 / 15%);
            z-index: 100;
          }
        }
      }
    }

    .preview-mode {
      padding-top: 16px;
      width: 240px;
      height: 500px;
      margin: 0 auto;

      svg {
        width: 100%;
        height: 100%;        
      }

      p {                
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        color: rgba($color: #1e2749, $alpha: 0.8);                
      }

      .logo-img {
        position: absolute;
        width: 120px;
        height: 120px;
      }
    }

    .preview-mode-page {
      width: 240px;
      height: 500px;
      margin: 0 auto;
      background-image: url('../../../../assets/images/iPhoneMock.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;  
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 35px;

      .screen-item {
        border-radius: 35px;
        svg {
          width: 100%;
          height: 100%;          
          border-radius: 35px;       
        }
      }      

      // Slider styles
      .slider-wrapper {
        width: 215px;
        margin: 2px auto 0;    
        border-radius: 30px;
        height: 483px;
        background-color: #000;

        .slide {          
          transition-duration: 250ms !important;
        }

        .selected {
          background-color: transparent !important;
          z-index: 1000 !important;
        }                
      }

      .carousel-slider {
        overflow: visible !important;
      }

      .carousel-arrow-icon {
        position: absolute !important;
        top: 50%;
        border: none !important;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background-color: transparent;

        svg {
          width: 100%;
        }

        &:hover {
          background-color: rgba(0, 163, 255, 0.1);
        }

        &.prev-icon {
          left: -55px !important;
        }

        &.next-icon {
          right: -55px !important;
        }
      }

      .preview-logo {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }

      .iphone-layout {
        margin-left: 1px;
      }

      .signin-preview {
        img {
          width: 70px;
          height: 70px;
          top: 44px;
        }
      }
    }    
  }

  .logo-uploader {
    background: #e6f6ff;
    border: 1px dashed #00A3FF;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 120px;
    min-height: 120px;
    margin: 16px 0 0;

    &:hover {
        background: #ccedff;
    }

    &.active,
    &:active {
        border: 1px solid #00A3FF;
    }

    input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    p {
      margin: 0;      
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0em;
    }
  }

  .file-extension {
    max-width: 120px;

    p {      
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;  
      color: rgba($color: #1e2749, $alpha: 0.8);   
      margin: 12px 0 0;    
    }
  }

  .app-customizer-layout {
    &.disabled-layout {
      pointer-events: none;
    }

    @media screen and (min-width: 990px) {
      column-gap: 40px;      
      
      .left-pane {
        flex: 1;
      }
    }

    @media screen and (min-width: 990px) {
      .right-pane {                
        width: 327px;
      }                    
    }
  }
}
