@import '../../../assets/sass/palette.scss';

.openFolderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
    min-width: 24px;
    min-height: 24px;
}

.openFolderWrapperIsOpen {
    transform: rotate(-90deg);
}
.selectedBackgroudOrg {
    background-color: rgba(0, 163, 255, 0.08);
}

.collections-section {
    .dropdown-height {
        height: 370px !important;
    }

    .collections-tree {
        border: 1px solid #E9E9EB;
        border-radius: 7px;
        padding: 20px 5px 20px 20px;
        // max-height: 345px;
        height: 345px; 

        .expandAll {
            margin: 0 0px 10px 0px !important;

            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .tree-item {
            cursor: pointer;
            margin-bottom: 10px;

            &:hover {
                background: rgba(0, 163, 255, 0.08);
                border-radius: 5px;  
                
                .form-check.form-check-solid .form-check-input:not(:checked) {
                    background-color: #fff;
                }
            }

            .node-text {
                font-weight: 500;                
                font-size: 14px;
                line-height: 20px;
                color: #4A516C;
                padding-left: 10px;                
                text-overflow: ellipsis;
                overflow: hidden;        
                white-space: nowrap;

                @media screen and (max-width: 1660px) {
                    max-width: 130px;
                }
            }
        }

        .auto-item {
            // pointer-events: none;
            opacity: 0.3;
        }
    }
}
