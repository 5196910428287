@import '../../../assets/sass/palette.scss';

.create-live-show-detail-step-div {
    .content {
        flex-direction: row;
    }
    .product-list-view {
        width: 70%;
        border-right: 1px solid $primary-border-color;
        // .input-fields {
        //     border-bottom: 1px solid $primary-border-color;
        // }
    }
    .products-preview-div {
        width: 450px;
        justify-content: flex-end;
        .thumbnail-title {
            align-items: center;
            justify-content: center;
            display: flex;
            font-weight: 500;
            color: rgba(30, 39, 73, 0.8) !important;
            font-size: 16px !important;
        }
    }
    .product-title {
        width: 34%;
    }
    .product-title-data {        
        font-size: 14px;
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .product-title-hover {
        &:hover {
            text-decoration: underline;
        }
    }
}
