@import '../../../assets/sass/palette.scss';


.modal-content {
    border-radius: 20px !important;

    &.default-modal {
        width: 620px;
    }
    &.modal-large-content.delete-and-move-modal {
        padding: 40px;
        width: 818px !important;
        .modal-footer {
            display: flex;
            justify-content: space-between;
            padding: 20px 30px 0 30px !important;
        }

        .modal-title {
            margin-top: 12px;
            font-size: 30px;
            font-weight: 600;
            line-height: 40px;
        }
        .ms-0 {
            margin-left: 0 !important;
        }

        .move-modal-select-label {
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            text-align: left;
            color: #1e2749;
            margin-top: 20px;
            margin-bottom: 10px;
            opacity: 0.8;
        }

        .title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .logo-container {
            width: 70px;
            height: 50px;
        }
    }
}

.modal-header {
    padding: 20px 30px !important;

    .modal-title {    
        font-size: 20px;
        font-weight: 500;
        line-height: 24px !important;
        font-family: 'poppins-medium';     
        color: #1e2749;   
    }

    svg.close-icon {
        width: 24px !important;
        height: 24px !important;
    }
}

.modal-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.modal-footer {
    &::after, &::before {
        display: none;
    }
    margin-top: 0 !important;
    padding: 12px 30px !important;

    button:last-child {        
        margin-left: 16px !important;
    }

    button {
        margin-right: 0 !important;        
    }
}

.error-modal-title {    
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    max-width: 280px;
    margin: 0 auto;
    text-align: center;
}

.modal-large-content {
    width: 42.85rem !important;

    &.combined-order-confirm-modal {
        border-radius: 10px !important;
    }
}
.mediaGreyText {
    color: $gray-border-color !important;
}

.border-none {
    border: none !important;
}

.variantAddImagesGridImageContainer {
    width: 100%;
    max-height: 10rem;
    max-width: 10rem;
    min-height: 10rem;
    min-width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 10px;
    border: 1px dashed #00a3ff;

    &:active {
        border: 1px solid #00a3ff;
    }
}
.variantAddUploadImagesText {
    margin-top: 10px;
    color: #1e2749;
    font-size: 12px;
}
.variantAddImagesGridImage {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $primary-blue-light-background;
    transition-duration: 300ms;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    text-align: center;

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
.variantAddImagesGridImage:hover {
    background-color: $primary-color-white;
    background-color: #ccedff;
}

.variantImagesGridImageContainer {
    max-height: 10rem;
    max-width: 10rem;
    min-height: 10rem;
    min-width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .loading-card {        
        height: 100%;
        background-color: #e9e9eb;
        border-radius: 10px;        
    }
}
.variantImagesCheckbox {
    position: absolute;
    left: 8px;
    top: 8px;
    height: 18px;
    width: 18px;
    object-fit: cover;
}
.variantImagesGridImageContainerTwoSelected {
    width: 100%;
    height: 100%;
    padding: 1.5px;
    border: 1.5px;
    border-style: solid;
    border-color: $primary-blue-background;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}
.variantImagesGridImageContainerTwo:hover {
    padding: 1.5px;
    border: 1.5px;
    border-style: solid;
    border-color: $primary-blue-background;
    border-radius: 10px;
}
.variantImagesGridImageContainerTwo {
    padding: 1.5px;
    border: 1.5px;
    border-style: solid;
    border-color: $primary-color-white;
    cursor: pointer;
    height: 100%;
    width: 100%;
}
.variantImagesGridImage {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}
.variantImagesGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    grid-gap: 7px;
}
.leftPaddingDelMoveModal {
    margin-left: 15px;
}

.cancel-btn {
    background: $primary-error-button-background !important;
    color: $primary-red !important;
}

.top-10 {
    top: 10px !important;
}

.end-10 {
    right: 10px !important;
}

.error-content {
    margin-left: 30px;
    ul {
        list-style-type: disc !important;
    }
    // ADDED FOR TITILE IN TOOLTIP
}
.danger-title {
    color: $primary-error-button !important;
}

.selected-button {
    background-color: $image-bg-color !important;
}
.label-text-color {
    color: $gray-border-color !important;
}

.w-740px {
    width: 740px !important;
}

.gray-value-color {
    color: $light-gray-text !important;
}

.loader {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 10rem;
    background: conic-gradient(from 180deg at 50% 50%, #00a3ff 0deg, rgba(0, 163, 255, 0) 360deg);
    box-shadow: 0px 0px 100px -50px black;
    animation: animate 1s linear infinite;
}

.loader::before {
    position: absolute;
    content: '';
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    border-radius: 10rem;
}
@keyframes animate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.edit-btn-text-color {
    color: $primary-blue-background;
}
.delete-confirm-modal {
    .main-icon {
        margin-bottom: 30px;
    }

    .modal-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: #F1416C;
        max-width: 414px;
        margin: 0 auto 14px;
    }

    .modal-message {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;        
        color: rgba(30, 39, 73, 0.8);
        max-width: 428px;
        margin: 0 auto;
    }
}
.image-crop-modal {
    width: 360px !important;

    .cropper-dashed.dashed-h {
        border: none;
    }
    .cropper-dashed.dashed-v {
        border: none;
    }
    .cropper-point {
        background-color: #111629;

        &.point-n,
        &.point-e,
        &.point-w,
        &.point-s {
            display: none;
        }
    }
    .cropper-point.point-se {
        width: 5px;
        height: 5px;
    }
    .cropper-view-box {
        outline: none;
        border: 1px dashed #ddd;
    }
    .cropper-modal {
        background-color: #000;
        opacity: 0.2;
    }
    .cropper-center {
        display: none;
    }
    .cropper-line {
        background-color: transparent;
    }
    .cropper-face {
        background-color: transparent;
    }
}

.change-account-info-modal {
    width: 620px !important;
    border-radius: 20px !important;

    .sent-success {        
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;     
        color: #61bd4f;   
    }

    p {                
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;        
    }
}

.printer-btn {
    &:hover {
        .svg-icon svg:not(.permanent):not(g) {        
            path {
                stroke: #fff;
            }
        }
    }
}

.adding-product-modal {
    max-width: 1068px;
    margin: 0 auto;
}

.product-label-print-modal {
    &.default-view {
        width: 716px !important;
        margin: 0 auto;
    }   
    
    &-body {
        height: 685px;
        overflow-y: scroll;

        &.expanded {
            height: calc(100vh - 130px);
        }

        &-container {
            width: 940px;
            margin: 0 auto;
        }
    }
}

.label-template-preview {
    &-dimension {
        border: 1px solid #1e2749;
        border-radius: 4px;
    }
    &-description {
        h5 {
            color: #1e2749;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 4px;
        }

        p {
            color: #1e2749;
            font-size: 12px;            
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 4px;
        }
    }
}

.tiktok-response-modal {
    width: 600px !important;

    .product-price {
        color: #a1a5b7;
    }

    .error-recommendation-content {
        max-height: 416px;
        overflow-y: scroll;
        padding-right: 16px;
    }
}

.tiktok-response-item {
    h5 {           
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #1e2749;
        text-transform: capitalize;

        &.error-title {
            color: #ee0b0b;
        }
    }

    ul {
        padding-left: 10px;
        list-style: inside;
        color: #1e2749;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;

        li {            
            &::first-letter {
                text-transform: capitalize;
            }

            &::marker {
                margin-right: 10px !important;
            }
        }
    }
}

.push-tiktok-modal {
    width: 600px !important;

    .tiktok-row {
        min-width: 320px;
    }

    .status-dot {
        width: 8px;
        height: 8px;
        border-radius: 8px;

        &.active {
            background-color: #50cd89;
        }

        &.inactive {
            background-color: #f1416c;
        }
    }
}

.cancelOrder {
    label {
        color: #1e2749;        
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        font-family: 'poppins-medium';
    }
}
