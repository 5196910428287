@import '../../../assets/sass/palette.scss';
@import '../../../assets/sass/core//components/mixins/svg-icon';
.couponList {
    .order-status-box {
        border-radius: 18px;
        font-size: 12px;
        line-height: 18px;
        width: fit-content;
        padding: 8px 15px !important;
        transition-duration: 300ms;
    }
    .expiredBox {
        background: rgba(241, 65, 108, 0.1);
        color: rgb(241, 65, 108);
        
        &.cursor-pointer {
            &:hover {
                background-color: rgb(241, 65, 108);
                color: white;
            }
        }
    }
    .defaultColorBox {
        background: rgba(0, 163, 255, 0.1);
        color: rgb(0, 163, 255);
        
        &.cursor-pointer {
            &:hover {
                background-color: rgba(0, 163, 255, 1);
                color: white;
            }
        }
    }
}
