.liveshow-section-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
  color: #1e2749;
}

.liveshow-section-subtitle {
  font-weight: 600;
  font-size: 16px;  
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0;
  color: #1e2749;
}

.current-product-container {  
  background-color: #f8f8fa;
  border-radius: 10px;
  height: 326px;
  // margin: calc(var(--ZOOM-MULTIPLIER) * 1.14rem) 0 calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
  // height: calc(var(--ZOOM-MULTIPLIER) * 24.29rem);
  // padding: calc(var(--ZOOM-MULTIPLIER) * 0.5rem) calc(var(--ZOOM-MULTIPLIER) * 1.42rem) calc(var(--ZOOM-MULTIPLIER) * 1rem);  

  .current-product-header {
    span {
      // font-size: calc(var(--ZOOM-MULTIPLIER) * 0.8rem);      
      font-size: 11px;      
      line-height: 17px;
      letter-spacing: 0px;      
      font-weight: 600;
      color: #f1416c;
    }

    .arrow-icon {
      height: calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
      width: calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
      border-radius: 50%;
      transition: box-shadow 0.5s ease-out;

      &:hover {
        box-shadow: 0px 0px 10px rgba(118, 123, 142, 0.141);
        background-color: #fff;
      }

      img {
        width: 0.43rem;
      }
    }
  }

  .current-product-content {
    gap: calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
    .current-product-image {
      img {
        border-radius: calc(var(--ZOOM-MULTIPLIER) * 0.35rem);
        max-width: calc(var(--ZOOM-MULTIPLIER) * 11.1rem);
        height: calc(var(--ZOOM-MULTIPLIER) * 15rem);
      }

      .current-product-id {
        max-width: calc(var(--ZOOM-MULTIPLIER) * 11.1rem);
        background-color: #ffffff;
        font-weight: 600;
        font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);;
        line-height: calc(var(--ZOOM-MULTIPLIER) * 1.21rem);
        color: #00a3ff;
        padding: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
        border-radius: 0 0 5px 5px;
        box-shadow: 0px 0px 10px rgba(118, 123, 142, 0.141);
        margin-top: calc(var(--ZOOM-MULTIPLIER) * (-2.14rem));
      }
    }

    .current-product-name {
      // font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
      // line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);      
      // margin-top: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
      // margin-bottom: calc(var(--ZOOM-MULTIPLIER) * 0.1rem);
      // width: calc(var(--ZOOM-MULTIPLIER) * 11.1rem);
      font-weight: 500;
      max-width: 166px;      
      font-size: 14px;      
      line-height: 21px;
      letter-spacing: 0px;      

      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .current-product-price {
      font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
      line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
      font-weight: 400;
    }

    .current-product-variants-table {
      flex: 60%;

      &-header {
        border-bottom: 1px solid #e9e9eb;
        padding-bottom: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);  
      }

      &-title {                        
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0px;        
        // font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
        // line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
      }

      &-content {
        height: calc(var(--ZOOM-MULTIPLIER) * 16.43rem);
        overflow-y: scroll;
      }

      &-cell {
        margin: calc(var(--ZOOM-MULTIPLIER) * 0.5rem) 0;

        span {
          font-weight: 400;
          font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
          line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
        }
      }
    }

    .search-icon {
      width: 1.28rem;
    }
  }
}

.current-product-description {
  padding: calc(var(--ZOOM-MULTIPLIER) * 1.43rem);
  background-color: #f8f8fa;
  border-radius: 10px;
  margin-bottom: calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
  height: calc(var(--ZOOM-MULTIPLIER) * 24.29rem);

  @media screen and (max-width: 991px) {
    height: calc(var(--ZOOM-MULTIPLIER) * 25rem);
  }

  &-header {
    width: 50%;

    .current-product-tab-name {
      font-weight: 600;
      font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
      line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
      color: #66728b;
      padding: calc(var(--ZOOM-MULTIPLIER) * 0.3rem) 0;
      border-bottom: 2px solid transparent;
    }

    .active {
      color: #00a3ff;
      border-bottom: 2px solid #00a3ff;
    }

    .go-back-icon {
      width: 1rem;
    }
  }

  &-body {    
    margin-top: calc(var(--ZOOM-MULTIPLIER) * 1.43rem);
    overflow-y: scroll;

    .notes-container {
      margin: calc(var(--ZOOM-MULTIPLIER) * 0.5rem) 0;

      .title-name-date {
        .circle-image-container {
          margin-right: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
        }

        .notes-username {
          span {            
            font-size: calc(var(--ZOOM-MULTIPLIER) * 1.14rem);
            font-weight: 500;
            line-height: calc(var(--ZOOM-MULTIPLIER) * 1.36rem);
          }
        }

        .note-updated {
          span {
            font-size: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
            font-weight: 400;
            line-height: calc(var(--ZOOM-MULTIPLIER) * 1rem);
            color: #a1a5b7;
          }
        }
      }

      .notes-details {
        margin-top: calc(var(--ZOOM-MULTIPLIER) * 0.25rem);;
        span {
          font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
          font-weight: 400;
          line-height: calc(var(--ZOOM-MULTIPLIER) * 1.36rem);
          color: #1e2749;
        }
      }
    }
  }
}

.current-product-container-no-product {  
  background-color: #f8f8fa;
  border-radius: 10px;    
  height: 326px;

  span {
    font-weight: 600;    
    color: #4b526d;
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .no-select-text-product {
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;    
    color: #a1a5b7;
  }

  img {
    width: 6rem;
  }
}
