//
// Main
//

// Body
body {
    background-color: $page-bg;
}
input:focus {
    outline: none;
}
// Font color from Content background color
.text-page-bg {
    color: $page-bg;
}

//Font family

.poppins-black {
    font-family: 'poppins-black';
}

.poppins-bold {
    font-family: 'poppins-bold';
}

.poppins-semibold {
    font-family: 'poppins-semibold';
}

.poppins-extrabold {
    font-family: 'poppins-extrabold';
}

.poppins-light {
    font-family: 'poppins-light';
}

.poppins-medium {
    font-family: 'poppins-medium';
}

.poppins-regular {
    font-family: 'poppins-regular';
}

//Font Colors

.dark-color {
    color: #1e2749 !important;
}

.h-fit-content {
    height: fit-content;
}

.w-fit-content {
    width: fit-content !important;
}

.object-fit-scale-down {
    object-fit: scale-down;
}
.object-fit-contain {
    object-fit: contain;
}
.objectFit {
    object-fit: cover;
}

.f-14px {
    font-size: 14px !important;
}

.f-12px {
    font-size: 12px !important;
}

.table-top-border {
    border-top: 1px solid #e9e9eb !important;
}
.table-right-border {
    border-right: 1px solid #e9e9eb !important;
}

.table-bottom-border {
    border-bottom: 1px solid #e9e9eb !important;
}

.opacity-80 {
    opacity: 0.8 !important;
}

.text-muted {
    color: #66728b !important;
}

.bg-muted {
    background: #66728b !important;
}

.no-top-border {
    border-top: none !important;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.details-box {
    box-shadow: 0px 5px 30px -10px rgb(19 16 57 / 10%);
    border-radius: 10px;
}

.top-shadow {
    box-shadow: 0px -15px 30px -15px rgb(19 16 57 / 10%);
}

.badge-0 {
    background: rgba(0, 163, 255, 0.1);
    border-radius: 8px;
    color: #00a3ff;
    @include svg-icon-color(#00a3ff);
}

.cross-color-class {
    @include svg-icon-color(var(--textColorCode));
}

.badge-1 {
    background: #ffe5ee;
    border-radius: 8px;
    color: #ff83ae;
    @include svg-icon-color(#ff83ae);
}

.badge-2 {
    background: rgba(97, 189, 79, 0.1);
    border-radius: 8px;
    color: rgb(97, 189, 79);
    @include svg-icon-color(rgb(97, 189, 79));
}

.badge-3 {
    background: rgba(97, 189, 79, 0.1);
    border-radius: 8px;
    color: rgba(7, 153, 146);
    @include svg-icon-color(rgba(7, 153, 146));
}

.badge-4 {
    background: rgba(195, 119, 224, 0.1);
    border-radius: 8px;
    color: rgba(195, 119, 224, 1);
    @include svg-icon-color(rgba(195, 119, 224, 1));
}

.badge-5 {
    background: rgba(255, 159, 26, 0.1);
    border-radius: 8px;
    color: rgba(255, 159, 26, 1);
    @include svg-icon-color(rgba(255, 159, 26, 1));
}

.badge-6 {
    background: rgba(52, 69, 99, 0.1);
    border-radius: 8px;
    color: rgba(52, 69, 99, 1);
    @include svg-icon-color(rgba(52, 69, 99, 1));
}

.badge-fulfillment {
    background-color: rgba(7, 153, 146, 0.1) !important;
    color: #079992 !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.m-auto-0 {
    margin: auto 0px;
}

/* Common style for Input */
input {
    font-family: 'poppins-regular';
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #1e2749;

    &::placeholder {
        color: #a1a5b7;
    }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
    // Containers
    .container,
    .container-xxl,
    .container-fluid {
        padding: 0 get($content-spacing, desktop);
    }

    // Wrapper
    .wrapper {
        transition: padding-left get($aside-config, transition-speed) ease, margin-right get($aside-config, transition-speed) ease;

        // Aside default enabled and aside fixed modes
        .aside-enabled.aside-fixed & {
            transition: padding-left get($aside-config, transition-speed) ease;
            padding-left: get($aside-config, width);
        }

        // Aside default enabled, aside fixed and aside minimize modes
        .aside-enabled.aside-fixed[data-kt-aside-minimize='on'] & {
            transition: padding-left get($aside-config, transition-speed) ease;
            padding-left: get($aside-config, minimized-width);
        }

        // Fixed header mode
        .header-fixed & {
            padding-top: get($header-config, fixed, height, desktop);
        }

        // Fixed header & fixed toolbar modes
        .header-fixed.toolbar-fixed & {
            padding-top: calc(#{get($header-config, fixed, height, desktop)} + var(--kt-toolbar-height));
        }
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    // Containers
    .container,
    .container-xxl,
    .container-fluid {
        max-width: none;
        padding: 0 get($content-spacing, tablet-and-mobile);
    }

    // Wrapper
    .wrapper {
        // Fixed header mode
        .header-tablet-and-mobile-fixed & {
            padding-top: get($header-config, fixed, height, tablet-and-mobile);
        }

        // Fixed header & fixed toolbar modes
        .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
            padding-top: calc(
                #{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile)
            );
        }
    }
}
