@import '../../../assets/sass/palette.scss';

.thumbnail-div {

    .thumbnail-img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
    .thumbnailImg {
        width: 210px !important;
        height: 100% !important;
        border-radius: 15px;
        object-fit: cover;
    }

    .thumbnail-icon {
        position: relative;
        display: block;
        width: 180px;
        height: 180px;
        margin: 0 auto;
        border: 1px solid #e6f6ff;
        border-radius: 10px;        
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0.0624822) 68.23%, rgba(0, 0, 0, 0.7) 100%);
        background-color: #e9e9eb;

        &:active {
            border: 1px solid #00A3FF;
        }

        &.liveshow {
            height: 378px;
            width: 260px;

            &:hover {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.0499858) 68.23%, rgba(0, 0, 0, 0.56) 100%);
            }
        }

        &.clicked {
            border: 1px solid #00a3ff;
            padding: 1px;
        }

        &:not(.liveshow) {
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .img-gradient:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 15px;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.0624822) 0%, rgba(0, 0, 0, 0.7) 100%);
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0%, rgba(0, 0, 0, 0.7)),
            color-stop(100%, rgba(0, 0, 0, 0.0624822))
        );
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0624822) 0%, rgba(0, 0, 0, 0.7) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0.0624822) 0%, rgba(0, 0, 0, 0.7) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.0624822) 0%, rgba(0, 0, 0, 0.7) 100%);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.0624822) 68.23%, rgba(0, 0, 0, 0.7) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
    }
    .img-gradient img {
        display: block;
    }
    .thumbnail-img-title {
        position: absolute;
        left: 20px;
        bottom: 26px;                
        color: $primary-color-white;
        z-index: 33;
    }
    .thumbanil-remove-icon {
        background-color: white;        
        border-radius: 30px;
        position: absolute;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -14px;
        right: -14px;
        box-shadow: 0px 5px 30px rgb(19 16 57 / 15%);
        z-index: 100;
    }

    .thumbnail-uploader-container {
        background: #e6f6ff;
        border: 1px dashed #00A3FF;
        border-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 180px;
        min-height: 180px;
        margin: 0 auto;        

        &.liveshow {
            min-height: 378px;
            width: 260px;
        }

        &:hover {
            background: #ccedff;
        }

        &.active,
        &:active {
            border: 1px solid #00A3FF;
        }

        input {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;        
        width: 180px;
        min-height: 180px;
        margin: 0 auto;
        background-color: #e9eaed;
        border-radius: 10px;

        &.liveshow {
            height: 378px;
            width: 260px;
        }
    }

    .gradient-section {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.0624822) 68.23%, rgba(0, 0, 0, 0.7) 100%);
        position: absolute;
        width: 100%;
        left: 0;
        height: 100%;
        bottom: 0;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.0499858) 68.23%, rgba(0, 0, 0, 0.56) 100%)
        }
    }
}
