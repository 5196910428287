.order-settings-page {
  &-container {
    max-width: 778px;
    margin: 0 auto;

    h3 {      
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;     
      color: #1e2749; 
    }

    .svg-icon {
      line-height: 1.2;
    }

    .form-check {
      min-height: unset;
    }
  }
}