@import '../../../assets/sass/palette.scss';
.dropdown-custom-width {
    min-width: 100%;
}
.divider-product-status {
    background-color: $primary-border-color;
}

.tagsScroll > div:first-of-type {
    width: 100%;
    display: flex !important;
    flex-wrap: nowrap !important;
}
.search-input {
    padding-left: 40px !important;
    padding-right: 30px !important;

    .search-icon {
        left: 10px;
    }
}
.vendor-dropdown {
    position: relative;
    margin-top: 24px;

    .select-placeholder {
        color: #A1A5B7;
    }
}
.product-types-section {
    .select-placeholder {
        color: #A1A5B7;
    }

    .close-icon {
        right: 10px;
    }
}
.tags-list-container {
    border: 1px solid #e4e6ef;
}


