.live-show-calendar-div {
    .create-live-show-btn {
        background-color: #00a3ff1a;
    }

    .valueBoxes {
        padding: 7px 14px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        svg {
            height: 16px;
            width: 16px;
            font-size: 16px;
        }
    }
    .valueBoxesLeft {
        border: 1px solid;
        border-radius: 5px 0px 0px 5px;
    }
    .valueBoxesRight {
        border: 1px solid;
        border-radius: 0px 5px 5px 0px;
    }
    .valueBoxSelected {
        border-color: #00a3ff;
        background-color: #00a3ff;
        color: white;
        svg {
            path {
                stroke: white;
            }
        }
    }
    .valueBoxNotSelected {
        border-color: #e4e6ef;
        background-color: white;
        color: #8B90A1;
        svg {
            path {
                stroke: #8B90A1;
            ;
            }
        }
    }
}
