@import '../../../assets/sass/palette.scss';

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

.simple3 .simple-inner {
    height: auto;
}

.simple3 ol li {
    border-bottom: 0 none;
    position: relative;
    transition: all ease 0.3s;
    float: left;
    box-sizing: border-box;
    text-align: center;
    margin: 0 12px 13px 0;
}

.simple3 ol::after {
  content: '.';
  display: block;
  visibility: hidden;
  height: 0;
  line-height: 0;
  font-size: 0;
  clear: both;
}

.simple3 ol li:first-child {
  border-left: 0 none;
  width: 240px !important;
  height: 399px !important;
  position: relative !important;
}

.simple3 {
  .recording-item {
    width: 120px !important;
    height: 193px !important;

    .drags-video {
      top: 8px;
      left: 8px;
      width: 24px;
      height: 24px;
    }

    .actions-menu {
      top: 8px;
      right: 8px;
      width: 24px;
      height: 24px;
      outline: none;
      border: none;
      background-color: transparent;
      border-radius: 50%;

      svg {
        [fill]:not(.permanent):not(g) {
          fill: #fff;
        }
      }

      &.active-btn {
        background-color: #e6f6ff;

        svg {
          [fill]:not(.permanent):not(g) {
            fill: #00a3ff;
          }
        }
      }
    }

    .primary-video-icon {
      bottom: 8px;
      right: 8px;
    }

    .recording-date {
      bottom: 8px;
      left: 8px;

      span {        
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;     
        color: #fff;   
      }
    }
  }
}


.product-recording-image {
  border-radius: 8px;
  object-fit: cover;
}

.recording-action-options {
  padding: 4px !important;
  width: 230px !important;

  .option-item {
    padding: 12px 14px !important;

    &:hover {
      color: #00a3ff !important;
    }
  }
}

.video-preview-modal-content {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  .carousel-root {
    width: 337.5px !important;

    .carousel.carousel-slider {
      display: flex;
      align-items: center;
    }
    
    .slider-wrapper {
      border-radius: 20px;
    }

    // Slide styles
    .carousel {
      .slide {
        max-height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;

        video {
          height: 100%;
        }

        &.selected {
          background-color: transparent !important;
        }

        img {
            border-radius: 20px;
            height: 100%;
            object-fit: cover;
        }
      }
    }

    // Thumbnails styles
    .carousel {
      .thumbs-wrapper {
        margin: 60px 0 0 0;
        overflow: visible;

        .control-arrow {
          display: none;
        }
      }

      .thumb {
        border: none;
        width: 80px;
        height: 80px;
        margin-right: 24px;
        opacity: 0.5;

        &.selected {
          background-color: transparent !important;
          width: 110px !important;
          height: 110px;
          opacity: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }

    // Next and Prev Icons
    .carousel-arrow-icon {
      background-color: transparent;
      border: none;

      &.prev-icon {
        position: fixed !important;
        left: 80px !important;
      }
      &.next-icon {
        position: fixed !important;
        right: 80px !important;
      }

      svg {
        &:hover {
          path {
            stroke: #00A3FF;
          }
        }
      }
    }
  }
}

.recording-overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0));
  padding: 32px 16px;
  color: #fff;  

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 5px;
  }
}

.primary-video-placeholder {
  border: 2px dashed #00a3ff !important;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e6f6ff;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #1e2749;
  }
}

.empty-recordings {
  height: 400px;

  h3 {    
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    max-width: 389px;
    margin: 0 auto;
    color: rgba(194, 196, 204, 1);
  }
}