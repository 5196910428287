@import '../../../assets/sass/palette.scss';
$dark-grey: #4b526d;

.scrollProductNotes {
    height: 425px;
    width: 300px;
    overflow: hidden;
    overflow: scroll;
    [data-kt-aside-minimize='on'] & {
        width: 353px;
    }
}
.hrLine {
    position: absolute;
    right: 2.08%;
    bottom: 87.5%;
    border: 1px solid $primary-border-color;
    width: 351px;
    [data-kt-aside-minimize='on'] & {
        width: 413px;
    }
}
.productNotesBox {
    background-color: $primary-color-white !important;
    border-radius: 10px !important;
    [data-kt-aside-minimize='on'] & {
        background-color: $primary-color-white !important;
        border-radius: 10px !important;
        padding: 10px 0px !important;
    }
}
.profileUserCls {
    object-fit: cover;
}
.float-right {
    float: right;
}
.newNotesInput {
    outline: none;

    &:hover, 
    &:focus {
        outline: 1px solid #00a3ff;
    }
}
.newNotesInput::placeholder {
    color: $gray-border-color;
}

.detailsContainer {
    padding-left: 0px !important;
}
.endBtn {
    border: 1px solid $primary-red !important;
    color: $primary-red !important;
}
.trashBtn {
    border: 1px solid $primary-secondary !important;
    color: $primary-grey !important;
    height: 30px !important;
    width: 70px !important;
    padding: 4px !important;
    font-size: 12px;
}
.editProductNote {
    border: 1px solid $primary-secondary !important;
    color: $primary-grey !important;
    height: 30px !important;
    width: 54px !important;
    padding: 4px !important;
    font-size: 12px;
    margin: 0px 6px 0px 17px !important;
}
.editsIcon {
    padding-right: 4px;
    height: 10px;
    margin-bottom: 2px;
}
.editTextInput {
    width: 95% !important;
    min-height: 160px;
    color: gray;
    border: 1px solid $primary-blue-background !important;
    border-radius: 10px;
    padding: 10px 10px 113px 10px;
    margin-left: 1px;
    [data-kt-aside-minimize='on'] & {
        width: 280px;
    }
}
.editTextInput:active {
    border: 1px solid $primary-blue-background !important;
    border-radius: 10px !important;
}
.editTextInput:focus {
    outline: 1px solid $primary-blue-background !important;
    border-radius: 10px !important;
}

.btnText {
    color: $primary-grey !important;
}
.endBtn:hover {
    background: $primary-error-button-background !important;
    color: $primary-error-button !important;
}
.otherDetail {
    font-weight: 500;
}
.category {
    font-family: 'poppins-regular';
    line-height: 2;
}
.categoryTypes {
    font-weight: normal;
}
.subCategoryTypes {
    font-weight: normal !important;
    background: $primary-color-white;
    border-radius: 5px;
    padding: 4px 10px;
    margin: 0px 4px 0px 6px;
}
.links {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $primary-blue-background;
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
.rowDetail {
    @include for-big-desktop-up {
        margin-left: -142px !important;
    }
}
.lineBetween {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
}
@include for-big-desktop-up {
    .hrLine {
        width: 459px;
    }
    .scrollProductNotes {
        width: 410px;
    }

    .editProductNote {
        margin: 0px 6px 0px 40px !important;
    }
    .editTextInput {
        width: 100%;
        [data-kt-aside-minimize='on'] & {
            width: 400px;
        }
    }
}
.productNoteText {
    height: 80px;
}
.note-placeholder-text {
    text-align: center;
    margin-top: -100px;
    h6 {        
        color: #1E2749;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
    }
    p {
        color: #a1a5b7;
        font-size: 14px;
        font-weight: 400;
        line-height: 149%;
    }
}