//
// Table
//

@import '../../palette';
.table {
    width: 100%;

    > :not(:last-child) > :last-child > * {
        border-bottom-color: inherit;
    }

    td {
        height: 70px;
    }

    tr,
    th,
    td {
        // border-color: inherit;
        // border-width: inherit;
        // border-style: inherit;
        text-transform: inherit;
        font-weight: inherit;
        font-size: 14px;
        color: inherit;        
        line-height: 19px;
        min-height: inherit;
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
    thead {
        border-bottom: 1px solid $primary-table-border;

        tr {
            th {
                border-bottom: 1px solid $primary-table-border;           
                font-size: 14px;
                line-height: 22px;    
                color: #1e2749;
                font-family: 'poppins-regular';
                font-weight: 600;
            }
        }
    }
    tfoot,
    tbody {
        tr:last-child {
            border-bottom: 0 !important;

            th,
            td {
                border-bottom: 0 !important;
            }
        }

        tr {
            td {
                font-family: 'poppins-regular';
                color: #66728b;
            }
        }

        tr:hover {
            background: #f8f8fa;
        }
    }

    tfoot {
        th,
        td {
            border-top: inherit;
        }
    }

    // Rounded
    &.table-rounded {
        border-radius: $border-radius;
        border-spacing: 0;
        border-collapse: separate;
    }

    // Flush
    &.table-flush {
        tr,
        th,
        td {
            padding: inherit;
        }
    }

    // Row bordered
    &.table-row-bordered {
        tr {
            border: 0px solid $primary-table-border !important;
        }

        tfoot {
            th,
            td {
                border-top-width: 1px !important;
            }
        }
    }

    // Row dashed
    &.table-row-dashed {
        tr {
            border-bottom-width: 1px;
            border-bottom-style: dashed;
            border-bottom-color: $border-color;
        }

        tfoot {
            th,
            td {
                border-top-width: 1px !important;
            }
        }
    }

    // Row border colors
    @each $color, $value in $grays {
        &.table-row-gray-#{$color} {
            tr {
                border-bottom-color: $value;
            }
        }
    }
}

// Cell gutters
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $name, $value in $gutters {
            .table.g#{$infix}-#{$name} {
                th,
                td {
                    padding: $value;
                }
            }

            .table.gy#{$infix}-#{$name} {
                th,
                td {
                    padding-top: $value;
                    padding-bottom: $value;
                }
            }

            .table.gx#{$infix}-#{$name} {
                th,
                td {
                    padding-left: $value;
                    padding-right: $value;
                }
            }

            .table.gs#{$infix}-#{$name} {
                th,
                td {
                    &:first-child {
                        padding-left: $value;
                    }

                    &:last-child {
                        padding-right: $value;
                    }
                }
            }

            .table .cell-height {
                padding-top: 7.75px !important;
                padding-bottom: 7.75px !important;
            }
            .table .no-padding {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
            .table .no-y-padding {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
            .table .left-right-padding{
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }
    }
}
