.pane {
  &-hScroll {
    overflow: auto;
    border-radius: 10px;
  }

  &-vScroll {
    overflow-y: auto;
    overflow-x: hidden;
    // height: 500px;
  }

  table {
    border-collapse: collapse;
    background: white;
    table-layout: fixed;
    width: 100%;

    th {
      height: 70px;

      &.scrollbar-cell {
        padding: 0;
        width: 8px;
      }
    }

    td {                  
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;      
    }

    // .minBoxSize {
    //   min-width: 120px !important;    
    // }
    // .minMidSize {
    //   min-width: 180px !important;
    // }

    .minBoxSize {
      width: 120px;
    }
    .minMidSize {
      width: 180px;
    }

    // @media screen and (max-width: 1919px) {
    //   .minBoxSize {
    //     width: 120px;
    //   }
    //   .minMidSize {
    //     width: 180px;
    //   }
    // }
  }
}

.no-products-found{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;

}