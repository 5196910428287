.product {
    .side-bar {
        position: relative;
        background: #ffffff;
        box-shadow: 0px 5px 30px rgba(19, 16, 57, 0.1);
        transition: right 0.2s linear;
    }

    .side-bar.side-bar-hidden {
        position: fixed;        
        right: 100%;
        .bar-toggle-arrow {
            display: none;
        }
    }

    .no-shadow-sidebar {
        box-shadow: none !important;
    }

    .side-bar.side-bar-hover {
        position: fixed;
        right: 0px;
    }

    .w-380px {
        width: 380px !important;
    }

    .width-animation {
        transition: width 2s linear;
    }

    .rapid-action-title {
        font-size: 18px;
        line-height: 18px;
        color: rgba(30, 39, 73, 0.8);
    }

    .collection-title {
        color: #a1a3ab !important;
    }

    .inactive-dot {
        background: #e0e1e5;
        height: 7px;
        width: 7px;
        border-radius: 50%;
    }

    .active-dot {
        background: rgba(52, 199, 89, 1);
        height: 7px;
        width: 7px;
        border-radius: 50%;
    }

    .carousel-arrow-div {
        background: none;
        border: 0;
        transition: opacity 0.15s ease;
        padding: 0px;
        height: 23px !important;
        width: 22px;
        border-radius: 6px;
        position: relative;
    }
    .carousel-arrow-div:hover {
        background: rgba(0, 163, 255, 0.1);
        .filter-blue {
            filter: invert(44%) sepia(35%) saturate(3228%) hue-rotate(177deg)
                brightness(103%) contrast(110%);
        }
    }
    .carousel-arrow-div:active {
        border: 1px solid #00a3ff;
    }
    .carousel-arrow {
        @include svg-icon-color(rgba(0, 163, 255, 1));
    }

    .disable-carousel-arrow-div {
        background: none !important;
        border: none !important;
    }

    .prod-img {
        border: 2px solid rgba(0, 163, 255, 0.3) !important;
    }

    .product-name {
        color: rgba(30, 39, 73, 0.8);
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 13px;
        max-height: 26px;
    }

    .img-border-radius {
        border-radius: 12px;
    }

    .round-image {
        border: 1px solid #e3e3e6;
        padding: 2px;
        border-radius: 50%;
    }
    .round-image:hover {
        border: 1px solid rgba(0, 163, 255, 0.1);
    }
    .round-image:active {
        outline: 1px solid #00a3ff;
        border: 2px solid rgba(0, 163, 255, 0.3) !important;
    }
    .img-border-radius:hover {
        border: 2px solid rgba(0, 163, 255, 0.3) !important;
    }
    .img-border-radius:active {
        outline: 1px solid #00a3ff;
        border: 2px solid rgba(0, 163, 255, 0.3) !important;
    }
    div.scrollable-table-wrapper {
        height: 500px;
        overflow: scroll;

        thead tr th {
            position: sticky;
            top: 0;
            z-index: 40;
            border-color: transparent !important;
            box-shadow: none !important;
            padding-top: 1.5rem !important;
            padding-bottom: 1.5rem !important;
        }
    }

    .drag-svg {
        visibility: hidden;
    }

    .table-row:hover, .dragging-row {
        .drag-svg {
            visibility: visible;
        }
        // .form-check-input {
        //     background-color: white;
        // }
        .form-check.form-check-solid .form-check-input:checked {
            background-color: #009ef7;
        }
    }

    .blue-drag-icon {
        @include svg-icon-color(#00A3FF)
    }

    .z-index-140 {
        z-index: 140;
    }
    .z-index-1 {
        z-index: 1;
    }
}

.collapseIcon {
    border-radius: 6px;
    margin-left: 2px;
}
.collapseIcon:hover {
    background-color: rgb(255 255 255 / 10%);
    filter: invert(44%) sepia(35%) saturate(3228%) hue-rotate(177deg)
        brightness(103%) contrast(110%);
}
.collapseIcon:active {
    border: 1px solid #a7dfff;
}

.table-header-background {
    background-color: #f8f8fa !important;
}

.delete-product-icon {
    box-shadow: 0px 5px 30px rgba(19, 16, 57, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    top: -8px;
    background-color: #ffffff;
    right: -8px;
    cursor: pointer;
}

.cross-icon {
    @include svg-icon-color(#f1416c);
}