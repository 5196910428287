@import '../../../assets/sass/palette.scss';
.tag-text {
    background: $light-gray;
    color: $light-gray-text;
}
.table-dark-strip {
    background: $light-gray-table;
}
.table-border-right {
    border-right: 1px solid $primary-border-color;
    border-bottom: 1px solid $primary-border-color !important;
}
.table-border-left {
    border-bottom: 1px solid $primary-border-color !important;
    border-left: 1px solid $primary-border-color;
}
.table-border-bottom {
    border-bottom: 1px solid $primary-border-color !important;
}
.main-table-text {
    color: $dark-text;
}
.table-product-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    transition-duration: 1000ms;
    border-radius: 8px;
}
.table-input-field {
    max-width: 110px;
}

.noFocusOutline {
    &:focus-visible {
        outline: none;
    }

    .option-title {                
        font-size: 14px;        
        font-weight: 400;
        line-height: 149%;
    }

    .option-item {
        border-radius: 7px;
        color: #66728b;
        font-size: 12px;        
        font-weight: 400;
        line-height: 120%;
        padding: 6px 12px;
        border-radius: 7px;
        background-color: #f8f8fa;
    }
}

.variant-subtitle {
    color: #1E2749;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
}

.variant-table {
    .price-widget {
        span {
            top: 6px;
            left: 5px;
            color: #181c32;
        }
    }
    .disabled-cell {
        pointer-events: none;
        cursor: not-allowed;
    }
    .variant-type-cell {
        .type-text {
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            color: #1e2749;
        }
    }
    &.scrolling-left {
        tr:not(.table-dark-strip) {            
            .variant-type-cell {
                &::after {
                    box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 6%);
                }
            }   
            
            th.variant-type-cell {
                &::after {                
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -1px;
                    width: 30px;
                    transform: translateX(100%);
                    transition: box-shadow .3s;
                    content: "";
                    pointer-events: none;                
                    border-left: 1px solid #e9e9eb;       
                } 
            }
        }     
        
        tr.table-dark-strip {
            .variant-type-cell {
                &::after {
                    box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 6%);
                    border-left: 1px solid #e9e9eb;
                }
            }
        }
    }

    tr {
        &.table-dark-strip {
            background-color: #f8f8fa !important;

            th {
                background: #f8f8fa !important;  
            }

            th:first-child {
                position: sticky;
                left: 0;
                z-index: 1;    
                width: 45px;                                
                border-top-left-radius: 10px !important;           
            }
            
            th:nth-child(2) { 
                position: sticky;
                // left: 45px;
                // left: 37px;
                left: 42px;
                z-index: 1;      
                // width: 76px;
                width: 50px;                
                
                &::after {
                    position: absolute;
                    top: 0;
                    right: 1px;
                    bottom: -1px;
                    width: 30px;
                    transform: translateX(100%);
                    transition: box-shadow .3s;
                    content: "";
                    pointer-events: none;                                    
                }
            }

            th:last-child {
                border-top-right-radius: 10px !important;
            }
        }
    }

    tr:not(.table-dark-strip) {
        td:first-child, th:first-child {
            position: sticky;
            left: 0;
            z-index: 1;    
            // width: 64px;    
            width: 40px;            
            background: #fff;              
        }
        
        td:nth-child(2), th:nth-child(2)  { 
            position: sticky;
            // left: 63px;
            left: 41px;
            z-index: 1;      
            // width: 76px;
            width: 50px;
            background: #fff;                           
        }

        .image-cell { 
            &::after {
                border-right: 1px solid #e9e9eb;
                position: absolute;
                top: 0;
                right: 0;                
                transform: translateX(100%);
                transition: box-shadow .3s;
                content: "";
                pointer-events: none;   
                height: 100%;         
            }                      
        }

        td:nth-child(3) {
            &::after {                
                position: absolute;
                top: 0;
                right: 0;
                bottom: -1px;
                width: 30px;
                transform: translateX(100%);
                transition: box-shadow .3s;
                content: "";
                pointer-events: none;                
                border-left: 1px solid #e9e9eb;       
            } 
        }

        td:nth-child(3), th:nth-child(3)  { 
            position: sticky;
            // left: 140px;
            // left: 115px;
            left: 91px;
            z-index: 1;    
            width: 210px;        
            background: #fff;                
        }

        td:nth-child(3) {
            border-right: 1px solid $primary-border-color;
        }
    }    

    tr:not(.table-dark-strip) {
        td:last-child {
            border-right: none !important;            
        }      

        &.selected-row {
            &:hover {
                td:nth-child(1),
                td:nth-child(2),
                td:nth-child(3) {
                    background-color: #edf6fb;
                } 
            }
        }
        
        &:not(.selected-row) {
            &:hover {
                td:nth-child(1),
                td:nth-child(2),
                td:nth-child(3) {
                    background-color: #F8F8FA;
                } 
            }
        }
    }
}

.variant-table-content {
    tr:not(.fake-header):hover {
        background-color: #F8F8FA;
    }

    .selected-row {
        background-color: #edf6fb;
        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(3) {
            background-color: #edf6fb !important;
        }

        &:hover {
            background-color: #edf6fb !important;

            td {
                background-color: #edf6fb;
            }
        }        
    }

    .table-input-field {
        background: #fff !important;
        border: 1px solid #E4E6EF;
        border-radius: 7px;
        height: 30px;
        padding: 10px;
    }
}

.attribute-cell {
    div, span, input {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        color: #66728B !important;
    }

    div {
        min-width: 150px;
    }

    input {
        padding-left: 0;
        min-width: 110px;
        width: 150px !important;
        background-color: transparent;
    }
}

.price-cell {
    display: flex;
    align-items: center;

    input {
        padding-left: 0;
        min-width: 110px;
        width: 130px !important;
    }
}

.weight-cell {
    display: flex;
    align-items: center;
    position: relative;

    input {
        padding-left: 0;
        min-width: 110px;
        width: 130px !important;
        cursor: pointer;
    }

    .weight-measure {
        position: absolute;
        right: 0;
    }

}

.status-cell {
    .active-status-box, .inactive-status-box {
        width: 90px;
    }
    .variant-status {
        text-transform: capitalize;
    }
}

.status-box {
    padding: 10px 8px !important;
    background-color: #fff !important;
    width: 130px !important;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 14px !important;
    border-radius: 7px;
    border: 1px solid #E4E6EF !important;
    height: 30px !important;
    
    .placeholder {
        color: #A1A5B7 !important;
    }
}

.status-dropdown-menu {
    width: 130px;
    overflow: hidden !important;
}

.focus-field:active,
.focus-field:focus-within,
.focus-field:focus {
    outline: 2px solid #00A3FF;
    border-radius: 3px;
    border:0 !important;
    outline-offset: -2px !important;
}

.focused-cell {
    outline: 2px solid #00A3FF;
    border-radius: 3px;
    border:0 !important;
    outline-offset: -2px !important;
}

.variant-table-header-cell {
    padding-bottom: 16px !important;
}
