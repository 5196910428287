@font-face {
    font-family: 'poppins-black';
    src: url(./assets/fonts/Poppins-Black.ttf) format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'poppins-bold';
    src: url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'poppins-extrabold';
    src: url(./assets/fonts/Poppins-ExtraBold.ttf) format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'poppins-light';
    src: url(./assets/fonts/Poppins-Light.ttf) format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'poppins-medium';
    src: url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'poppins-regular';
    src: url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'poppins-semibold';
    src: url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
    font-display: block;
}

html {
    scroll-behavior: smooth;
}

body {
    height: 100vh;
    width: 100vw;
    margin: 0;
    color: #1e2749;
    font-size: 11pt;
    font-family: 'poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: block;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hidescroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.hidescroll::-webkit-scrollbar {
    display: none;
}

.half-opacity {
    filter: opacity(50%);
}

.smallicon {
    width: 25px;
    height: 25px;
    padding: 5px;
}

.smallicon:hover {
    color: rgb(54, 174, 214);
}

.icon-bg {
    background-color: whitesmoke;
}

.hover-border:hover {
    outline: 1px solid rgb(41, 175, 219);
}

.animation-smooth {
    transition-duration: 200ms;
}

.side-loading {
    height: 100px;
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-responsive-modal-modal {
    width: 600px;
    box-shadow: 0px 5px 30px rgba(19, 16, 57, 0.1);
    border-radius: 20px;
    padding: 0px;
}
.modalCls {
    padding: 0px;
}
.padding30 {
    padding: 20px 0px;
}
.points-none {
    pointer-events: none !important;
}
.full-height {
    height: 100vh;
}
