.taxLogheaderContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.mybrdr {

    border: solid red 1px;
}

.log-container{
    height: 1000px;
    overflow: scroll;
}
.methodContainer{
    display: flex;
}

.log-url{
    margin-left: 10px;
    margin-right: 20px;
}

.urlcontainer{
padding-top: 15px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
}

.logs-breaker{
    border: solid 1px gray ;
    margin: 50px;
    margin-right: 100px;
    margin-left: 100px;
}