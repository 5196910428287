@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
.toolTipShip {
    width: 350px !important;
}
.connected {
    color: $select-drop-hover;
}
.shipping {
    .modal-dialog {
        position: absolute !important;
        right: 25px !important;
        display: block !important;
    }
}

.mediaGreyText {
    color: $secondary-grey-shade !important;
}
.unLine {
    text-decoration: underline;
}

.override-content {
    .details-page-container.has-change {
        padding-top: 0 !important;
    }
}

.has-change.shipping-setting-title {
    margin-top: 24px !important;
}