.titleText {
    font-size: 23px;
}
.messageText {
    font-size: 18px;
}
.icon {
    margin-left: 47%;
    height: 41px;
    filter: invert(38%) sepia(45%) saturate(3910%) hue-rotate(314deg)
        brightness(85%) contrast(101%);
}
