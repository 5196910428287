// //
// // Content
// //

// // Desktop mode
// @include media-breakpoint-up(lg) {
//   .content {
//     padding: get($content-spacing, desktop) 0;

//     // Toolbar enabled and toolbar not fixed mode
//     .toolbar-enabled:not(.toolbar-fixed) & {
//       padding-top: 0;
//     }
//   }
// }

// // Tablet & mobile modes
// @include media-breakpoint-down(lg) {
//   .content {
//     padding: get($content-spacing, tablet-and-mobile) 0;

//     // Toolbar enabled mode and toolbar not fixed mode
//     .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
//       padding-top: 0;
//     }
//   }
// }

.override-content {
    max-width: 100vw !important;
    margin: 0px !important;
    min-height: 100vh !important;

    .details-page {
        padding: 0 40px;

        // Page header styles
        &-header {
            padding: 40px 0;
        }

        &-container {
            max-width: 1058px;
            margin: 0 auto;
            padding: 40px 0;
            &.is-connected {
                max-width: 778px;
            }
    
            .main-detail-layout {
                .left-pane,
                .right-pane {
                    margin: 0 auto;
                }
                
                @media screen and (min-width: 990px) {
                    display: flex;
                    justify-content: space-between;
                    column-gap: 24px;      
                    
                    .left-pane {
                        flex: 1;
                    }
                }
    
                @media screen and (min-width: 1440px) {
                    .right-pane {
                        width: 334px;
                    }
                }
    
                @media screen and (max-width: 1439px) and (min-width: 990px) {
                    .right-pane {                
                        width: 285px;
                    }                    
                }

                @media screen and (min-width: 990px) {
                    .right-pane {                
                        // width: 285px;

                        &.custom-details-page,
                        &.order-details-page {
                            width: 318px !important;
                        }

                        &.home-analytics-page,
                        &.liveshow-settings-page {
                            width: 401px !important;
                        }
                    }                    
                }
    
                .table-wrapper {
                    @media screen and (min-width: 1280px)  {
                        max-width: 636px;
                    }                    
                
                    // @media screen and (max-width: 1279px) and (min-width: 990px) {
                    //     max-width: 525px;
                    // }
                }
            }
        }
    }

    .custom-page-header {
        margin: 0 0 40px;
    }
}
