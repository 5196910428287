@import '../../../assets/sass/palette.scss';

.live-show-stepper {
    border-bottom: 1px solid $primary-border-color;
    .step-label {
        flex: 1 1 0px;
        font-size: 12px;
        font-weight: 700;
    }
    .bar-class {
        background-color: #E5F6FF;
        height: 10px;
        border-radius: 50px;
    }
}