.shop-details-page {
  &-container {
    max-width: 778px;
    margin: 0 auto;
  }
  
  .section-title {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0em;
    color: #1e2749;
    font-weight: 600;
  }

  .phone-code-input {
    width: 113px;
    
    &.active {
      border: 1px solid #00a3ff !important;
    }
  }

  .country-search-input {
    border: 1px solid #00a3ff !important;
  } 
}