.createCouponPage {
    .openFolderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        transition: transform linear 0.1s;
        transform: rotate(0deg);
    }

    .openFolderWrapperIsOpen {
        transform: rotate(-90deg);
    }
    .createCouponCheckBox {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #1e2749;
    }
    .createCouponRadioButtonOuter {
        min-height: 20px;
        max-height: 20px;
        min-width: 20px;
        max-width: 20px;
        border-radius: 50%;
        border: 1px solid #e9e9eb;
    }
    .createCouponRadioButtonOuterSelected {
        min-height: 20px;
        max-height: 20px;
        min-width: 20px;
        max-width: 20px;
        border-radius: 50%;
        border: 1px solid #00a3ff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .createCouponRadioButtonInnerSelected {
        min-height: 10px;
        max-height: 10px;
        min-width: 10px;
        max-width: 10px;
        border-radius: 50%;
        background-color: #00a3ff;
    }
    .createcouponRadioText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #4b526d;
    }
    // Check Box
    .createCouponCheckBoxOuter {
        min-height: 20px;
        max-height: 20px;
        min-width: 20px;
        max-width: 20px;
        border-radius: 5px;
        background: #f8f8fa;
    }
    .createCouponCheckBoxOuterSelected {
        min-height: 20px;
        max-height: 20px;
        min-width: 20px;
        max-width: 20px;
        border-radius: 5px;
        background-color: #00a3ff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .createCouponCheckBoxInnerSelected {
        min-height: 10px;
        max-height: 10px;
        min-width: 10px;
        max-width: 10px;
    }

    .summaryText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #a1a5b7;
    }
    .summaryInfoText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        color: #4b526d;
    }
    .summaryInfoDescText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #a1a5b7;
    }
    .createCouponCheckBoxSpace {
        min-height: 20px;
        max-height: 20px;
        min-width: 20px;
        max-width: 20px;
        border-radius: 5px;
        opacity: 0;
    }
    .statusSmallHeading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: rgba(30, 39, 73, 0.8);
    }
    .dateBoxText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #4b526d;
    }
    .timeAMPM {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #4b526d;
    }
    .timeAMPMSelected {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #00a3ff;
    }
    .coverFit {
        object-fit: cover;
    }
    .customerBlockName {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #4b526d;
    }
    .customerBlockEmail {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #4b526d;
    }
    .valueBoxes {
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
    }
    .valueBoxesLeft {
        border: 1px solid;
        border-radius: 5px 0px 0px 5px;
    }
    .valueBoxesRight {
        border: 1px solid;
        border-radius: 0px 5px 5px 0px;
    }
    .valueBoxSelected {
        border-color: #00a3ff;
        background-color: #00a3ff;
        color: white;
    }
    .valueBoxNotSelected {
        border-color: #e4e6ef;
        color: #1e2749;
    }
    .valuePlaceHodler {
        color: #a1a5b7;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    }
    .valueInput {
        width: 237px;
        ::placeholder {
            color: #a1a5b7;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
        }

        .percentage-value {
            right: 10px;
            top: 10px;
        }
    }
    .section-content {
        margin-left: 34px;
    }
    .valueColor {
        color: #4b526d;
    }
    .arrow-icons {
        right: 10px;
        top: 5px;
    }
    .pamTitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #1e2749;
        opacity: 0.8;
    }
    .pamSelectorTitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #4b526d;
    }
    .pamSelectorDesc {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #a1a5b7;
    }
    .customerBuyGetSamllHeading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #4b526d;
    }
    .lightDescription {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #4b526d;
        opacity: 0.5;
    }
    .countriesSmallHeader {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #1e2749;
    }
    .countriesSmallHeaderDesc {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #4b526d;
    }
    .disabled-section {
        pointer-events: none;
        opacity: 0.6;
    }
}
