.f-16px{
    font-size: 16px;
}

.f-24px{
    font-size: 24px;
}

.subtitle{
    color: rgba(30, 39, 73, 0.8);
}