@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
      @content;
  }
}

.root {
    background-color: white;
    color: black;
    border-radius: 7px;
    width: 270px;
    height: 40px;
    filter: $drop-shadow-color;
    cursor: pointer;
  }

  @include for-big-desktop-up {
    .root {
      width: 370px;
    }
  }
 

.dragIcon {
    margin-left: 7px;
  }

.folderText {
    padding-top: 135px !important;
    margin-top: 135px !important;
    position: relative;
    top: 5px;
  }
  