.top-55 {
    top: 55px !important;
}
.minBoxSize {
    min-width: 120px !important;    
}
.minMidSize {
    min-width: 180px !important;
}
.minBigBoxSize {
    min-width: 250px !important;
}
.detail-table-cell {
    padding: 30px !important;
}
.detail-text-section {
    padding-top: 17px;
}
.detail-item {
    min-height: 50px;

    &.mb-10 {
        margin-bottom: 30px !important;
    }

    &-header {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;    
        margin: 0 0 4px;    
    }
}
.filter-chips {
    margin: 15px 0;
    display: block;

    span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
    }

    .chip-item {
        background-color: #F8F8FA;        
        color: #4B526D;
        padding: 4px 10px;
        border-radius: 5px;
        margin: 0 5px 5px 0;
        display: inline-block;
    
        .svg-icon {
            svg {
                height: 14px;
                width: 14px;
                margin-left: 8px;
    
                rect {
                    fill: #8F93A4 !important;
                }            
            }
        }
    }

    .clear-all {
        color: #00A3FF;
        margin-left: 15px;
    }
}
