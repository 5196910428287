.custom-select-dropdown {
  .menu-item {   
    position: relative;

    .input-box {
      margin-left: 1px;
      margin-right: 1px;
    }
    
    .dropdown-item {
      &.active {
        background: #f8f8fa;                
      }
    }

    .menu-link {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .svg-icon {
        padding: 5px;
        border-radius: 50%;                
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;

        &.active,
        &:hover {
          background-color: #E6F6FF;

          svg {
            path {
              fill: #00A3FF;
            }
          }
        }

        svg {
          height: 10px;                   
        }
      }
    }

    .tags-action-menu {
      position: absolute;
      background: #FFFFFF;
      box-shadow: 0px 5px 14px rgb(19 16 57 / 14%);
      border-radius: 5px;
      width: 134px;
      z-index: 12;
      right: 20px;
      top: 38px;
      padding: 5px;

      &.up-direction {
        top: -76px;
      }

      div {
        padding: 10.5px 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;                
        color: #4A516C;
        border-radius: 7px;

        &:hover {
          background-color: #f8f8fa;
        }
      }
    }

    .tag-name {
      color: #4B526D;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .more-action-icon {
      position: absolute;
      right: 5px;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 20px;
      border-radius: 7px;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;                
      color: #4b526d;

      &:focus {
        outline: 1px solid #00A3FF;
      }

      &.error {
        outline: 1px solid #F1416C;
      }
    }

    .error-message {
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      color: #F1416C;
    }
  }
}

.search-select-dropdown {
  margin-top: 10px;
  padding: 10px;
  max-height: 304px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;

  .options {
    padding-bottom: 40px;

    .option-item {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #4B526D;
      padding: 15px;
      cursor: pointer;
      border-radius: 7px;

      &.no-option {
        cursor: unset;
      }

      &.add-vendor-link {
        color: #00a3ff;
      }
    }
  }
}