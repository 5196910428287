:root {
  --ZOOM-MULTIPLIER: 1;
}
@media screen and (max-width: 1919px) and (min-width: 1440px) {
  :root {
    --ZOOM-MULTIPLIER: 1;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1280px) {
  :root {
    --ZOOM-MULTIPLIER: 0.7;
  }
}
.live-viewers-highlights {
  @media screen and (max-width: 991px) {
    height: 35rem;
    margin-top: calc(var(--ZOOM-MULTIPLIER) * 1.42rem) !important;
  }
}

.livevideo-stream-wrapper {  
  border-radius: 10px;
  background-color: #ffffff;
  display: inline-block;
  
  canvas {
    // border-radius: calc(var(--ZOOM-MULTIPLIER) * 1rem);
    // width: 100%;
    height: 100%;
    border-radius: 0;
  }
  video {
    // border-radius: calc(var(--ZOOM-MULTIPLIER) * 1rem);
    // width: 100%;
    height: 100%;
    border-radius: 0;
  }
  p {
    font-weight: 500;
    font-size: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
    line-height: calc(var(--ZOOM-MULTIPLIER) * 2.57rem);
    margin: 0;
  }
  .video-controller {
    display: flex;
    justify-content: center;
    padding-top: calc(var(--ZOOM-MULTIPLIER) * 2.14rem);
  }

  &-inner {
    position: relative;
    z-index: 111;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    background-color: #000;
    text-align: center;
    // width: calc(var(--ZOOM-MULTIPLIER) * 16.65rem);
    // height: calc(var(--ZOOM-MULTIPLIER) * 29.6rem);

    @media screen and (max-width: 1279px) {
      width: 13.5rem !important;
      height: 24rem !important;
    }

    .controls-overlay {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: calc(var(--ZOOM-MULTIPLIER) * 1rem) 0;
      background: #0000006e;
      border-radius: 0px 0px calc(var(--ZOOM-MULTIPLIER) * 1rem) calc(var(--ZOOM-MULTIPLIER) * 1rem);
      position: absolute;
      bottom: 0;
      width: 100%;
      transition: 0.3s;
      // transform: translateY(calc(var(--ZOOM-MULTIPLIER) * (-5.3rem)));
      height: translateY(calc(var(--ZOOM-MULTIPLIER) * 5.3rem));
      // transform: translateY(calc(var(--ZOOM-MULTIPLIER) * (-4.72rem)));

      &.controls-hidden {
        transition: 1s;
        // transform: translateY(calc(var(--ZOOM-MULTIPLIER) * (-0.5rem)));
        // transform: translateY(70px);
      transform: translateY(calc(var(--ZOOM-MULTIPLIER) * (5.3rem)));

      }
    }

    .shopper-app-status-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
      width: 100%;
      position: absolute;
    
      .live-timer {
        border-radius: 5px;
        background-color: #161a1d4d;
    
        padding: calc(var(--ZOOM-MULTIPLIER) * 0.28rem) calc(var(--ZOOM-MULTIPLIER) * 0.71rem);
        span {
          font-family: 'Poppins';
          font-weight: 400;
          font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
          line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
          color: #ffffff;
        }
      }
      .shopper-app-status {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .mobile-icon {
          width: 0.85rem;
        }

        .facebook-icon {
          width: 1.28rem;
        }
      }
    } 
  }
}

.liveshow-dashboard {
  // padding: calc(var(--ZOOM-MULTIPLIER) * 2rem) 0;
  padding: 30px;
  max-height: 100vh;

  .row:before,
  .row:after {
    display: none !important;
  }
}

.liveshow-products-section {
  flex: 1 !important;
}

.live-video-canvas-desktop {
  // width: 260px;
  // height: 460px;
  border-radius: 10px;
}

.liveshow-metric-sections {
  flex: 1;
  
  @media screen and (max-width: 1279px) {
    margin-top: calc(var(--ZOOM-MULTIPLIER) * 1.42rem) !important;
    padding-left: 0 !important;
  }

  .upper-container, .lower-container {
    height: calc(50vh - 42px);
    // margin-bottom: calc(var(--ZOOM-MULTIPLIER) * 1.42rem) !important;
  }
}
