.fs-9px {
    font-size: 9px !important;
}

.overflowx-hidden {
    overflow-x: hidden !important;
}

.taxwrap {
    overflow: hidden;
    text-overflow: ellipsis;
}

.w-70 {
    width: 70% !important;
}

.disable-background { 
    background-color: #f8f8fa !important;
    cursor: default;
    pointer-events: none;
}

.disable-hover:hover {
    border: 1px solid #E4E6EF !important;
}
.package-placeholder-img {
    display: flex;
    width: 100%;
    padding: 45px;
}
.fulfill-input-label {    
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    color: #1e2749;    
}
.selected-value {
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #1e2749;   
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.placeholder {
        color: #a1a5b7;
    }
}
.order-dimensions-text {    
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #a1a5b7;
}
.shipment-options-text {    
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    color: #1e2749;    
}