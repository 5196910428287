@import '../../../assets/sass/palette.scss';
.custom-divider {
    background-color: $input-border-color;
    width: 100%;
    height: 1px;
    display: flex;
}
.custom-vertical-divider {
    background-color: $input-border-color;
    width: 1px;
    height: 30px;
    display: flex;
    margin: 0 5px;
}
