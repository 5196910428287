@import '../../../assets/sass/palette.scss';

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

.simple2 .simple-inner {
    height: auto;
}

.simple2 ol li {
    border-bottom: 0 none;
    position: relative;
    transition: all ease 0.3s;
    float: left;
    box-sizing: border-box;
    text-align: center;
    margin: 0 8px 12px 0;
}

.simple2 ol::after {
    content: '.';
    display: block;
    visibility: hidden;
    height: 0;
    line-height: 0;
    font-size: 0;
    clear: both;
}

.checks {
    position: absolute;
    top: 24px;
    left: 8px;
}

.drags {
    position: absolute;
    top: 19px;
    right: 15px;

    svg {
        path {
            fill: #fff !important;
        }
    }
}

.addUsers {
    position: absolute;
    // top: 104px;
    // right: 3px;
    bottom: 0px;
    right: 14px;
}

.simple2 ol li:first-child {
    .addUsers {
        position: absolute;
        top: 487px;
        right: 515px;
    }
}
.simple2 ol li:first-child {
    border-left: 0 none;
    width: 312px !important;
    height: 312px !important;
    position: relative !important;
}

.imageUploadArea {
    height: 150px;
    width: 150px;
    background: rgba(0, 163, 255, 0.1);
    border: 1px dashed #00A3FF;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    &:hover {
        background-color: #CCEDFF;
    }

    &:active {
        border: 1px solid #00A3FF;
    }

    img {
        margin: auto;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;        
    }
}

ol li {
    list-style-type: none;
}

.modalSize {
    height: 300px;
    width: 300px;
}
.modal {
    background-color: $modal-bg-color !important;
}
.closeIcon {
    position: absolute;
    right: 80px;
    top: 50px;
    background-color: transparent;
    border: none;

    svg {
        width: 24px !important;
        height: 24px !important;

        g path {
            fill: #fff !important;
        }

        &:hover {
            g path {
                fill: #00A3FF !important;
            }
        }
    }
}
.btnGroup {
    position: absolute;
    top: -1%;
    right: 2rem;
}
.arrows {
    width: 28px !important;
    position: absolute;
    top: calc(50% - 25px);
    right: calc(50% - 14px);
    height: 50px;
}
.eachImage {
    width: 150px !important;
    height: 150px !important;

    &.loading {
        position: relative !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner-border {
            position: absolute;
        }
    }

    .controll-box {
        width: 100%;
        height: 100%;
        z-index: 4;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        border: 4px solid #f8f8fA;
        border-radius: 10px;
    }

    input {
        left: 16px;
        top: 16px;
    }
}
.modal .modal-dialog {
    max-width: fit-content !important;
}
.default-product-icon {
    background-color: #e9e9eb;
    border: 4px solid #f8f8fA;
    border-radius: 10px;

    &.product-image {
        object-fit: contain;
        background-color: #fff;
    }
}

.image-preview-modal-content {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    .carousel-root {
        width: 452px !important;

        .carousel.carousel-slider {
            display: flex;
            align-items: center;
        }
        
        .slider-wrapper {
            border-radius: 20px;
        }

        // Slide styles
        .carousel {
            .slide {
                max-height: 600px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;

                &.selected {
                    background-color: transparent !important;
                }

                img {
                    border-radius: 20px;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        // Thumbnails styles
        .carousel {
            .thumbs-wrapper {
                margin: 60px 0 0 0;
                overflow: visible;

                .control-arrow {
                    display: none;
                }
            }

            .thumb {
                border: none;
                width: 80px;
                height: 80px;
                margin-right: 24px;
                opacity: 0.5;

                &.selected {
                    background-color: transparent !important;
                    width: 110px !important;
                    height: 110px;
                    opacity: 1;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
        }

        // Next and Prev Icons
        .carousel-arrow-icon {
            background-color: transparent;
            border: none;

            &.prev-icon {
                position: fixed !important;
                left: 80px !important;
            }
            &.next-icon {
                position: fixed !important;
                right: 80px !important;
            }

            svg {
                &:hover {
                    path {
                        stroke: #00A3FF;
                    }
                }
            }
        }
    }
}
