@import '../../../assets/sass/palette.scss';

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

li {
    padding-inline-start: 0px !important;
}

.app {
    height: 100%;
}

ul {
    list-style: none;
}

.app > ul {
    box-sizing: border-box;
    height: 100%;
    padding: 30px 15px;
}

.treeRoot {
    height: 100%;
}

.draggingSource {
    opacity: 0.3;
}

.dropTarget {
    background-color: $drop-color;
}

.folderName {
    font-size: 14px;
    line-height: 20px;
    margin-right: 15px;
}

.arrowIcon {
    font-size: 16px;
    margin: 0px 15px 15px 0px;
}

.folderIcon {
    margin: 0 15px;
}

.treeStyle {
    padding-bottom: 15px;
}

.addPlusIcon {
    filter: $filterBlueColor;
}

.addButton {
    background-color: $button-background;
}

.btnText {
    filter: $filterBlueColor;
}

.expandAll {
    filter: $filterBlueColor;
    margin: 17px 0px 20px 0px !important;
}

.headerTitle {
    margin-top: 22px;
    margin-left: 17px;
    font-family: 'poppins-semibold';
    font-size: 24px;
}

.list {
    padding-top: 100px !important;
}

.treeStyle {
    padding-top: 100px;
}

.titles {
    position: fixed;
    background: white;
    width: 340px;
    height: 95px;
    top: 197px;
    right: 36px;
    transition-duration: 300ms;
    border-radius: 3px 3px 0px 0px;
    [data-kt-aside-minimize='on'] & {
        top: 196px;
        right: 99px;
    }
}
.hidescrollbar::-webkit-scrollbar {
    display: none;
}

@include for-big-desktop-up {
    .addButton {
    }

    .titles {
        right: 26px;
        width: 430px;
        top: 195px;
        height: 105px;
        transition-duration: 300ms;
        [data-kt-aside-minimize='on'] & {
            right: 216px;
        }
    }

    .headerTitle {
        margin-left: 22px;
        font-size: 30px;
    }

    .treeStyle {
        padding-top: 120px;
    }

    .addButton {
    }
}
