.dropdown-width {
    max-width: 250px;
}
.moveToRight {
    // transform: translateX(120px);
    transform: translateX(85px);
}
.search-box {
    padding: 16px 24px;

    input {
        background-color: #f8f8fa !important;
        height: 40px;        
        color: #a1a5b7;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins-regular';
        padding: 16px 10px;

        &:focus {
            outline: 1px solid #00A3FF;
        }
    }
}
.tags-menu-dropdown {
    height: 350px;
}
.tag-item {
    font-size: 12px;
    font-weight: 400;
    padding: 5px;
    width: fit-content;
    display: inline-block;
    border-radius: 7px;
    margin: 3px;

    &.none-color {
        background-color: #f8f8fA !important;
        color: #66728b !important;
    }
}
.limited-tag {
    right: 0;
    top: 0;
    z-index: 12;
}
.tags-list {
    padding: 10px 3px 10px 10px;

    .menu-item {   
        position: relative;

        &.disabled-item {
            .menu-link {
                cursor: default;
                pointer-events: none;

                .tag-name {
                    color: #A1A5B7;
                }
            }            
        }
        
        .dropdown-item {
            &.active {
                background: #f8f8fa;                
            }
        }

        .menu-link {
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .svg-icon {
                padding: 5px;
                border-radius: 50%;                
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;

                &.active {
                    background-color: #E6F6FF;

                    svg {
                        path {
                            fill: #00A3FF;
                        }
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: #00A3FF;
                        }
                    }
                }

                svg {
                    height: 10px;                   
                }
            }
        }

        .tags-action-menu {
            position: absolute;
            background: #FFFFFF;
            box-shadow: 0px 5px 14px rgb(19 16 57 / 14%);
            border-radius: 5px;
            width: 134px;
            z-index: 120000;
            right: 20px;
            top: 38px;
            padding: 5px;

            &.up-direction {
                top: -76px;
            }

            div {
                padding: 10.5px 14px;
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;                
                color: #4A516C;
                border-radius: 7px;

                &:hover {
                    background-color: #f8f8fa;
                }
            }
        }

        .tag-name {
            color: #4B526D;
        }

        .more-action-icon {
            position: absolute;
            right: 10px;
        }

        input {
            box-sizing: border-box;
            width: 100%;
            padding: 10px 20px;
            border-radius: 7px;
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;                
            color: #4A516C;

            &:focus {
                outline: 1px solid #00A3FF;
            }

            &.error {
                outline: 1px solid #F1416C;
            }
        }

        .error-message {
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            color: #F1416C;
        }
    }
}
