.create-liveshow-notifications-step {
    height: 500px;
    overflow: auto;
    .input-label-class {
        color: #1e2749cc;
    }
    .checkbox-title {
        font-weight: 500 !important;
        font-family: 'poppins-medium' !important;
    }
    .facebook-post-warning {
        background: rgba(234, 182, 0, 0.05);
        padding: 15px;
    }
    .warning-text {
        color: #eab600;
    }
}
