// Palette for Common Colors
$primary-color-white: #fff;
$primary-border-color: #e9e9eb;
$primary-white-background: #f8f8fa;
$primary-blue-background: #00a3ff;
$primary-blue-light-background: hsla(202, 100%, 50%, 0.2);
$vendor-shadow: rgba(19, 16, 57, 0.1);
$primary-error-button: rgba(241, 65, 108, 1);
$primary-error-button-background: rgba(241, 65, 108, 0.1);
$primary-red: red;
$light-red: #f1416c1a;
$primary-table-border: #eff2f5;
$select-drop-hover: #34c759;
$selected-item: #61bd4f;
$select-drop-hover-backgroud: rgb(52, 199, 89, 0.1);
$unselect-drop-hover: #8b90a1;
$drop-shadow-background: rgb(19 16 57 / 10%);
$primary-tool-tip: #424758;
$primary-grey: grey;
$primary-secondary: #c2c4cc;
$primary-grey-button: rgba(30, 39, 73, 0.5);
$gray-border-color: #a1a5b7;
$input-border-color: #e4e6ef;
$light-gray: #f8f8fa;
$light-gray-text: #4b526d;
$light-gray-table: #f8f8fa;
$dark-text: rgba(30, 39, 73, 0.8);
$button-text-color: #4a516c;
$custom-danger: rgba(235, 90, 70, 1);
$social-button-color: #00a3ff;
$input-grey: #f5f8fa;
$secondary-grey: #a7a8bb;
$secondary-grey-shade: #66728b;
$medium-grey-shade: #a1a3ab;
$card-background: #def3ff;
$card-hover-background: #cfefff;
$manual-sort-line: #1967d2;
$button-primary-color: #38a2f7;
$drop-color: #e8f0fe;
$button-background: rgba(0, 163, 255, 0.08);
$schd-Btn-Color: rgba(0, 163, 255, 0.1);
$schd-Btn-Color: rgba(0, 163, 255, 0.1);
$act-Btn-Color: rgba(52, 199, 89, 0.1);
$act-Btn-Dark-Color: rgba(52, 199, 89, 0.5);
$purple-Btn: rgba(172, 127, 255, 0.2);
$purple-Btn-Color: rgba(172, 127, 255, 1);
$inact-Btn-Color: rgba(77, 77, 77, 0.1);
$text-light-black: rgba(0, 0, 0, 0.5);
$dnd-text-blue: #1e2749;
$image-bg-color: rgba(0, 163, 255, 0.1);
$modal-bg-color: rgb(13 13 13 / 70%);
$drop-shadow-color: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
$filterBlueColor: invert(44%) sepia(35%) saturate(3228%) hue-rotate(177deg) brightness(103%) contrast(110%);
$filterRedColor: invert(37%) sepia(83%) saturate(2097%) hue-rotate(321deg) brightness(96%) contrast(97%);
$tag-Color: rgba(0, 163, 255, 0.3);
$tagText-Color: rgba(0, 163, 255);
$light-blue-highlight: #edf6fb;
$warning-yellow-color: #eab600;
$light-blue-highlight: #edf6fb;
$light-text: rgba(161, 165, 183, 1);
$light-sort-highlight: rgba(248, 248, 250, 1);

:export {
    greenColor: $select-drop-hover;
}
