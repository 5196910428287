$img-backgroung: #f8f8fa;
$budget-increase: #50cd89;
$budget-decrease: #f1416c;
$budget-same: #ffc700;

.vendor-list {
    .badge {
        font-weight: 500 !important;
    }
    .img-div {
        background: $img-backgroung;
        border-radius: 5px;
    }

    .green-dot {
        background: $budget-increase;
    }

    .red-dot {
        background: $budget-decrease;
    }

    .yellow-dot {
        background: $budget-same;
    }
}
.img-row-div {
    background: $img-backgroung;
    border-radius: 5px;
    height: 130px !important;
    width: 130px !important;
    margin-right: 5px !important;
    border-radius: 5px !important;
}
.profileURL {
    height: 50px !important;
    width: 50px !important;
    border-radius: 5px;
    border: 2px solid $img-backgroung
}
.detailRow {
    z-index: 1;
    position: absolute;
    background-color: white;
    width: 97%;
    box-shadow: 0px 1px 5px 0px lightgrey;
    td {
        border-top: 0px !important;
    }
}