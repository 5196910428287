//
// Menu
//

// Aside menu
.aside-menu {
    margin-top: 127px;

    .menu {
        // Link padding x
        @include menu-link-padding-x(get($aside-config, padding-x));

        // Menu indention
        @include menu-link-indention(
            get($aside-config, menu-indention),
            get($aside-config, padding-x)
        );
    }

    .sub-menu-item {
        margin-left: 32px;
        .active {
            background: rgba(0, 163, 255, 0.1);
            border-radius: 5px 0px 0px 5px;
            border-left: 5px solid #00a3ff;
            margin-left: -5px;
        }
    }

    // Item
    .menu-item {
        padding: 0;

        // Menu Link
        .menu-link {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }
        .menu-link:hover:not(.disabled):not(.active) {
            color: rgba(255, 255, 255, 0.8) !important;
            background: rgba(0, 163, 255, 0.1);
            border-radius: 5px;
            .svg-icon {
                @include svg-icon-color(rgba(255, 255, 255, 0.8) !important);
            }
        }
        .menu-link:active {
            border: 1px solid #00a3ff;
        }

        // Icon
        .menu-icon {
            justify-content: flex-start;
        }

        [data-kt-aside-minimize='on'] & {
            .view-anchor {
                display: none;
            }

            .menu-link:hover:not(.disabled):not(.active) {
                border-radius: 0px;
                border-left: 0px;
            }

            .subMenuClass {
                margin-left: 32px !important;
                padding-left: 0px !important;
                background: none !important;
                border-radius: none !important;
                border: none !important;
            }

            .withoutSub {
                background: none !important;
                border-radius: none !important;
                border: none !important;
                padding-left: 30px !important;
            }

            .active {
                background: rgba(0, 163, 255, 0.1);
                border-radius: 5px 0px 0px 5px;
                border-left: 5px solid #00a3ff;
                padding-left: 20px;

                .menu-icon {
                    .svg-icon {
                        @include svg-icon-color(#09a1fa !important);
                    }
                }
            }
        }
    }
}

// Aside dark theme
.aside-dark {
    .hover-scroll-overlay-y {
        --scrollbar-space: 0.4rem;

        @include scrollbar-color(
            get($aside-config, scrollbar-color),
            get($aside-config, scrollbar-hover-color)
        );
    }


    .menu {
        .menu-item {
            .menu-section {
                color: rgba(255, 255, 255, 0.8) !important;
            }

            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state(
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8),
                null
            );
            @include menu-link-hover-state(
                $white,
                $primary,
                $primary,
                $white,
                null
            );
            @include menu-link-show-state(
                $white,
                $primary,
                $primary,
                $white,
                null
            );
            @include menu-link-active-state(
                #00a3ff,
                #00a3ff,
                #00a3ff,
                #00a3ff,
                null
            );
        }
        .sub-enu-item {
            .menu-section {
                color: rgba(255, 255, 255, 0.8) !important;
            }

            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state(
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8),
                null
            );
            @include menu-link-hover-state(
                $white,
                $primary,
                $primary,
                $white,
                null
            );
            @include menu-link-show-state(
                $white,
                $primary,
                $primary,
                $white,
                null
            );
            @include menu-link-active-state(
                #00a3ff,
                #00a3ff,
                #00a3ff,
                #00a3ff,
                black
            );
        }
    }
}

// Aside light theme
.aside-light {
    .menu {
        .menu-title {
            font-weight: 500;
        }
    }
}
