@import '../../../assets/sass/palette.scss';

.info_radiobutton {
    color: $light-text;
}
.auto_link {
    text-decoration: underline !important;
}
.lightPlaceholderText::placeholder {
    color: red !important;
}
