@import '../../../../assets/sass/core/components/mixins/svg-icon';

.user-permissions-page {
  &-container {
    max-width: 778px;
    margin: 0 auto;
  }

  .user-role-status {
    border-radius: 18px;
    font-size: 12px;
    line-height: 24px;
    padding: 5px 10px !important;

    &:not(.no-hover-effect) {
      &:hover {
        color: #fff !important;
  
        .status-arrow {
          @include svg-icon-color(#fff, !important);
        }
      }
    }

    &.owner {
      background: #f7f2ff;
      color: #8f51ff;

      .owner-arrow {
        @include svg-icon-color(#8f51ff);
      }
    }

    &.admin {
      background: #e6f6ff;
      color: #00a3ff;

      .admin-arrow {
        @include svg-icon-color(#00a3ff);
      }

      &:hover {
        background-color: #00a3ff;        
      }
    }

    &.admin-associate {      
      background: #eefaf4;
      color: #50cd89;      

      .admin-associate-arrow {
        @include svg-icon-color(#50cd89);
      }

      &:hover {
        background-color: #50cd89;        
      }
    }

    &.warehouse-manager {
      background: #fff6e9;      
      color: #ff9f1a;      

      .warehouse-manager-arrow {
        @include svg-icon-color(#ff9f1a);
      }

      &:hover {
        background-color: #ff9f1a;        
      }
    }

    &.fulfilment-associate {      
      background: #feecf1;
      color: #f1416c;            

      .fulfilment-associate-arrow {
        @include svg-icon-color(#f1416c);
      }

      &:hover {
        background-color: #f1416c;        
      }
    }

    &.warehouse-associate {      
      background: #fffae6;
      color: #ffc700;            

      .warehouse-associate-arrow {
        @include svg-icon-color(#ffc700);
      }

      &:hover {
        background-color: #ffc700;        
      }
    }
  }
}

.role-permissions {
  border-radius: 8px;
  background-color: rgba($color: #e6f6ff, $alpha: 0.4);

  h5 {
    margin-bottom: 12px;        
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
  }

  .permission-item {
    background: rgba(230, 246, 255, 1);    
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    padding: 6px 8px;
    color: #00a3ff;
    border-radius: 7px;
    margin-right: 12px;
    margin-bottom: 8px;
    display: inline-block;
  }
}

.user-roles-dropdown {
  top: 60px;
  width: 200px;
}