@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
.vendorPic {
    height: 100px;
    width: 100px;
}
.scrollNotes {
    height: 320px;
    width: 300px;
    overflow: hidden;
    overflow: scroll;
    [data-kt-aside-minimize='on'] & {
        width: 353px;
    }
}
.notesContainerBox {
    background-color: $primary-color-white !important;
    border-radius: 10px !important;
    position: fixed !important;
    width: 400px;
    right: 2%;
    [data-kt-aside-minimize='on'] & {
        background-color: $primary-color-white !important;
        border-radius: 10px !important;
    }
}

.w-365px{
    width: 365px !important;
}
@include for-big-desktop-up {
    .notesContainerBox {
        top: 19% !important;
        position: fixed !important;
        width: 486px;
        right: 25px;
    }
}
.profileUserCls {
    border-radius: 7px;
    margin-right: 10px;
    height: 35px;
}
.detailsCls {
    margin-top: 10px;
    margin-bottom: 10px;
}
.float-right {
    float: right;
}
.vendorPic {
    height: 100px;
    width: 100px;
    background: $primary-white-background;
    border-radius: 5px;
    background: $primary-color-white;
    box-shadow: 0px 5px 30px $vendor-shadow;
    border-radius: 10px;
}
.createNotesInput {
    background: #f8f8fa;
    border-radius: 7px;
    outline: none;
    border: none;
    height: 43px;
    width: 90%;
    color: #66728b;
    margin-right: 10px;
    text-align: left;
    padding: 10px;
    transition-duration: 150ms;
    &:focus {
        height: 80px;
        border: 1px solid $primary-blue-background;
    }
    &::placeholder {
        color: rgba(30, 39, 73, 0.5);
        font-size: 11pt;
    }
}
.noresize {
    resize: none;
}
.detailsContainer {
    padding-left: 0px !important;
}
.endBtn {
    border: 1px solid $primary-red !important;
    color: $primary-red !important;
}
.trashBtn {
    border: 1px solid $primary-secondary !important;
    color: $primary-grey !important;
    height: 30px !important;
    width: 70px !important;
    padding: 4px !important;
    font-size: 12px;
}
.editBtn {
    border: 1px solid $primary-secondary !important;
    color: $primary-grey !important;
    height: 30px !important;
    width: 54px !important;
    padding: 4px !important;
    font-size: 12px;
    margin: 0px 6px 0px 40px;
}
.editsIcon {
    padding-right: 4px;
    height: 10px;
    margin-bottom: 2px;
}
.editTextInput {
    width: 489px;
    min-height: 160px;
    color: gray;
    border: 1px solid $primary-blue-background !important;
    border-radius: 10px;
    padding: 10px 10px 113px 10px;
    margin-left: 1px;
    [data-kt-aside-minimize='on'] & {
        width: 341px;
    }
}
.editTextInput:active {
    border: 1px solid $primary-blue-background !important;
    border-radius: 10px !important;
}
.editTextInput:focus {
    outline: 1px solid $primary-blue-background !important;
    border-radius: 10px !important;
}
.actionEditCls {
    position: absolute;
    bottom: 20px;
}
.btnText {
    color: $primary-grey !important;
}
.endBtn:hover {
    background: $primary-error-button-background !important;
    color: $primary-error-button !important;
}
.otherDetail {
    font-weight: 500;
}
.category {
    font-weight: 600;
    line-height: 2;
}
.categoryTypes {
    font-weight: normal;
}
.subCategoryTypes {
    font-weight: normal !important;
    background: $primary-color-white;
    border-radius: 5px;
    padding: 4px 10px;
    margin: 0px 4px 0px 6px;
}
.links {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $primary-blue-background;
}
.rowDetail {
    @include for-big-desktop-up {
        margin-left: -142px !important;
    }
}
.lineBetween {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
}
.hrLine{
    [data-kt-aside-minimize='on'] & {
        width: 400px;
    }
}
@include for-big-desktop-up {

    .scrollNotes {
        width: 410px;
        height: 425px;
    }
    .detailsCls {
        margin-top: 15px;
    }
    .hrLine{
        [data-kt-aside-minimize='on'] & {
            width: 485px;
        }
    }
}
.vendorNoteText {
    height: 80px !important;
}