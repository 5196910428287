.note-header {
  &-text {
    .username {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1E2749;
    }

    .note-date {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #A1A5B7;
    }
  }
}