@import '../../../assets/sass/palette.scss';

.live-products-preview {
    .preview {
        height: 530px !important;
        background-color: $light-gray;        
    }
    .preview-title {
        font-weight: 500 !important;
    }
    .fixed-height-scroll {
        height: 95%;
        overflow: scroll;
    }
}