.z-index-sidebar {
    z-index: 100 !important;
}

.liveshow-event-preview {
    box-shadow: 0px 5px 30px rgba(19, 16, 57, 0.1) !important;
}

.bar-toggle-arrow {
    position: absolute;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    top: 20px;
    left: -13px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.live-event-preview {
    .live-event-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 39px;
        letter-spacing: 0.01em;
    }
    .breakWord {
        inline-size: 100%;
        overflow-wrap: break-word !important;
    }
    .live-event-date-time {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    .live-event-border-line {
        width: 111%;
    }
    .live-event-products-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: #4a516c;
    }
    .currently-live {
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;

        background: #ea2a11;
        border-radius: 18px;
        color: white;

        // &:hover {
        //     background: rgba(235, 90, 70, 0.1);
        //     color: rgba(235, 90, 70, 1);
        // }
    }
    .past-event {
        background: rgba(122, 134, 160, 0.1);
        color: rgba(122, 134, 160, 1);
        // &:hover {
        //     background-color: rgba(122, 134, 160, 1);
        //     color: white;
        // }
    }
    .upcoming-event {
        background: rgba(0, 163, 255, 0.1);
        color: rgba(0, 163, 255);
        // &:hover {
        //     background-color: rgba(0, 163, 255);
        //     color: white;
        // }
    }
    .live-manager-btn {
        bottom: 30px;
        position: absolute;
        right: 24px;
    }
    .no-products-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #4b526d;
    }
    .no-products-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #a1a5b7;
    }
    .product-name-class {
        width: 200px;
        color: rgb(30, 39, 73);
        &:hover {
            text-decoration: none;
        }
    }
}
