.vw-87{
    width: 87vw !important;
}

.w-97{
    width: 97% !important;
}

.text-btn {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;

    &:hover {
        text-decoration: underline !important;
    }
}

.disabled-text-btn {
    color: #c2c4cc;
}

.selected-option-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #1e2749;

    .placeholder-text,
    &.placeholder-text {
        color: #a1a5b7;
    }
}