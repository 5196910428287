@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

.collectionContainer {
    width: 100%;
    padding-top: 2%;
    overflow-x: hidden;
}
.overflowXScroll {
    overflow-x: scroll;
}
.organization {
    width: 340px !important;
    height: 530px !important;
    margin-left: 5px;
}
.organization::-webkit-scrollbar {
    display: none;
}
@include for-big-desktop-up {
    .collectionContainer {
        width: 1128px !important;
        height: 702px !important;
    }
    .organization {
        width: 430px !important;
        height: 700px !important;
        margin-left: 25px;
    }
    .orgTree {
        font-size: 30px;
    }
}

.orgTree {
    font-size: 20px;
    padding-top: 6px;
}

.columnSubContainer {
    width: 100%;
    min-height: 100%;
}

.smooth-dnd-container {
    height: 100% !important;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
.collectionBigArrowContainer {
    background-color: $light-gray;
}
.collection-table-header-name {
    width: 55%;
}