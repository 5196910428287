@import '../../../assets/sass/palette.scss';
.left-border-flat {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left-width: 0 !important;
}
.right-border-flat {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.form-switch.form-check-solid.form-switch-normal .form-check-input {
    height: 24px;
    width: 40px;
}

.condition-option {
    margin-bottom: 20px;
}

.radio-normal {
    height: 16px;
    width: 16px;
}

.radio-lg {
    height: 18px;
    width: 18px;
    margin-right: 4px !important;
}

.narrow-input {
    width: 190px !important;
}