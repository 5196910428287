.lastDropdownElement {
    transform: translateY(-100%) !important;
}
.show-effects {
    &:hover {
        background: #edf6fb !important;
        border-radius: 10px;
    }
    &:focus {
        background: #edf6fb !important;
        border-radius: 10px;
        border: 1px solid #00a3ff !important;
    }
    .dropdown-item {
        &:hover {
            background: #edf6fb !important;
            border-radius: 10px;
        }
    }
}
.z-index-dropdown {
    z-index: 10;
}

.dropdown-menu {
    width: auto !important;
    // max-height: fit-content;
    min-width: fit-content !important;
    max-height: 184px;
    // left: unset;

    &.full-width-dropdown {
        min-width: 100% !important;    
        width: 100%;
    }

    &.customer-dropdown-menu {
        padding-bottom: 70px !important;
    }

    // &::-webkit-scrollbar-track {
    //     background: #fff;                
    // }        

    // &::-webkit-scrollbar-corner {        
    //     background-color: #fff;
    // }
}
.custom-dropdown {
    padding: 10px !important;
    border-radius: 7px !important;
    overflow-x: hidden;

    &.no-scroll-dropdown {
        padding: 10px !important;
    }

    .dropdown-item {
        padding: 10px !important;
        border-radius: 5px;        

        &-option {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .option-text {
            color: #1e2749;
            font-weight: 400;
            font-size: 14px !important;
            line-height: 120%;
        }
    }

    // &::-webkit-scrollbar-track {
    //     background: #fff;
    //     border-bottom-left-radius: 7px !important;
    //     border-top-right-radius: 7px !important;        
    // }        

    // &::-webkit-scrollbar-corner {        
    //     background-color: #fff;
    // }
}
