@import '../../../assets/sass/palette';
@import '../../../assets/sass/core//components/mixins/svg-icon';

.account-settings-page {
    &-container {
        max-width: 778px;
        margin: 0 auto;

        .profile-data {
            h3 {                
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;   
                margin-bottom: 4px;             
            }

            p {                
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            .file-upload {
                label {
                    padding: 9px 16px;                
                    color: #00a3ff;            
                    font-size: 14px;                
                    font-weight: 500;
                    line-height: 120%;
                    background-color: #00a3ff1a;
                    border-radius: 4px;
                }

                input {
                    display: none;
                }
            }
        }

        .profile-info {
            max-width: 420px;            
        }
    }
}