@import '../../../assets/sass/core/components/mixins/svg-icon';

.tiktok-overview {
  &-item {
    flex: 1;

    h5 {
      color: #a1a5b7;      
      font-size: 14px;      
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 12px;
    }

    p {
      color: #1e2749;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      margin: 0;
    }

    &:not(:first-child) {
      padding-left: 40px;      
      border-left: 1px solid #E9E9EB;
    }
  }
}

.tiktok-checkbox:disabled {
  background-color: #f8f8fa !important;
}

.main-cell {
  &.tiktok-product {
    max-width: 245px;
  }
}

.tiktok-sync-status {
  .sync-status-box {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 8px 12px;
    border-radius: 18px;
    text-align: center;

    &.processing-status-box {  
      width: 90px;
      color: #8f51ff;
      background-color: #f7f2ff;
    }

    &.update-status-box {
      width: 89px;
      color: #b96400;
      background-color: #fff9e5;

      &:not(.recommendation-box) {
        width: 69px;
      }
    }

    &.ready-status-box {
      width: 82px;
      color: #078a15;
      background-color: #f0faf5;

      &:not(.recommendation-box) {
        width: 62px;
      }
    }

    &.in-review-status-box {
      width: 80px;
      color: #006fff;
      background-color: #e6f6ff;
    }

    &.in-queue-status-box {
      width: 78px;
      color: #b96400;
      background-color: #fff9e5;
    }
  }
}

.tiktok-category {
  .dropdown-box-active, .dropdown-box-error {
    border-radius: 7px;
  }

  .selected-value {
    font-size: 12px;
  }

  .input-group {
    height: 34px;
    border-radius: 7px;
    border: 1px solid #e4e5e9;

    input {
      width: 100%;
      left: 0;
      border: none !important;
      outline: none !important;
      border-radius: 7px !important;
    }
  }
}

.tiktok-category-dropdown {
  right: 0px;
  top: 44px;
  min-width: 280px !important;
}

.category-search {
  background: #FFFFFF;
  width: 100% !important;
}

.level-category-option {
  font-size: 14px;
  line-height: 18px;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 8px;
  margin: 0 2px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.level-category-selected {
  white-space: nowrap;
  color: #66728B;
  cursor: pointer;
  &:hover {
    color: #1e2749;
  }
  &.active {
    color: #1e2749;
  }
}

.level-category-previous-button {
  position: absolute;
  top: calc(50% - 23px);
  left: -15px;
  transform: translate(0, -50%) rotate(180deg);
  border: none;
  cursor: pointer;
  height: 42px;
  padding: 0 2px;
  z-index: 2;
  background: #F8F8FA;
  &:hover {
    background: #CCEDFF;
  }
}

.level-category-next-button {
  position: absolute;
  top: calc(50% - 23px);
  right: -15px;
  transform: translate(0, -50%);
  border: none;
  cursor: pointer;
  height: 42px;
  padding: 0 2px;
  z-index: 2;
  background: #F8F8FA;
  &:hover {
    background: #CCEDFF;
  }
}

.not-found-icon {
  width: 680px;
  height: 148px;
}

.search-highlight {
  color: #00A3FF;
  background-color: #FFFFFF;
  &.tool-tip {
    background-color: transparent;
  }
}

.tiktok-category-search-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: #F8F8FA;
  }
}

.slick-slide {
  border-right: 1px solid #E9E9EB;
}