@import '../../../assets/sass/palette';
.conditions-dropdown{
    top: 43px;
}

.outside-click-parent-div > div:first-of-type {
    width: 100%;
    display: flex;
    margin: auto;
    height: 100%;
}

.tag-selector{
    padding: 0.825rem 0rem !important;
}

.fs-13px{
    font-size: 13px !important;
}

.mw-153px{
    max-width: 153px !important;
}

.collection-management {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
    .input-group-addon {
        background-color: $primary-color-white;
        border: none;
        padding-right: 25px !important;
        padding: 13px;
    }
    .input-group {
        border: 1px solid $input-border-color;
        border-radius: 7px;
        width: 33.33%;
    }
}

.container-with-errorbox{
    display: flex;
    flex-direction: column;
}

.errorbox {
    height: auto !important;
    margin-top: 5px !important;
}

.delete-icon-btn {
    height: 32px;
    width: 32px;
    min-width: 32px;
    border-radius: 6px;
    background-color: #ffe2e5 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin: 0 !important;
    outline: none !important;

    &:hover {
        background-color: #f64e60 !important;

        svg {            
            path, line {
                stroke: #fff !important;
            }
        }
    }

    svg {
        width: 9px !important;
        height: 9px !important;

        path, line {
            stroke: #f64e60;
        }
    }
}

.rules-engine-select-search {
    left: 0;
    border-radius: 7px !important;
    border: 1px solid #00a3ff !important;
}

.w-95 {
    width: 95% !important;
}