.time-picker {
    .error-message {
        height: 0px !important;
    }

    &-container {
        grid-gap: 10px;

        .placeholder-text {
            color: #a1a5b7;
        }

        .time-value-view {
            min-width: 80px;
            color: #1e2749;
        }

        .timeAMPM {
            color: #1e2749;

            input {             
                color: #1e2749;
   
                &::placeholder {
                    color: #a1a5b7;
                }
            }
        }

        .ampm-text {
            font-size: 14px;
            line-height: 21px;
        }
    }
}