.manifest-dropdown {
  min-width: 500px;
  min-height: 200px;

  &-header {
    padding: 24px;

    h4 {      
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      color: #1e2749;
    }

    .svg-icon svg {
      width: 24px;
      height: 24px;

      [fill]:not(.permanent):not(g) {
        fill: #4a516c;
      }
    }
  }

  &-content {
    padding: 16px 20px;
    min-height: 130px;

    .carrier-logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .w-120px {
    width: 120px !important;
  }
}

.manifest-preview-modal {
  width: 941px !important;
  border-radius: 20px !important;

  &-header {
    padding: 30px;

    h3 {      
      font-size: 20px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0em;      
    }

    .creation-date {      
      font-size: 16px;      
      line-height: 18px;
      letter-spacing: 0em;
      font-weight: 400;
      color: #66728b;      
      right: 138px;
      bottom: 13px;      

      span {
        font-weight: 600;
        color: #1e2749cc;
      }
    }

    .svg-icon svg {
      width: 24px;
      height: 24px;

      [fill]:not(.permanent):not(g) {
        fill: #4a516c;
      }
    }
  }

  &-content {
    padding: 20px 30px;

    .profile-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-footer {
    padding: 37px 30px;
  }
}