@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
.addEditArea {
    position: fixed;
    top: 79%;
    width: 340px;
    background: white;
    height: 105px;
    right: 36px;
}

.cancel {
   margin-right: 10%;
}

.save {
    position: absolute;
    top: 58%;
    right: 15px;
    color: white !important;
}

.editAddInput {
    width: 300px;
    margin-left: 17px;
    background: white;
    border: 1px solid $input-border-color;
    box-sizing: border-box;
    border-radius: 7px;
    height: 40px;
}

.btnGroup {
    margin-left: 150px;
    margin-top: 4%;
}

@include for-big-desktop-up {
    .addEditArea {
        top: 73.5%;
        width: 431px;
        right: 24px;
        height: 108px;
    }
    .editAddInput {
        width: 380px;
        margin-left: 22px;
    }
    .btnGroup {
        margin-left: 230px;
        margin-top: 3%;
    }
}