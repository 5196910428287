.live-show-viewers-header {
  &-more {
    right: 7px;
  }
}

.live-viewers-count-container {
  padding: 12px 20px;

  .live-viewer-count {
    font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
    font-weight: 600;
    line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
    letter-spacing: 0em;
    margin-right: calc(var(--ZOOM-MULTIPLIER) * 1rem);
    margin-left: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);        
  }

  .live-count-icon {
    &-total, &-facebook {
      width: 1.71rem;
    }

    &-mobile {
      width: 1rem;
    }
  }
}

.live-viewers-details {
  width: 100%;
  margin-top: auto;
  overflow: auto;

  .viewer-detail {
    padding: calc(var(--ZOOM-MULTIPLIER) * 0.5rem) calc(var(--ZOOM-MULTIPLIER) * 1rem);
    width: 100%;

    &:hover {
      background-color: #f8f8fa;
    }

    &.active {
      background-color: #e5f6ff;      
    }

    .viewer-action-menu {
      right: 1rem;
    }

    &-blocked {
      width: 100%;      
      height: 100%;
      opacity: 0.6;
      left: 0;
      background-color: #fff;
    }

    .viewer-icon {
      width: 1.71rem;
    }
  }
  .circularProgres-with-image {

    .circle-image {
      border-radius: 50%;
      width: 3.14rem;
      height: 3.14rem;
    }
  }

  .viewer-name {
    font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
    font-weight: 500;
    line-height: calc(var(--ZOOM-MULTIPLIER) * 1.2rem);
    letter-spacing: 0px;
    margin: 0 calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
    text-overflow: ellipsis;
    max-width: calc(var(--ZOOM-MULTIPLIER) * 5rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    // -webkit-line-clamp: 2;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    /* white-space: nowrap; */

    @media screen and (min-width: 1920px) {
      max-width: calc(var(--ZOOM-MULTIPLIER) * 10rem);
    }

    @media screen and (max-width: 1919px) and (min-width: 1441px) {
      max-width: calc(var(--ZOOM-MULTIPLIER) * 7rem);
    }
  }
}

.modal {
  .viewer-icon {
    width: 1.71rem;
  }

  .viewer-action-menu {  
    width: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
    height: calc(var(--ZOOM-MULTIPLIER) * 1.71rem);
  
    &.active {
      border-radius: 50%;
      background-color: #CCEDFF;
    }
  }
}

.viewers-action-drop-down {
  width: 19.28rem;
}

.viewer-action-drop-down {
  width: 12.14rem;
}
