.border-bottom-none { 
    border-bottom: none !important;
}

.border-top-none { 
    border-top: none !important;
}

.w-48 {
    width: 48% !important;
}

.shipping-rates-header {
    margin-bottom: 8px;
}

.shipping-rate-item {
    margin-bottom: 8px;
    padding: 8px 9px;

    &-provider {        
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #1e2749;
    }

    &-amount {        
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        white-space: nowrap;
    }

    &-dates {        
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #a1a5b7;
        white-space: nowrap;
    }
}

.buy-shipping-label {
    border-top: 1px solid #eff2f5;
}

.cheapest-label-slide-in {
    background: white;
    border-bottom: 1px solid #eff2f5;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    position: absolute;
    width: 100%;
    left: 0;
    top: -58px;
    &.active {
        top: 52px;
        opacity: 1;
    }

    &::-webkit-scrollbar-track {
        height: 100%;
        position: relative;
        z-index: 999;
        background: transparent;
        border: none;
        margin-bottom: 0;
    }

    .shipping-rate-item {
        margin-bottom: 0;
    }

    .cheapest-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #1E2749CC;
        margin-bottom: 6px;
    }
}

.label-list {
    margin-top: 0;
    background-color: white;
    transition: all 0.5s ease-in-out;
}

.button-slide-in {
    transform: translateY(100%);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.button-slide-in.active {
    transform: translateY(0);
    opacity: 1;
}
