.btn-back {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  padding: 12px;
  cursor: pointer;
  width: fit-content;
  border-radius: 20px;
  p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    padding: 0 5px;
  }
}

.btn-back:hover {
  background-color: #e5e5e5;
}

.brand-logo {
  margin-bottom: 60px;
}

.content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

  span {
    font-size: 16px;
    font-weight: 600;
  }

  .email-sentence {
    font-size: 14px;
    font-weight: 500;
    color: #a1a5b7;

    .email-text {
      font-size: 14px;
      font-weight: 500;
      color: #008fdf;
    }
  }
}

.p-text {
  font-size: 14px;
  font-weight: 500;
  color: #a1a5b7;
  margin-bottom: 24px;
}

.height-container {
  height: 860px;
}

.counter-msg {
  margin-top: 24px;
}
