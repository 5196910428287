.fulfilmentCenter {
    .partialShippingButton {
        background: rgba(241, 65, 108, 1) !important;
        border-radius: 1rem;
    }
    .columnSize {
        max-height: calc(100vh - 182px);
    }
    .packageShippingDivider {
        opacity: 100;
    }
    .leftFulfilmentBlock {
        width: 100%;
        min-height: calc(100vh - 80px);
    }
    .rightFulfilmentBlock {
        width: 100%;
        height: calc(100vh - 80px);

        .order-detail-link {
            .text-primary {
                &:hover {
                    text-decoration: underline !important;
                }
            }            
        }

        .combined-order-indicator {
            background: rgba($color: #61bd4f, $alpha: 0.1);
            padding: 8px 12px;            
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: center;
            color: #61bd4f;
            border-radius: 18px;
        }

        .order-code {            
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0em;  
            color: #1e2749;   
            
            &:hover {
                text-decoration: underline !important;
            }
        }

        .order-customer {
            margin-top: 14px;

            .customer-name {                
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                color: #1e2749;

                &:hover {
                    text-decoration: underline;
                }
            }

            .order-info {
                span {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 0em;                    
                    color: #1e2749;
                }

                &-label {                    
                    font-weight: 400 !important;
                    color:rgba(102, 114, 139, 1) !important;
                    // margin-left: 28px;
                }
            }
        }

        .bar-class {
            background-color: rgba(234, 234, 234, 1);
            border-radius: 30px;
        }

        .order-items {
            margin-bottom: 18px;
        }

        .order-items-list {            
            height: calc(100vh - 346px);
            margin-right: -13px;
            overflow-y: scroll;
        }

        .order-shipping-address {
            &-title {                
                font-size: 14px;
                font-weight: 600;
                line-height: 27px;
                letter-spacing: 0em;                
                color: #1e2749;
            }

            &-description {                
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0em;                
                color: #1E2749;                
            }
        }
    }
    @media screen and (min-width: 1200px) {    
        .packageShippingDivider {
            opacity: 0;
        }
        .leftFulfilmentBlock {
            width: 20%;
        }
        .rightFulfilmentBlock {
            width: 80%;
        }
    }
}
