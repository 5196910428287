.home-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
}

.home-subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  color: #1e2749;
}

.home-analytics-card {
  h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;    
    color: #1e2749;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 13px;  
    color: #a1a5b7;

    span {            
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      
      &.positive-value {
        color: #61bd4f;
      }

      &.negative-value {
        color: #f1416c;
      }
    }
  }  

  &.h-380px {
    height: 380px;
  }
}

.channel-profit-item {
  display: flex;  
  border-bottom: 1px solid #e9e9eb;
  height: 40px;

  &.no-border {
    border: none;
  }

  &.subitem {
    div {
      color: #66728b;
    }
  }

  div {
    flex-basis: 100%;    
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    color: #1e2749;
    display: flex;
    align-items: center;
  }
}