@import '../../../../assets/sass/palette.scss';
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
.leave-body-size {
    min-width: 600px;
}

@media (max-width: 576px) {
    .leave-body-size {
        min-width: auto;
    }
}
@media (max-width: 768px) {
    .leave-body-size {
        min-width: auto;
    }
}

.pt-18px{
    padding-top: 18px !important;
}

.price-color{
    color: $secondary-grey-shade;
}

.tagBadge {
    font-size: 12px !important;
    background-color: $tag-Color !important;
    color: $tagText-Color !important;
    padding: 0px !important;
    padding-left: 5px !important;
}

.closeIconBadge {
    filter: $filterBlueColor !important;
}

.tagInput {
    padding: 10px;
    border: 1px solid $primary-border-color;
    border-radius: 10px;
    margin: 3px;
}

.userNameStyle {
    margin-right: 17px;
}
.notesLogo {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50% !important;
}

.customerName {
    font-family: "poppins-regular";
    font-size: 12px !important;
    padding-left: 10px;
}

.timelineForm {
    width: 105vh !important;
}

@include for-big-desktop-up { 

    .timelineForm {
        width: 118vh !important;
    }
    
}

.timeLineVertical{
    border-left: 0.5px solid grey;
    margin-left: 9px;
    opacity: 0.3;
    margin-left: 17px;
    width: 1px !important;
}

.resendEmail {
    height: 38px;
    padding-top: 9px;
    margin-top: 12px;
    display: block !important;
}

.v2 {
    height: 20px;
}


.orderType {
    width: 163px !important;
}


.dropCreate {
    border: 1px solid $primary-border-color !important;
    color: $gray-border-color !important;
}

.priceInput {
    width: 70px !important;
    height: 40px;
}

.productName {
    padding-left: 0px;
}

.alignLeft {
    left: 16px !important;
    position: absolute;
}

.recieptCancel {
    margin-left: 251px !important;
}

.orderDetails {
    .modal-dialog {
        position: absolute !important;
        right: 25px !important;
        display: block !important;
    }
}

.noProductText {
    text-align: center;
}

.deleteDraft {
    border: 1px solid !important;
}