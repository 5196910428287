$dark-grey: #4b526d;
$draggable-box-background: rgba(0, 163, 255, 0.1);
$primary: #00a3ff;
$danger: #f1416c;

.add-vendor {
    .category-badge {
        color: $dark-grey;
        background-color: white;
    }
}

.draggable-box {
    background: $draggable-box-background;
    border: 1px solid $primary;
    border-radius: 10px;
}

.change-logo {
    background-color: $draggable-box-background !important;
    color: $primary !important;
}

.red-border {
    border: 1px solid $danger !important;
}

.category-div {
    padding-top: 10px;
}

.category-input {
    border: none !important;
}

.category-input:focus {
    border: none !important;
    background: transparent !important;
    outline: none !important;
}

.category-input-div {
    border-left: 1px solid #00a3ff;
}

.min-width-20 {
    min-width: 20px !important;
}
.vendorLogo {
    height: 100px;
    width: 100px;
}

.mt-badge {
    margin-top: 0.35rem !important;
}

.input-text-color {
    color: #4b526d !important;
}

.h-94vh {
    height: 94vh !important;
}

.opacity-90 {
    opacity: 0.9 !important;
}

.profile-pic-margin {
    margin-left: 119px;
}

.label-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}
.outside-click-parent-div > div:first-of-type {
    width: 100%;
}

.disabled {
    * {
        cursor: none;
        pointer-events: none;
        color: rgba(0, 0, 0, 0.4);
    }

    * > ::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
}

.modal-height {
    height: 90%;
}
