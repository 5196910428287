@import '../../../assets/sass/palette.scss';
@import '../../../assets/sass/core//components/mixins/svg-icon';
.orderList {
    .order-status-box {
        border-radius: 18px;
        font-size: 12px;
        line-height: 18px;
        background-color: yellow;
        width: fit-content;
        padding: 8px 15px !important;
        transition-duration: 300ms;
    }
    .to-fulfil-order-status-box {
        background: rgba(0, 163, 255, 0.1);
        color: rgba(0, 163, 255);
        // &:hover {
        //     background-color: rgba(0, 163, 255);
        //     color: white;
        // }
    }
    .pending-order-status-box {
        background: rgba(122, 134, 160, 0.1);
        color: rgba(122, 134, 160, 1);
        // &:hover {
        //     background-color: rgba(122, 134, 160, 1);
        //     color: white;
        // }
    }
    .to-process-order-status-box {
        background: rgba(122, 134, 160, 0.1);
        color: rgba(122, 134, 160, 1);
        // &:hover {
        //     background-color: rgba(122, 134, 160, 1);
        //     color: white;
        // }
    }
    .to-ship-order-status-box {
        background: rgba(172, 127, 255, 0.1);
        color: rgba(172, 127, 255, 1);
        // &:hover {
        //     background-color: rgba(172, 127, 255, 1);
        //     color: white;
        // }
    }
    .shipped-order-status-box {
        background: rgba(97, 189, 79, 0.1);
        color: rgba(97, 189, 79, 1);
        // &:hover {
        //     background-color: rgba(97, 189, 79, 1);
        //     color: white;
        // }
    }
    .error-order-status-box {
        background: rgba(235, 90, 70, 0.1);
        color: rgba(235, 90, 70, 1);
        // &:hover {
        //     background-color: rgba(235, 90, 70, 1);
        //     color: white;
        // }
    }
    .disputed-order-status-box {
        background: rgba(75, 147, 229, 0.1);
        color: rgba(75, 147, 229, 1);
        // &:hover {
        //     background-color: rgba(75, 147, 229, 1);
        //     color: white;
        // }
    }
    .hideScrollBar::-webkit-scrollbar {
        display: none;
    }

    .error-list-background {
        background-color: #fff3f6 !important;
    }

    .br-50 {
        border-radius: 50px;
    }

    .danger-text {
        color: #f1416c !important;
    }

    .warning-text {
        color: $warning-yellow-color !important;
    }

    .error-badge {
        background-color: $light-gray !important;
        color: $light-gray-text !important;
    }

    .badge-error {
        @include svg-icon-color($light-gray-text);
    }
    .fitContentWidth {
        width: fit-content !important;
    }
    .roundButton {
        border-radius: 2rem;
    }
    .rotatedArrow {
        transform: rotate(-90deg);
    }
    .upArrow {
        transform: rotate(-90deg);
    }

    .downArrow {
        transform: rotate(90deg);
    }

    .ordersShippingRows {
        background-color: rgba(248, 248, 250, 0.4) !important;
    }
    .inline-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }
    .blueOrderNums {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #00a3ff;
    }
    .combinedOrderChildRows {
        cursor: pointer;
        &:hover {
            background-color: #f8f8fa !important;
        }

        a {
            color: #1e2749 !important;            
            font-weight: 600;
            font-size: 14px;
            
            &:hover {
                text-decoration: underline !important;
            }
        }
    }
    .ordersSelectedRows {
        background-color: #edf6fb !important;
    }
    .vip-icon {
        svg {
            path {
                fill: transparent !important;
            }
        }
    }
    .order-customer-link {
        color: #1e2749 !important;
        &:hover {
            text-decoration: underline !important;
        }
    }
    .available-item-quantity {
        color: #1e2749 !important;
        font-family: 'poppins-regular';
        font-weight: 600;
        font-size: 14px;
    }
    .order-status-error-text {        
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;           
    }
}
