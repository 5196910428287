@import '../../../assets/sass/palette.scss';
.error-list-background {
    background-color: #fff3f6 !important;
}
.danger-text {
    color: #f1416c !important;
}
.minLargeSize {
    min-width: 200px !important;
}
.rotatedArrow {
    transform: rotate(90deg);
}

.lightGreyBackground {
    background-color: $light-gray;
}
.leftSpace {
    padding-left: 9rem;
}
.waitlist-auth-width {
    width: 60%;
}
