.label-number-input {
  input {
    padding: 14px 11px !important;    
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    height: 43px !important;
    width: 98px !important;
    border: 1px solid #e4e6ef;
  }

  .input-controls {
    top: 6px;
    right: 8px;
  }

  .error-text {
    bottom: -20px;
    right: 0;    
    font-size: 12px;    
    font-weight: 400;
    line-height: 120%;
  }
}

.variant-label-item {
  padding: 10px 0;
  margin-left: 82px;
}

.rotated-icon {
  transform: rotate(-90deg);
}