.overlappingImage {
    border: 2px solid white;
    margin-right: -8px;
}
.dateText {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
}
.monthYearText {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2.26px;
    text-transform: uppercase;
    color: #8b90a1;
}
.timeText {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: #a1a5b7;
}

.dropdown-menu-container {
    max-height: 130px;
}

.spinner-border.live-show-search-spinner{
    width: 16px !important;
    height: 16px !important;
    background-image: url('../../../assets/icons/searchbarLoader.svg');
}

.live-show-list-view-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #1E2749;
    margin-bottom: 0px;
}

.live-show-list-view-title-sub {
    padding-top: 32px;
    padding-bottom: 10px;
}
.live-list-view-table-row {
    cursor: pointer;
    &:hover {
        background: #f8f8fa;
    }
}