@import '../../../assets/sass/palette.scss';

.DatesSelectorWeekContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
}
.vertical-divider {
    background-color: $input-border-color;
    width: 5px;
    height: 100%;
    display: block;
}
.firstSelectedDate {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}
.lastSelectedDate {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
.squareCalBlock {
    width: 40px;
    height: 40px;
}
.squareCalBlockChild {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &.disabled-date {
        pointer-events: none !important;
    }
}
.future-dates {
    &:hover {
        background: #e5f6ff !important;
        border-radius: 100px;
    }
    &:active {
        background: #e5f6ff;
        border-radius: 100px;
        border: 1px solid #00A3FF;
    }
}
.datesBetween {
    background-color: $primary-blue-light-background;
}
.singleDateSelected {
    border-radius: 50px;
}
// .scheduled-availability-datepicker {
//     height: 490px !important;
// }
.month-year-indicator {
    background-color: #f8f8fa;
    height: 40px;
}
.centered-date-dropdown {
    left: calc(50% - 150px) !important;

    &.left-0 {
        left: 0 !important;
    }
}
