.shopify-integrations {
  &-container {
    max-width: 700px;
    margin: 0 auto;

    .sync-border {
      width: 18px !important;
      height: 18px !important;
      background-image: url('../../../../assets/icons/syncLoaderIcon.svg') !important;
    }

    .sync-progress {      
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #a1a5b7;
    }

    .sync-steps {
      margin-left: 26px;

      &-item {        
        font-size: 10px;
        font-weight: 400; 
        line-height: 1;     
        color: #a1a5b7;  
      }
    }

    .sync-completed {
      color: #34C759;
    }

    .sync-error {
      color: #f1416c;
    }

    .sync-counts {
      padding-left: 34px;

      div {        
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;  
        color: #4B526D;      
      }

      span {
        text-transform: capitalize;
      }
    }

    .sync-last-time {
      color: #4b526d;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      padding-left: 34px;
    }
  }
}
