.invite-signup-page {
  width: 100vw;
  height: 100vh;
  flex-direction: column;

  .card {
    width: 500px;
    margin: 51px auto 0;
    padding: 40px 24px 31px;

    h3 {      
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: 0em;   
      margin: 0 0 40px;   
    }

    .password-input {
      position: relative;

      &-addon {
        position: absolute;        
        top: 0;
        right: 0;        
        width: 40px;
      }
    }
  }
}