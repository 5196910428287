@import '../../../assets/sass/palette.scss';

.bulk-editor-modal {
    .modal-title {
        color: #1e2749;
    }

    thead {
        th {
            border-bottom: 0px !important;
        }
    }

    // .form-check-input {
    //     min-width: 1.75rem;
    // }

    .table-body {
        .price-input {
            font-size: 14px;
            line-height: 18px;
            color: #66728b;
        }

        .product-name-cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;   
            color: #1e2749 !important;
            font-family: 'poppins-regular';
            font-weight: 600;
            font-size: 14px;
            
            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}

.label-height>div {
    padding-top: 24.25px !important;
    padding-bottom: 24.25px !important;
    padding-right: 16px !important;
}

.w-1068px {
    width: 1068px !important;
}

// .label-height:hover,
// .label-height:focus,
// .label-height:focus-within {
//     outline: 2px solid #00A3FF !important;
//     border-radius: 3px;
// }

.dontScrollBulkEditorTop {
    position: sticky !important;
    left: 0;
    top: 0;
    // z-index: 12 !important;
}

.dontScrollBulkEditor {
    position: sticky !important;
    left: 0;
    top: 0;
    // box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1) !important;
    z-index: 120 !important;

    &.scrolling-left {
        &::after {
            box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
            border-left: 1px solid #e9e9eb;
            position: absolute;
            top: 0;
            right: 1px;
            bottom: -1px;
            width: 30px;
            transform: translateX(100%);
            transition: box-shadow 0.3s;
            content: "";
            pointer-events: none;
        }
    }    
}

.boxShadowBulkEditor {
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1) !important;
}

.overFlowContaonerBulkEditor {
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1) !important;

}

.capitalize-first {
    text-transform: lowercase;

    ::first-letter {
        text-transform: uppercase;
    }
}

.removeInputHighlight:focus {
    outline: none !important;
}

.tree-sub-item-fix {
    transform: translateX(-8pt);
}

.tree-sub-item-select-fix {
    transform: translateX(-30pt);
}

.product-name {
    max-height: 100% !important;
}

.collection-child-node {
    padding-left: 9% !important;
}

.row-checkbox-bg {
    background: #fff;
    border-radius: 5px;
    border: 0px;
    border: 1px solid #ddd;
}

.parent-checkbox {
    border: 1px solid #e4e6ef !important;
}

.tree-modal-row {
    // margin-top: 10px;
    // padding-bottom: 10px;
    padding: 12.5px 0;
}

.display-flex {
    display: flex !important;
}

.wpx-200 {
    width: 200px !important
}

.wpx-100 {
    width: 100px !important
}

.form-checkbox {
    border-radius: 5px !important;
    background-size: auto !important;
}

.h-70px {
    max-height: 70px !important;
}

.m-left {
    margin: auto auto auto 11px !important;
}

.m-right {
    margin: auto 13px auto auto !important;
}

.wpx-140 {
    width: 140px !important;
}

.wpx-124 {
    width: 124px !important;
}

.wpx-134 {
    width: 134px !important;
}

.wpx-144 {
    width: 144px !important;    
}

.wpx-106 {
    width: 106px !important;    
}

.wpx-235 {
    width: 235px !important;
}

.wpx-180 {
    width: 180px !important;
}

.wpx-360 {
    max-width: 360px !important;
}

.action-row-position {
    position: sticky !important;
    top: 0 !important;
    z-index: 15 !important;
}

.table-body {
    &.scrolling-left {
        tr {        
            th:nth-child(1),    
            td:nth-child(1) {
                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -1px;
                    width: 30px;
                    transform: translateX(100%);
                    transition: box-shadow .3s;
                    content: "";
                    pointer-events: none;                
                    border-left: 1px solid #e9e9eb;
                    box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 6%);                    
                }
            }
            
            td:nth-child(1) {
                background-color: #fff;

                &:hover {
                    background-color: #f8f8fa !important;
                }
            }
        }     
    }
}

// .bulk-editor-input {
//     padding-top: 10px !important;
//     padding-bottom: 10px;
// }

.input-padding {
    padding-top: 9.90px !important;
    padding-bottom: 9.90px !important;
}

.field-y-padding {
    padding-top: 17.336px !important;
    padding-bottom: 17.336px !important;

    input {
        border: none;
    }
}

// td:hover {
//     outline: 2px solid red !important;
//     border: none !important;
// }

.focus-field:active,
.focus-field:focus-within,
.focus-field:focus {
    outline: 2px solid #00A3FF;
    border-radius: 3px;
    border:0 !important;
    outline-offset: -2px !important;

    input {
        &:focus {
            outline: none !important;
            border: none !important;
        }
    }
}

.no-left-padding {
    padding-right: 1.25rem !important;
    padding-left: 0 !important;
}
.p-left-20px {
    padding-left: 20px !important;
}

.gap-20px {
    margin-right: 20px !important;
}
.padding-left-right {
    padding-left: 14px !important;
    padding-right: 14px !important;
}
.w-235px {
    width: 235px !important;
}
.w-360px {
    width: 360px !important;
}
.opacity-half {
    opacity: 0.5;
}

.disabled-row {
    pointer-events: none;
}
.disabled-arrow {
    pointer-events: none;
    opacity: 0.1;
}
.bulk-editor-dropdown {
    z-index: 1200 !important;
}
.bulk-editor-selected-option {
    font-size: 14px;
    line-height: 21px;
}
.bulk-product-table {
    overflow-y: hidden !important;
    margin: 0 30px !important;
    border-radius: 10px !important;
}