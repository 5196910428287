@import '../../../assets/sass/palette.scss';

.live-product-info-div {
    .live-product-roww {
        &:hover {
            background: #ffffff;
            box-shadow: 0px 24px 34px rgba(118, 123, 142, 0.1);
            border-radius: 10px;
        }
    }
    .product-row {
        flex-direction: row;
    }
    .product-title-info {
        flex-direction: column;
    }
    .product-id {
        background-color: $light-gray;
        border: 1px solid $input-border-color;
        border-radius: 20%;
        color: $gray-border-color;
    }
    .product-name-class {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        &:hover {
            text-decoration: underline;
        }
    }
}
