@import '../../../assets/sass/palette.scss';
.selected-order-background {
    background-color: $light-blue-highlight;
}
.imageOverlayText {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
    color: white;
}
.roundedImagesOrderFulfilment {
    border-radius: 0.2rem;
}
.order-card {
    background-color: #fff;
    margin-bottom: 12px;

    &.selected-order,
    &:active,
    &:hover {
        background-color: #edf6fb;
    }

    &.selected-order,
    &:active {
        border: 1px solid #00a3ff;
    }

    .customer-profile {
        .customer-name {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: #4b526d;
        }
    }    

    .order-card-main {
        padding: 16px 15px;

        .order-description {
            margin-top: 10px;

            div {
                span {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    color: #4b526d;
                    
                    &.order-description-label {
                        color: #a1a5b7;
                    }
                }
            }
        }
    }
}
