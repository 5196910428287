@import '../../../assets/sass/palette.scss';
$dark-grey: #4b526d;



.customerTaxcodeBox {
    background-color: $primary-color-white !important;
    border-radius: 10px !important;
    [data-kt-aside-minimize='on'] & {
        background-color: $primary-color-white !important;
        border-radius: 10px !important;
        padding: 10px 0px !important;
    }
    &:hover, 
    &:focus {
        .tax-edit-button {
            display: flex;
          }
    }
}

.tax-edit-button{
    display: none;
}


.newNotesInput {
    outline: none;

    &:hover, 
    &:focus {
        outline: 1px solid #00a3ff;
    }
}
.newNotesInput::placeholder {
    color: $gray-border-color;
}


.tax-code-heading {
    display:flex;
    flex-shrink: 1 ;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
    justify-content: space-between
}

.tax-spinner {
    margin-left: 20px;
    align-items: center;
    justify-content: center;
  }