.product-file-upload {
    margin-bottom: 20px;

    &-box {
        background: rgba(0, 163, 255, 0.1);
        border: 1px dashed #00A3FF;
        border-radius: 10px;
        min-height: 150px;
        position: relative;
        overflow: hidden;
        padding: 36px 30px; 
        display: flex;
        align-items: center;
        width: 100%;

        &:hover {
            background: #ccedff;
        }

        &:active {
            border: 1px solid #00A3FF !important;
        }

        &.dragged-status {
            border: 1px solid #00A3FF;
        }

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
        }

        .uploadIcon {
            margin-right: 19px;
            width: 40px;
            height: 40px;

            &.accepted {
                width: 45px;
                height: 45px;
            }
        }

        .dndText p:first-child {
            font-size: 20px;
        }

        .mediaGreyText {
            color: #a1a5b7 !important;
        }
    }
}

.media-title {
    margin-bottom: 35px;
}

.whole-drag-section {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none;
    cursor: default;
    visibility: hidden;
}