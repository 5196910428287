.page-title {    
    font-size: 30px !important;
    line-height: 36px;
    font-weight: 600;
    font-family: 'poppins-regular';
    color: #1e2749;
}
.csv-import-btn {
    button {
        background-color: transparent !important;
        border: none !important;
    }
}