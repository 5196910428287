.search-bar-box {
  flex-direction: row;
  border: 1px solid #f5f8fa;
  padding: 4px;
  background: #f5f8fa;
  border-radius: 5px;
  width: 260px;

  .tiktok-close {
    position: absolute;
    z-index: 3;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &.tiktok-category {
    border: none !important;
    background-color: #FFFFFF !important;

    input {
      border: 1px solid #E4E5E9;
      background-color: #FFFFFF !important;
    }
    input::placeholder {
      color: #4B526D;
    }
    input {
      &:focus {
        border: 1px solid #00A3FF !important;
      }
    }
  }

  input {
    &:focus {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}