@import '../../../assets/sass/palette.scss';

.no-warehouse-section {
    img {
        width: 166px;
        margin: 0 auto;
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 10px;
        text-align: center;
        color: #A1A5B7;
        margin: 0 0 16px;
    }

    button {        
        margin: 0 auto;
    }
}