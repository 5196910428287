@import '../../../assets/sass/palette.scss';
.right-border-divider {
    transform: rotate(-90deg);
    right: -70px;
    bottom: 42%;
    max-width: 140px !important;
    background-color: $input-border-color !important;
}
.final-scale-checkbox {
    font-weight: 400;
    color: #1E2749;
}
.sale-price-title {
    div {
        align-items: center;
    }
    span {        
        margin-left: 0.75rem;
        cursor: pointer;
    }
    .text-danger {
        line-height: 1;
    }
}

.disabled-input {
    background-color: $input-grey !important;
}

.toggle-absolute {
    position: absolute !important;
    right: 10px !important;
}

.tax-title-section {
    margin-bottom: 12px;
}
