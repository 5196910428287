@import '../../../assets/sass/palette.scss';

.create-live-show-detail-step-div {
    .content {
        flex-direction: row;
    }
    .input-view {
        width: 70%;
        border-right: 1px solid $primary-border-color;
        .input-fields {
            border-bottom: 1px solid $primary-border-color;
            &.no-border {
                border-bottom: none;
            }
        }
    }
    .thumbnail-view {
        width: 30%;
        justify-content: flex-end;
        .thumbnail-title {
            align-items: center;
            justify-content: center;
            display: flex;
            font-weight: 500;
            color: rgba(30, 39, 73, 0.8) !important;
            font-size: 16px !important;
        }
    }
    .date-time-picker {
        .date-class {
            flex: 0 0 35%;
        }
        .start-time-class {
            flex: 0 0 30%;
        }
        .end-time-class {
            flex: 0 0 30%;
        }
    }
}

.svg-icon {
    &.rotate-svg {
        svg {
            transform: rotate(180deg);
        }
    }
}

.channels-section {
    max-height: 300px;
    overflow-y: scroll;
}