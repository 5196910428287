.filter-menu {
  // position: fixed;
  // width: 378px;
  // top: 0;
  // right: 0;
  // background-color: #fff;
  // height: 100vh;
  // box-shadow: 0px 5px 30px rgba(19, 16, 57, 0.1);
  // z-index: 1000;

  &-header {
    padding: 16px 20px;
    border-bottom: 1px solid rgba(194, 196, 204, 0.3);

    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #1E2749;
    }

    svg {
      rect {
        fill: #4A516C !important;
      }
    }
  }

  &-content {
    height: calc(100vh - 105px);
  }

  &-footer {
    padding: 8px 20px;
    border-top: 1px solid rgba(194, 196, 204, 0.3);
    position: fixed;
    bottom: 0;
    right: 0;
    width: 419.5px;
  }
}

.filter-menu-item {
  &-header {
    padding: 20px;    

    h6 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4A516C;
    }

    .rotate-icon {
      transform: rotate(270deg);
    }

    .clear-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #00A3FF;
    }
  }

  &-content {
    padding: 14px 20px;
    background: #fcfcfc;
    max-height: 222px;
    overflow-y: scroll;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);  

    .filter-menu-checkbox {
      margin-bottom: 7px;

      span {
        padding-left: 14px !important;
        font-family: 'poppins-regular';        
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #66728B;
      }
    }
  }
}