@import '../../../assets/sass/palette.scss';

.fulfilmentProfilePicture {
    object-fit: cover;

    &.product-image {
        min-width: 72px;
        min-height: 72px;
        width: 72px;
        height: 72px;
        margin-right: 10px;
    }

    &.shipping-rate-icon {
        margin-right: 14px;
    }
}
.fulfilmentShiftImage {
    margin-right: -10px;
}

.order-product-images {
    margin-right: 10px;
}

.fulfilmentPicture {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    object-fit: cover;

    &.roundedImagesOrderFulfilment {
        border-radius: 4px !important;
    }
}

.fulfill-title {    
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;    
    color: #1e2749;
}

.fulfill-orders-list {
    &::-webkit-scrollbar {        
        height: 0px !important;
    }
}

.search-order-input {
    margin-right: 13px;
}
